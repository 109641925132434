import React, { useState } from 'react'
import { Box, Grid, Tabs, Tab, IconButton } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'
import { showWarningSwal, showInfoSwal } from '../../functions/alert'
import { invalidName } from '../../functions/general'
import { addDifficultyGroup } from '../../functions/postData'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableToggle from '../general/ReusableToggle'
import ReusableButton from '../general/ReusableButton'
const formContainerStyle = { paddingBottom: 20 }
const configContainerStyle = { marginTop: 30, paddingInline: 200 }
const rowsContainerStyle = { marginTop: 20 }
const flexStyle = { display: 'flex' }
const centerFlexStyle = { display: 'flex', alignItems: 'center' }
const difficultiesContainerStyle = assignMarks => ({ marginTop: 20, width: !assignMarks && 1000 })
const buttonsWrapperStyle = {
   marginInline: '490px 110px', marginBottom: 20
}
const rowButtonsWrapperStyle = { width: 80 }
const upButtonStyle = (index, length) => ({ marginRight: index === length - 1 && 20 })
const downButtonStyle = index => ({ marginLeft: !index && 36 })
const rowIconStyle = { fontSize: 32, color: '#000' }
function EachForm({ forms, setForms, count }) {
   const { name, assignMarks, difficulties } = forms[count]
   const updateForm = (count, identifier, value) => {
      let newForms = [...forms]
      if (typeof count === 'object') {
         if (identifier === 'add') {
            newForms[count[0]].difficulties.splice(count[1] + 1, 0, { name: '', correct_marks: '', wrong_marks: '', blank_marks: '' })
         } else if (identifier === 'remove') {
            newForms[count[0]].difficulties.splice(count[1], 1)
         } else if (identifier === 'moveUp') {
            [newForms[count[0]].difficulties[count[1] - 1], newForms[count[0]].difficulties[count[1]]] =
               [newForms[count[0]].difficulties[count[1]], newForms[count[0]].difficulties[count[1] - 1]]
         } else if (identifier === 'moveDown') {
            [newForms[count[0]].difficulties[count[1] + 1], newForms[count[0]].difficulties[count[1]]] =
               [newForms[count[0]].difficulties[count[1]], newForms[count[0]].difficulties[count[1] + 1]]
         } else newForms[count[0]].difficulties[count[1]][identifier] = value
      } else {
         newForms[count][identifier] = value
      }
      setForms(newForms)
   }
   const massAssignScore = target => {
      let newForms = [...forms]
      newForms[count].difficulties.forEach(n => n[target] = newForms[count].difficulties[0][target])
      setForms(newForms)
   }
   return (
      <Grid container style={formContainerStyle}>
         <Grid container alignItems="center" justifyContent="space-between" style={configContainerStyle}>
            <div style={centerFlexStyle}>
               <NunitoText value='Difficulty Group' fontSize={20} fontWeight={600} marginRight={40} />
               <ReusableTextField type='default' width={450} height={60} bgColor='#F2F2F2' placeholder='Enter Difficulty Group Name*'
                  state={name} count={count} target='name' setState={updateForm} required />
            </div>
            <div style={centerFlexStyle}>
               <NunitoText value='Assign Marks' fontSize={20} fontWeight={600} marginRight={40} />
               <ReusableToggle type='icon' width={120} state={assignMarks} count={count} target='assignMarks'
                  setState={updateForm} />
            </div>
         </Grid>
         <Grid container direction="row" alignItems="center" justifyContent="center" style={rowsContainerStyle}>
            {difficulties.map((difficulty, index) => (
               <Grid key={index} container alignItems='center' justifyContent='space-between' style={difficultiesContainerStyle(assignMarks)}>
                  {assignMarks && !index && <Grid container justifyContent='space-between' style={buttonsWrapperStyle}>
                     <ReusableButton text='Mass Assign Correct Points' fontSize={15} height={42} width={240} bgColor='#6FC9B6'
                        onClick={() => massAssignScore('correct_marks')} />
                     <ReusableButton text='Mass Assign Wrong Points' fontSize={15} height={42} width={240} bgColor='#F16774'
                        onClick={() => massAssignScore('wrong_marks')} />
                     <ReusableButton text='Mass Assign Blank Points' fontSize={15} height={42} width={240} bgColor='#707070'
                        onClick={() => massAssignScore('blank_marks')} />
                  </Grid>}
                  <div style={rowButtonsWrapperStyle}>
                     {index > 0 && <IconButton edge="end" style={upButtonStyle(index, difficulties.length)}
                        onClick={() => updateForm([count, index], 'moveUp')}>
                        <ExpandLessIcon style={rowIconStyle} />
                     </IconButton>}
                     {index < difficulties.length - 1 && <IconButton edge="end" style={downButtonStyle(index)}
                        onClick={() => updateForm([count, index], 'moveDown')}>
                        <ExpandMoreIcon style={rowIconStyle} />
                     </IconButton>}
                  </div>
                  <div style={flexStyle}>
                     <ReusableTextField type='default' width={assignMarks ? 360 : 760} height={60} bgColor='#F2F2F2'
                        required placeholder='Enter Difficulty Name*' count={[count, index]} target='name'
                        state={difficulty.name} setState={updateForm} />
                     {assignMarks && <div>
                        <ReusableTextField type='default' width={280} height={60} bgColor='#F2F2F2'
                           placeholder='Correct Answer*' count={[count, index]} target='correct_marks' number
                           state={difficulty.correct_marks} setState={updateForm} marginLeft={20} required />
                        <ReusableTextField type='default' width={280} height={60} bgColor='#F2F2F2'
                           placeholder='Wrong Answer*' count={[count, index]} target='wrong_marks' number
                           state={difficulty.wrong_marks} setState={updateForm} marginLeft={20} required />
                        <ReusableTextField type='default' width={280} height={60} bgColor='#F2F2F2'
                           placeholder='Blank Answer*' count={[count, index]} target='blank_marks' number
                           state={difficulty.blank_marks} setState={updateForm} marginLeft={20} required />
                     </div>}
                  </div>
                  <div style={rowButtonsWrapperStyle}>
                     {difficulties.length > 1 && <IconButton edge="start" onClick={() => updateForm([count, index], 'remove')}>
                        <RemoveCircleIcon style={rowIconStyle} />
                     </IconButton>}
                     <IconButton edge="start" onClick={() => updateForm([count, index], 'add')}>
                        <AddCircleIcon style={rowIconStyle} />
                     </IconButton>
                  </div>
               </Grid>
            ))}
         </Grid>
      </Grid>
   )
}
const boxStyle = { width: 1500, minHeight: 450, maxHeight: '90%' }
const addGroupButtonDivStyle = { display: 'flex', justifyContent: 'flex-end', marginTop: -45, marginRight: 20 }
const divStyle = {
   borderRadius: 10, margin: '20px 20px 0 20px',
   boxShadow: '0px 1px 6px rgb(0,0,0,0.2), 0 1px 6px rgb(0,0,0,0.2)'
}
const tabPropsStyle = { height: 0 }
const tabStyle = (value, i) => ({
   color: value !== i && '#707070',
   backgroundColor: value !== i && '#E7E6E6',
   border: value === i && '1px solid #F0F0F0',
   boxShadow: value === i && '0px 1px 6px rgb(0,0,0,0.2), 0px 1px 6px rgb(0,0,0,0.2)',
   borderRadius: '10px 10px 0 0',
   textTransform: 'none',
   marginRight: 10, height: 40
})
const closeIconStyle = { position: 'relative', left: 12, height: 18, width: 18, color: '#707070' }
const buttonsContainerStyle = { paddingInline: 210, marginBlock: '60px 30px' }
export default function AddDifficultyGroupModal({ setLoading, setOnAddDifficultyGroup, afterAddingDifficultyGroup }) {
   const [forms, setForms] = useState([{
      id: 1, name: '', difficulties: [
         { name: '', correct_marks: '', wrong_marks: '', blank_marks: '' },
         { name: '', correct_marks: '', wrong_marks: '', blank_marks: '' },
         { name: '', correct_marks: '', wrong_marks: '', blank_marks: '' }
      ], assignMarks: false
   }])
   const [value, setValue] = useState(0)
   const handleChange = (e, newValue) => setValue(newValue)
   const addForm = () => {
      if (forms.length >= 10) return showInfoSwal('Maximum number of tab is 10')
      setForms([...forms, {
         id: forms.map(f => f.id).reduce((a, b) => Math.max(a, b), 0) + 1,
         name: '', difficulties: [
            { name: '', correct_marks: '', wrong_marks: '', blank_marks: '' },
            { name: '', correct_marks: '', wrong_marks: '', blank_marks: '' },
            { name: '', correct_marks: '', wrong_marks: '', blank_marks: '' }
         ], assignMarks: false
      }])
      setValue(forms.length)
   }
   const deleteForm = (e, id) => {
      e.stopPropagation()
      let newForms = [...forms]
      newForms = newForms.filter(f => f.id !== id)
      if (!newForms.length) return setOnAddDifficultyGroup(false)
      let index = forms.findIndex(f => f.id === id)
      if (value === forms.length - 1 || index < value) setValue(value - 1)
      setForms(newForms)
   }
   const onSubmit = () => {
      let payload = []
      let msg = ''
      for (let i = 0; i < forms.length; i++) {
         const { name, assignMarks, difficulties } = forms[i]
         let warningMessage = ''
         if (invalidName(name)) warningMessage += 'Invalid Difficulty Group Name.<br>'
         for (let j = 0; j < difficulties.length; j++) {
            const { name, correct_marks, wrong_marks, blank_marks } = difficulties[j]
            let a = ''
            if (invalidName(name)) a += 'Invalid Difficulty Name.<br>'
            if (assignMarks) {
               if (isNaN(correct_marks) || correct_marks === '') a += 'Invalid correct marks<br>'
               if (isNaN(wrong_marks) || wrong_marks === '') a += 'Invalid wrong marks<br>'
               if (isNaN(blank_marks) || blank_marks === '') a += 'Invalid blank marks<br>'
            }
            if (a) warningMessage += `<b>Difficulty ${j + 1}</b> has<br>${a}`
         }
         if (!warningMessage) {
            payload.push({
               name: name, assign_marks: Number(assignMarks),
               difficulty: !assignMarks
                  ? difficulties.map(d => ({ name: d.name }))
                  : difficulties
            })
         } else {
            msg += '<div>'
            msg += `<div style="text-align:center; font-weight: 800">Error in Group ${i + 1}</div>`
            msg += `<div style="text-align:left">${warningMessage}</div>`
            msg += '</div>'
         }
      }
      if (msg) return showWarningSwal(msg)
      setLoading(true)
      console.log(payload)
      addDifficultyGroup(payload).then(d => {
         setLoading(false)
         afterAddingDifficultyGroup(() => setOnAddDifficultyGroup(false), d)
      }).catch(e => {
         setLoading(false)
         console.log(e)
      })
   }
   return (
      <Box className='popUpModal horizontalScrollable' style={boxStyle}>
         <NunitoText value='ADD DIFFICULTY GROUP' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
         <div style={addGroupButtonDivStyle}>
            <ReusableButton text='Add another difficulty group' fontSize={15} height={40} width={280} bgColor='#5E75C3'
               iconType='add' onClick={() => addForm()} allowSpam />
         </div>
         <div style={divStyle}>
            <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: tabPropsStyle }}>
               {forms.map((f, i) => <Tab key={f.id} label={<div style={centerFlexStyle}>
                  Group {i + 1}
                  <CloseIcon style={closeIconStyle} onClick={e => deleteForm(e, f.id)} />
               </div>} style={tabStyle(value, i)} />)}
            </Tabs>
            <EachForm forms={forms} setForms={setForms} count={value} />
         </div>
         <Grid container justifyContent='space-evenly' style={buttonsContainerStyle}>
            <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
               onClick={() => setOnAddDifficultyGroup(false)} />
            <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
               onClick={() => onSubmit()} />
         </Grid>
      </Box>
   )
}