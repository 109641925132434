import React from "react";
import { Box, Grid } from "@mui/material";
import NunitoText from "../general/NunitoText";
import ReusableButton from "../general/ReusableButton";
import { Padding } from "@mui/icons-material";

export default function ConfirmIdentifiedAnswers({
  confirmFunction,
  cancelConfirm,
}) {
  return (
    <Box
      className="popUpModal"
      style={{ width: 810, minHeight: 270, paddingRight: 20, paddingLeft: 20 }}
    >
      <NunitoText
        value="Attention!!"
        fontSize={34}
        fontWeight={600}
        color="#F16774"
        marginTop={25}
      />
      <NunitoText
        value={`Note: Modifying unique answers after marking has commenced may impact total points and moderation outcomes.  Please remark the countries associated with that grade.`}
        fontSize={20}
        fontWeight={600}
        marginTop={18}
      />
      <NunitoText
        value={`Please ensure that all newly updated unique answers are checked for integrity IAC. Changes may affect IAC results.`}
        fontSize={20}
        fontWeight={600}
        marginTop={18}
      />
      <Grid
        container
        justifyContent="space-evenly"
        style={{ marginBlock: "30px 20px" }}
      >
        <ReusableButton
          text="Cancel"
          bgColor="#8D8D8D"
          fontSize={25}
          height={63}
          width={280}
          onClick={() => cancelConfirm()}
        />
        <ReusableButton
          text="Confirm"
          bgColor="#5E75C3"
          fontSize={25}
          height={63}
          width={280}
          onClick={() => confirmFunction()}
        />
      </Grid>
    </Box>
  );
}
