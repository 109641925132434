import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { invalidName, invalidEmail, makeSureIsArray } from '../../functions/general'
import { showWarningSwal } from '../../functions/alert'
import { getBase64 } from '../../functions/upload'
import { patchOrganization } from '../../functions/patchData'
import NunitoText from '../general/NunitoText'
import ReusableCheckbox from '../general/ReusableCheckbox'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
import Loader from '../general/Loader'
export default function EditOrganizationModal({ setLoading, table, id, afterEditingOrganization, cancelEdit }) {
    const editingOrganization = () => {
        let obj = makeSureIsArray(table.data).find(s => s.id === id)
        obj.logo = obj.logo ?? ''
        obj.phone = obj.phone.replaceAll(',', '/').replaceAll('"', '').replace('[', '').replace(']', '')
        return obj
    }
    const [organization, setOrganization] = useState(editingOrganization())
    const { name, country_id, address, billing_address, phone, email, person_incharge, logo } = organization
    const [useSame, setUseSame] = useState(editingOrganization().address === editingOrganization().billing_address)
    const [hasImage, setHasImage] = useState(Boolean(editingOrganization().logo))
    const [loadingImage, setLoadingImage] = useState(false)
    const countryOptions = useSelector(state => state.countryOptions)
    const openFile = () => document.getElementById('file-upload').click()
    const updateOrganization = (identifier, value) => {
        let newOrganization = { ...organization }
        newOrganization[identifier] = value
        setOrganization(newOrganization)
    }
    const handleFileInputChange = (file) => {
        setHasImage(true)
        setLoadingImage(true)
        getBase64(file)
            .then(result => {
                updateOrganization('logo', result)
                setLoadingImage(false)
            })
            .catch(err => {
                console.log(err);
                alert(`Error at handeFileInputChange: ${JSON.stringify(err)}`)
                setLoadingImage(false)
            });
    }
    const unChanged = () => {
        let og = editingOrganization()
        let owo = JSON.stringify(og) === JSON.stringify(organization)
        if (og.address !== og.billing_address && useSame) owo = false
        return owo
    }
    const onSubmit = () => {
        if (unChanged()) return showWarningSwal('Please make some changes before submitting')
        let warningMessage = ''
        // if (invalidName(name)) warningMessage += 'Invalid Organization Name.<br>'
        if (address === '') warningMessage += 'Please enter an address.<br>'
        if (invalidEmail(email)) warningMessage += 'Invalid Email.<br>'
        if (!phone.toString().length) {
            warningMessage += 'Phone number must be number.<br>'
        } else {
            if (phone.split('/').some(el => isNaN(el) || !el.toString().length)) {
                warningMessage += 'All phone number must be number.<br>'
            }
            if (!/^[\d/]*$/.test(phone)) warningMessage += 'Add extra phone number using "/".<br>'
        }
        if (invalidName(person_incharge)) warningMessage += 'Invalid Person in charge.<br>'
        if (!country_id) warningMessage += 'Please select a country.<br>'
        if (warningMessage) return showWarningSwal(warningMessage)
        setLoading(true)
        let payload = { id, country_id, address, billing_address, phone: phone.split('/').map(o => o.trim()), email, person_incharge, logo }
        if (name.toLowerCase() !== editingOrganization().name.toLowerCase()) payload.name = name.toUpperCase()
        console.log(payload)
        patchOrganization(payload).then(d => {
            setLoading(false)
            afterEditingOrganization(cancelEdit, d)
        }).catch(e => {
            setLoading(false)
            console.log(e)
        })
    }
    const previewStyle = {
        display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20,
        backgroundColor: '#F2F2F2', height: 250, width: 760
    }
    return (
        <Box className='popUpModal horizontalScrollable' style={{ width: 1500, height: 850 }}>
            <NunitoText value='EDIT ORGANISATION' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
            <Grid container justifyContent='center'>
                <Grid container justifyContent="space-evenly" style={{ marginTop: 20, marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={400} height={60} bgColor='#F2F2F2' placeholder='Organization Name*'
                        state={name} setState={updateOrganization} required onBlur target='name' />
                    <ReusableTextField type='withLabel' width={500} height={60} bgColor='#F2F2F2' placeholder='Organization Address*'
                        state={address} setState={updateOrganization} required target='address' />
                    <ReusableTextField type='withLabel' width={400} height={60} bgColor='#F2F2F2' placeholder='Email*'
                        state={email} setState={updateOrganization} required target='email' />
                </Grid>
                <Grid container justifyContent="space-evenly" style={{ marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={400} height={60} bgColor='#F2F2F2' placeholder='Phone Number*'
                        state={phone} setState={updateOrganization} required target='phone' />
                    <ReusableAutocompleteWithID type='withLabel' width={500} height={60} placeholder='Country*'
                        state={country_id} setState={updateOrganization} btnBgColor='#144A94' borderColor='#707070' required
                        options={countryOptions.map(c => ({ id: c.id, option: c.display_name }))} target='country_id' />
                    <ReusableTextField type='withLabel' width={400} height={60} bgColor='#F2F2F2' placeholder='Person-in-charge*'
                        state={person_incharge} setState={updateOrganization} required target='person_incharge' />
                </Grid>
                <Grid container justifyContent="space-between" style={{ paddingInline: 34 }}>
                    <div style={{ textAlign: 'left' }}>
                        <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Billing Address'
                            state={useSame ? address : billing_address} setState={updateOrganization}
                            readOnly={useSame} disabled={useSame} target='billing_address' />
                        <ReusableCheckbox type='modal' label='Same as Organization Address' state={useSame} setState={setUseSame}
                            fontSize={16} />
                    </div>
                    <ReusableTextField type='withLabel' width={760} height={60} bgColor='#F2F2F2' placeholder='Select Image'
                        state={logo} setState={updateOrganization} onClick={openFile} readOnly target='logo' />
                </Grid>
                <input id='file-upload' type="file" style={{ display: 'none' }} onChange={e => handleFileInputChange(e.target.files[0])} />
                <Grid container justifyContent='flex-end' style={{ marginRight: 34, marginTop: -30 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', width: 760 }}>
                        {hasImage ? !loadingImage && logo ?
                            <img src={logo} alt="Org Logo" style={{ maxHeight: 500, maxWidth: 760 }} /> :
                            <Loader height={250} /> :
                            <div style={previewStyle}>
                                <p>No preview available</p>
                            </div>}
                    </div>
                </Grid>
            </Grid>
            <Grid container justifyContent='space-evenly' style={{ paddingInline: 100, marginBlock: 30 }}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => cancelEdit()} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
        </Box>
    )
}