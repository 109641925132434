import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CSVUploadTable from "../components/table/CSVUploadTable";
import CSVUploadFilter from "../components/CSVUploadFilter";
import ReusableToggle from "../components/general/ReusableToggle";
import NunitoText from "../components/general/NunitoText";
import ReusableTextField from "../components/general/ReusableTextField";
import ReusableSelect from "../components/general/ReusableSelect";
import ReusableButton from "../components/general/ReusableButton";
import ReusableCheckbox from "../components/general/ReusableCheckbox";
import ReusableAutocompleteWithID from "../components/general/ReusableAutocompleteWithID";
import ReusableAutocomplete from "../components/general/ReusableAutocomplete";
import schoolFile from "../files/school_template.csv";
import participantFileForAdmin from "../files/participant_template_admin.csv";
import participantprivate from "../files/participant_template_private.csv";
import participantFileForPartner from "../files/participant_template_partner.csv";
import participantFileForTeacher from "../files/participant_template_teacher.csv";
import participantFileForCentreTeacher from "../files/participants_template_teacher_from_centre.csv";
import {
  invalidName,
  invalidNumber,
  invalidEmail,
  delimitCSVRow,
  gradeOptions as allGradeOptions,
  makeSureIsArray,
} from "../functions/general";
import {
  getSchools,
  getCompetitions,
  getOrganizations,
} from "../functions/getData";
import { addSchool, addParticipant } from "../functions/postData";
import { showWarningSwal, isEmpty, warningMessage } from "../functions/alert";
import { showNotification } from "../functions/snackbar";
import {
  isAdmin,
  isAdminOrPartnerOrAssistant,
  isManagerOrTeacher,
  isPartnerOrAssistant,
  isFromTuitionCentre,
  getdata,
  isPrivate,
} from "../functions/checkrole";
import { useSnackbar } from "notistack";
import Loader from "../components/general/Loader";
const gridStyle = { textAlign: "center" };
export function MapField({
  count,
  id,
  label,
  firstRow,
  csvHeaders,
  csvMapFields,
  setCSVMapFields,
  csvArray,
  copyCSVArray,
  setCopyCSVArray,
  noClick,
}) {
  const changeCSVMapField = (value) => {
    let newCSVMapFields = { ...csvMapFields };
    newCSVMapFields[id] = value;
    setCSVMapFields(newCSVMapFields);
    const newCopyCSVArray = copyCSVArray.map((c, i) => ({
      ...c,
      [csvHeaders[count]]: csvArray[i][value],
    }));

    setCopyCSVArray(newCopyCSVArray);
  };
  const checkUnique = (arr) => new Set(arr).size !== arr.length;
  // Check if all values in csvMapFields are unique. If not, set border to red
  const containerStyle = { border: "1px solid", paddingBlock: 10 };
  const noClickContainerStyle = { ...containerStyle, pointerEvents: "none" };
  return (
    <Grid
      container
      alignItems="center"
      style={noClick ? noClickContainerStyle : containerStyle}
    >
      <Grid item xs={4} style={gridStyle}>
        <NunitoText value={label || ""} fontSize={16} fontWeight={600} />
      </Grid>
      <Grid item xs={4} style={gridStyle}>
        {firstRow !== undefined && csvMapFields !== undefined && (
          <NunitoText
            value={firstRow[csvHeaders[count]] || ""}
            fontSize={16}
            fontWeight={600}
          />
        )}
      </Grid>
      <Grid item xs={4} style={gridStyle}>
        {csvMapFields[id] && (
          <ReusableSelect
            type="type2"
            width={230}
            state={csvMapFields[id]}
            setState={changeCSVMapField}
            btnBgColor="#5E75C3"
            height={45}
            options={csvHeaders.map((c) => ({ value: c, option: c }))}
            label={label}
            required={checkUnique(Object.values(csvMapFields))}
            borderColor="#707070"
          />
        )}
        {/* {csvMapFields[id] && <ReusableSelect type='type2' width={230} state={csvMapFields[id]} setState={changeCSVMapField}
                    btnBgColor='#5E75C3' height={45} options={csvHeaders.map(c => ({ value: c, option: c }))} borderColor='#707070' />} */}
      </Grid>
    </Grid>
  );
}
const schoolHeader = [
  { id: "name", label: "School Name", key: "name" },
  {
    id: "name_in_certificate",
    label: "School Name in Certificate",
    key: "name_in_certificate",
  },
  { id: "address", label: "Address", key: "address" },
  { id: "postal", label: "Postal Code", key: "postal" },
  { id: "phone", label: "Phone Number", key: "phone" },
  { id: "province", label: "Province/State", key: "province" },
  { id: "email", label: "Email", key: "email" },
];
const participantHeader = () =>
  [
    { id: "name", label: "Name", key: "name" },
    { id: "school", label: "School", key: "school" },
    { id: "email", label: "email", key: "email" },
    { id: "tuition_centre", label: "Tuition", key: "tuition_centre" },
    { id: "grade", label: "Grade", key: "grade" },
    { id: "class", label: "Class", key: "class" },
    { id: "identfier", label: "identfier", key: "identfier" },
  ].filter(Boolean);
const uploadToSchool = () =>
  [
    isAdmin() && {
      label: "Country",
      state: "",
      key: "country_id",
      header: "countries",
    },
    {
      label: "Tuition Centre",
      state: false,
      key: "private",
      header: "tuition centre",
    },
  ].filter(Boolean);
const uploadToParticipant = () =>
  [
    isAdminOrPartnerOrAssistant() && {
      label: "Type",
      state: "",
      key: "type",
      header: "type",
    },
    {
      label: "Competition",
      state: "",
      key: "competition_id",
      header: "competitions",
    },
    isAdmin() && {
      label: "Organization",
      state: "",
      key: "organization_id",
      header: "organizations",
    },
    isAdmin() && {
      label: "Country",
      state: "",
      key: "country_id",
      header: "countries",
    },
    { label: "School", state: "", key: "school", header: "schools" },
    isAdminOrPartnerOrAssistant() && {
      label: "Tuition Centre",
      state: "",
      key: "tuition_centre",
      header: "tuition centres",
    },
    // { label: 'Is participant a private candidate?', state: false, key: 'forPartner', header: 'tuition centre' },
  ].filter(Boolean);
export default function CSVUpload() {
  document.title = "CSV Upload";
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const [uploadSchool, setUploadSchool] = useState(
    !(isManagerOrTeacher() || history.location.search === "?upload=participant")
  );
  const [schoolFiltered, setSchoolFiltered] = useState(uploadToSchool());
  const [participantFiltered, setParticipantFiltered] = useState(
    uploadToParticipant()
  );

  const typeOptions = [
    { id: 0, name: "School Candidate" },
    { id: -1, name: "Private Candidate" },
    { id: 1, name: "Organization Candidate" },
  ];
  const [competitionOptions, setCompetitionOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [allOrganizationOptions, setAllOrganizationOptions] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const allCountryOptions = useSelector((state) => state.countryOptions);
  const [countryOptions, setCountryOptions] = useState(allCountryOptions);
  const [allSchoolOptions, setAllSchoolOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [allTuitionCentreOptions, setAllTuitionCentreOptions] = useState([]);
  const [tuitionCentreOptions, setTuitionCentreOptions] = useState([]);

  const [headers, setHeaders] = useState(
    uploadSchool ? schoolHeader : participantHeader()
  );
  const [csvFile, setCSVFile] = useState(null);
  const [csvHeaders, setCSVHeaders] = useState();
  const [csvMapFields, setCSVMapFields] = useState();
  const [csvArray, setCSVArray] = useState([]);
  const [copyCSVArray, setCopyCSVArray] = useState([]);
  const [list, setList] = useState([]);
  const [massAssignGrade, setMassAssignGrade] = useState("");
  const [massAssignSchool, setMassAssignSchool] = useState("");
  const [massAssignTuition, setMassAssignTuition] = useState("");
  const [massSchoolOptions, setMassSchoolOptions] = useState([]);
  const [massTuitionCentreOptions, setMassTuitionCentreOptions] = useState([]);
  const [errors, setErrors] = useState([]);
  //Table
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //Select from the table
  const [selected, setSelected] = useState([]);
  const [selecting, setSelecting] = useState([]);
  const [sort, setSort] = useState("");
  const [okToHvDupes, setOkToHvDupes] = useState(false);
  const [loading, setLoading] = useState(false);
  const allowedStatus = useMemo(
    () =>
      [
        "active",
        isAdminOrPartnerOrAssistant() && "ready",
        isAdmin() && "lock",
      ].filter(Boolean),
    []
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const checkIfCompOrgIsAvailable = useCallback(
    (org) => {
      return org;
    },
    [allowedStatus]
  );
  const loadCompetitionsForAdmin = useCallback(
    (competitions) => {
      let filteredCompetitions = makeSureIsArray(competitions).filter((d) => {
        let foundOrganizations = d.competition_organization;
        return (
          d.status === "active" &&
          foundOrganizations.length &&
          foundOrganizations.some((org) => checkIfCompOrgIsAvailable(org))
        );
      });
      setCompetitionOptions(
        filteredCompetitions.map((d) => ({
          id: d.id,
          name: d.name,
          competition_organization: d.competition_organization,
          allowed_grades: d.allowed_grades,
        }))
      );
    },
    [checkIfCompOrgIsAvailable]
  );
  const loadCompetitionsForPartnerOrBelow = useCallback(
    (competitions) => {
      let filteredCompetitions = makeSureIsArray(competitions).filter((d) =>
        checkIfCompOrgIsAvailable(d.competition_organization[0])
      );
      setCompetitionOptions(
        filteredCompetitions.map((d) => ({
          id: d.id,
          name: d.name,
          competition_organization: d.competition_organization,
          allowed_grades: d.allowed_grades,
        }))
      );
    },
    [checkIfCompOrgIsAvailable]
  );
  const controller = useMemo(() => new AbortController(), []);
  const signal = controller.signal;
  useEffect(() => {
    getCompetitions("?limits=50&status=active", signal)
      .then((c) => {
        let competitions = c.competitionList.data;
        if (isAdmin()) {
          loadCompetitionsForAdmin(competitions);
        } else {
          loadCompetitionsForPartnerOrBelow(competitions);
        }
      })
      .catch((e) => console.log(e));
    if (isAdmin()) {
      getOrganizations("?limits=100&status=active", signal)
        .then((o) => {
          setAllOrganizationOptions(
            o.OrganizationLists.data.map((d) => ({
              id: d.id,
              name: d.name,
              users: d.users,
            }))
          );
        })
        .catch((e) => console.log(e));
    } else if (isPartnerOrAssistant()) {
      getSchools("?limits=0&status=active", signal)
        .then((s) => {
          setAllSchoolOptions(
            s.SchoolLists.data
              .filter((s) => !s.private)
              .map((d) => ({ id: d.id, name: d.name }))
          );
          setAllTuitionCentreOptions(
            s.SchoolLists.data
              .filter((s) => s.private)
              .map((d) => ({ id: d.id, name: d.name }))
          );
        })
        .catch((e) => console.log(e));
    }
    return () => controller.abort();
  }, [
    user,
    loadCompetitionsForAdmin,
    loadCompetitionsForPartnerOrBelow,
    controller,
    signal,
  ]);
  const getAppropriateOrgs = (competition_id) => {
    let competitionOrganizations = competitionOptions.find(
      (c) => c.id === competition_id
    ).competition_organization;
    // let filteredOrganizations = competitionOrganizations.filter(org => checkIfCompOrgIsAvailable(org))
    let filteredOrganizations = competitionOrganizations;
    setOrganizationOptions(
      allOrganizationOptions.filter((o) =>
        filteredOrganizations.map((org) => org.organization_id).includes(o.id)
      )
    );
  };
  const resetSchoolFiltered = () => {
    let newSchoolFiltered = [...schoolFiltered];
    newSchoolFiltered.forEach((f) =>
      typeof f.state === "boolean" ? (f.state = false) : (f.state = "")
    );
    setSchoolFiltered(newSchoolFiltered);
  };
  const resetParticipantFiltered = () => {
    let newParticipantFiltered = [...participantFiltered];
    newParticipantFiltered.forEach((f) =>
      typeof f.state === "boolean" ? (f.state = false) : (f.state = "")
    );
    setParticipantFiltered(newParticipantFiltered);
  };
  // Upload to related functions
  const onChangeUploadSchool = (value) => {
    setUploadSchool(value);
    cancelCSVFile();
    resetSchoolFiltered();
    resetParticipantFiltered();
    setHeaders(value ? schoolHeader : participantHeader());
  };
  const onChangeSchoolFiltered = (count, identifier, value) => {
    let newSchoolFiltered = [...schoolFiltered];
    newSchoolFiltered[count][identifier] = value;
    setSchoolFiltered(newSchoolFiltered);
  };
  const onChangeCompetition = (value) => {
    let newParticipantFiltered = [...participantFiltered];
    newParticipantFiltered.forEach((n) => {
      if (
        ["organization_id", "country_id", "school", "tuition_centre"].includes(
          n.key
        )
      )
        n.state = "";
    });
    setParticipantFiltered(newParticipantFiltered);
    if (value !== "") {
      let selectedCompetition = competitionOptions.find((c) => c.id === value);
      setGradeOptions(
        allGradeOptions.filter((g) =>
          selectedCompetition.allowed_grades
            .map((z) => Number(z))
            .includes(g.id)
        )
      );
      if (isAdmin()) {
        getAppropriateOrgs(value);
      } else if (isPartnerOrAssistant()) {
        setSchoolOptions(allSchoolOptions);
        setTuitionCentreOptions(allTuitionCentreOptions);
      }
    }
  };
  const onChangeType = (value) => {
    let newParticipantFiltered = [...participantFiltered];
    let school = newParticipantFiltered.find((n) => n.key === "school").state;
    if (value === 0 && school === -1) {
      newParticipantFiltered.find((n) => n.key === "school").state = "";
    }
    newParticipantFiltered.find((n) => n.key === "tuition_centre").state = "";
    setParticipantFiltered(newParticipantFiltered);
  };
  const onChangeOrganization = (value) => {
    let newParticipantFiltered = [...participantFiltered];
    newParticipantFiltered
      .filter((n) => ["country_id", "school", "tuition_centre"].includes(n.key))
      .forEach((n) => (n.state = ""));
    if (value !== "") {
      let competition = newParticipantFiltered.find(
        (n) => n.key === "competition_id"
      ).state;
      let competitionOrganizations = competitionOptions.find(
        (c) => c.id === competition
      ).competition_organization;
      let participatingCountries = competitionOrganizations
        .filter((o) => o.organization_id === value)
        .map((c) => c.country_id);
      let organizationUsers = organizationOptions
        .find((o) => o.id === value)
        .users.filter((u) => u.role_id === 2 && u.status !== "deleted");
      let organizationCountries = [
        ...new Set(organizationUsers.map((u) => u.country_id)),
      ].filter((c) => participatingCountries.includes(c));
      setCountryOptions(
        allCountryOptions.filter((c) => organizationCountries.includes(c.id))
      );
    }
  };
  const onChangeCountry = (value) => {
    let newParticipantFiltered = [...participantFiltered];
    newParticipantFiltered
      .filter((n) => ["school", "tuition_centre"].includes(n.key))
      .forEach((n) => (n.state = ""));
    setParticipantFiltered(newParticipantFiltered);
    if (value !== "") {
      getSchools(`?country_id=${value}&status=active&limits=0`, signal)
        .then((s) => {
          let allSchools = s.SchoolLists.data
            .filter((d) => !d.private)
            .map((d) => ({ id: d.id, name: d.name }));
          let allCentres = s.SchoolLists.data
            .filter((d) => d.private)
            .map((d) => ({ id: d.id, name: d.name }));
          setAllSchoolOptions(allSchools);
          setAllTuitionCentreOptions(allCentres);
          setSchoolOptions(allSchools);
          setTuitionCentreOptions(allCentres);
        })
        .catch((e) => console.log(e));
    }
  };
  const onChangeParticipantFiltered = (count, identifier, value) => {
    let newParticipantFiltered = [...participantFiltered];
    newParticipantFiltered[count][identifier] = value;
    switch (newParticipantFiltered[count].key) {
      case "competition_id":
        onChangeCompetition(value);
        break;
      case "type":
        onChangeType(value);
        break;
      case "organization_id":
        onChangeOrganization(value);
        break;
      case "country_id":
        onChangeCountry(value);
        break;
      default:
    }
    setParticipantFiltered(newParticipantFiltered);
  };
  // CSV File related functions
  const openFileInput = () =>
    document.getElementById("file-upload-for-csv").click();
  const onChangeCSVFile = (e, file) => {
    e.target.value = null;
    setCSVFile(file);
    const reader = new FileReader();
    try {
      reader.onload = (e) => {
        const text = e.target.result;
        processCSV(text);
      };
      reader.readAsText(file);
    } catch (err) {}
  };
  const processCSV = (str, delim = ",") => {
    console.log("here");
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    if (rows.length >= 401 && !uploadSchool)
      return showWarningSwal("participants entry must not exceed 400");
    //Get the header from the large chunk of string read from the csv file
    const header = str
      .slice(0, str.indexOf("\n"))
      .trim()
      .replace(/['"]+/g, "")
      .split(delim);
    setCSVHeaders(header);
    console.log(headers);
    //Set an object of mapFields
    let newMapFields = {};
    mapheaders.forEach((h, i) => {
      if (header[i]) newMapFields[h.id] = header[i];
    });
    setCSVMapFields(newMapFields);
    //Get all the data from the large chunk of string read from the csv file as an array

    console.log(rows.length);
    // let isSameColumns = [... new Set(rows.map(r => r.split(',').length).slice(0,-1))].length === 1
    const newArray = rows.map((row) => {
      // const values = row.split(',')
      let rowarr = row.split("");
      rowarr.map((el, index) => {
        if (el === "," && rowarr[index - 1] === ",") {
          rowarr.splice(index, 0, " ");
        }
      });

      const values = delimitCSVRow(rowarr.join(""));
      const eachObject = header.reduce((obj, header, i) => {
        //This function will return an array with the last item being an object with undefined values
        //So we check if the object is undefined before using string functions on the object
        obj[header] = values[i]
          ? values[i].trim().replace(/["]+/g, "")
          : values[i];
        return obj;
      }, {});
      return eachObject;
    });

    //We do not want to set the last item as the csvArray since the last item is just undefined
    //as we are setting the rows by checking if there is a next row
    const a = newArray.filter((n, i) => i < newArray.length - 1);

    if (isAdmin()) {
      setCSVArray(a);
      //Keep the original as reference and use the copyCSVArray to decide which columns to map to which field
      setCopyCSVArray(a);
    } else submitCSVAsList(a);
    // if (!isAdmin()) submitCSVAsList(a)
  };
  const cancelCSVFile = () => {
    setCSVFile(null);
    setCSVHeaders();
    setCSVMapFields();
    setCSVArray([]);
    setCopyCSVArray([]);
    setList([]);
    setSort("");
  };
  const submitCSVAsList = (data) => {
    let newArr = [];
    data.forEach((row, index) => {
      let newObj = {};
      newObj.id = index;
      // Load all selected options from the Upload To: section
      if (uploadSchool)
        schoolFiltered.forEach((f) => (newObj[f.key] = f.state));
      else
        participantFiltered.forEach((f) => {
          switch (f.key) {
            case "school":
              newObj[f.key] =
                actualSchoolOptions.find((s) => s.id === f.state)?.name || "";
              break;
            case "tuition_centre":
              newObj[f.key] =
                tuitionCentreOptions.find((s) => s.id === f.state)?.name || "";
              break;
            default:
              newObj[f.key] = f.state;
          }
        });
      mapheaders.forEach(({ key }, i) => {
        if (["school", "tuition_centre"].includes(key)) {
          if (key === "tuition_centre") {
            if (
              participantFiltered.find((p) => p.key === "type").state !== -1
            ) {
              newObj[key] = "";
            } else {
              if (newObj[key] === "") newObj[key] = Object.values(row)[i];
            }
          } else {
            if (newObj[key] === "") newObj[key] = Object.values(row)[i];
          }
          // if (newObj[key] === '') newObj[key] = Object.values(row)[i]
        } else {
          newObj[key] = Object.values(row)[i];
        }
      });
      newArr.push(newObj);
    });
    setList(newArr);
    setMassSchoolOptions(schoolOptions);
    setMassTuitionCentreOptions(tuitionCentreOptions);
  };
  // Handle changes to dataset
  const cancelList = () => {
    setList([]);
    setSort("");
  };
  const massUpdateList = (identifier, value) => {
    let newList = [...list];
    newList.forEach((n) => {
      if (selected.includes(n.id)) n[identifier] = value;
    });
    setList(newList);
  };
  const downloadTemplate = (type) => {
    var a = document.createElement("a");
    let file = uploadSchool
      ? schoolFile
      : isAdminOrPartnerOrAssistant()
      ? type === "private"
        ? participantprivate
        : participantFileForAdmin
      : participantFileForAdmin;
    let suffix = isAdmin()
      ? "-for-admin"
      : isPartnerOrAssistant()
      ? "-for-partner-or-assistant"
      : "-for-school-manager-teacher";
    a.setAttribute("href", file);
    a.setAttribute(
      "download",
      `upload-${uploadSchool ? "school" : "participant"}-template${
        uploadSchool ? "" : suffix
      }.csv`
    );
    a.click();
  };
  const checkSelect = () => {
    let arr = [];
    if (uploadSchool) {
      arr = schoolFiltered
        .filter((s) => s.key !== "private")
        .map((s) => ({ name: s.label, state: s.state }));
    } else {
      arr = participantFiltered
        .filter((s) => !["school", "tuition_centre"].includes(s.key))
        .map((p) => ({ name: p.label, state: p.state }));
    }
    if (isEmpty(arr.map((a) => a.state))) return warningMessage(arr);
    if (
      !uploadSchool &&
      isAdminOrPartnerOrAssistant() &&
      participantFiltered.find((p) => p.key === "type").state === 0 &&
      isFromTuitionCentre() &&
      participantFiltered.find((p) => p.key === "school").state === -1
    ) {
      return showWarningSwal(
        "Please select an actual school if participant is not private candidate"
      );
    }
    return openFileInput();
  };
  const exceedlimit = (list) => {
    return list.length >= 400;
  };
  const getGradeID = (value) =>
    gradeOptions.find((o) => o.name.toLowerCase() === value.toLowerCase()).id;
  // const getPartnerID = value => massPartnerOptions.find(o => o.name === value).id
  const getSchoolID = (value) =>
    massSchoolOptions.find((s) => s.name.toLowerCase() === value.toLowerCase())
      .id;
  const getTuitionCentreID = (value) =>
    massTuitionCentreOptions.find(
      (t) => t.name.toLowerCase() === value.toLowerCase()
    ).id;
  // const getPartnerCentreID = value => partnerCentreOptions.find(p => p.user_id === value).id
  const onSubmit = () => {
    if (list.length && sortOptions().length)
      return showWarningSwal("There are errors in your data");
    if (
      !uploadSchool &&
      list.map((l) => isDuplicate(list, l)).includes(true) &&
      !okToHvDupes
    ) {
      return showWarningSwal(
        'There are duplicate particiant entries!<br>Please check the "I acknowledge there are duplicate participant entries checkbox"'
      );
    }
    setLoading(true);
    let passedInList = [];
    list
      .sort((a, b) => a.id - b.id)
      .forEach((l, i) => {
        let { id, ...obj } = l;
        passedInList.push(obj);
        obj.id = i;
      });
    let payload = [];
    console.log(passedInList);
    if (uploadSchool) {
      payload = passedInList.map((p) => ({
        name: p.name.toUpperCase(),
        country_id: isAdmin() ? p.country_id : user.country_id,
        private: Number(p.private),
        address: p.address,
        postal: p.postal,
        phone: p.phone,
        province: p.province.toUpperCase(),
        email: p.email,
        name_in_certificate: p.name_in_certificate,
      }));
    } else {
      if (isAdmin()) {
        console.log(passedInList);
        payload = passedInList.map((p) => ({
          identifier: p.identfier,
          name: p.name.toUpperCase(),
          grade: getGradeID(p.grade),
          competition_id: p.competition_id,
          class: p.class,
          email: p.email,
          for_partner: isManagerOrTeacher() ? 0 : [1].includes(p.type) ? 1 : 0,
          organization_id: p.organization_id,
          country_id: p.country_id,
          school_id: ["", "Home School"].includes(p.school)
            ? null
            : getSchoolID(p.school),
          tuition_centre_id:
            p.tuition_centre === ""
              ? null
              : getTuitionCentreID(p.tuition_centre),
        }));
      } else if (isPartnerOrAssistant()) {
        payload = passedInList.map((p) => ({
          identifier: p.identfier,
          name: p.name.toUpperCase(),
          grade: getGradeID(p.grade),
          competition_id: p.competition_id,
          class: p.class,
          email: p.email,
          for_partner: isManagerOrTeacher() ? 0 : [1].includes(p.type) ? 1 : 0,
          school_id: ["", "Home School"].includes(p.school)
            ? null
            : getSchoolID(p.school),
          tuition_centre_id:
            p.tuition_centre === ""
              ? null
              : getTuitionCentreID(p.tuition_centre),
        }));
      } else if (isManagerOrTeacher()) {
        if (isFromTuitionCentre()) {
          payload = passedInList.map((p) => ({
            name: p.name.toUpperCase(),
            grade: getGradeID(p.grade),
            competition_id: p.competition_id,
            class: p.class,
            for_partner: isManagerOrTeacher()
              ? 0
              : [1].includes(p.type)
              ? 1
              : 0,
            school_id: ["", "Home School"].includes(p.school)
              ? null
              : getSchoolID(p.school),
          }));
        } else {
          payload = passedInList.map((p) => ({
            name: p.name.toUpperCase(),
            grade: getGradeID(p.grade),
            competition_id: p.competition_id,
            class: p.class,
            for_partner: isManagerOrTeacher()
              ? 0
              : [1].includes(p.type)
              ? 1
              : 0,
          }));
        }
      }
    }
    let addRecords = uploadSchool ? addSchool : addParticipant;
    payload = uploadSchool ? { school: payload } : { participant: payload };
    console.log(payload);
    addRecords(payload, signal)
      .then((d) => {
        setLoading(false);
        if (d.errors) {
          let temperrors = [];
          Object.keys(d.errors).forEach(function (key, INDEX) {
            let errorINDEX = key.split(".")[1];
            if (!temperrors.includes(errorINDEX)) {
              temperrors.push(errorINDEX);
            }
          });
          let filtered = temperrors.filter((el) => {
            return el !== undefined;
          });
          setErrors(filtered);

          let templist = list;

          console.log(errors);
        } else {
          showNotification(
            "success",
            d.message,
            enqueueSnackbar,
            closeSnackbar
          );
          history.push(uploadSchool ? "/schools" : "/participants");
          setErrors([]);
        }
        // if (d.status === 201) {
        //     showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
        //     history.push(uploadSchool ? '/schools' : '/participants')
        // }
      })
      .catch((e) => {
        if (e.name !== "AbortError") setLoading(false);
      });
  };
  const massDeleteList = () => {
    let newList = [...list];
    selected.forEach((s) => (newList = newList.filter((n) => n.id !== s)));
    setList(newList);
    setSelected([]);
    setSelecting([]);
  };
  const noCondition = () => false;
  // const invalidSchlName = value => !/^[\u{2019}\'\;\.\,\s\(\)\[\]\w-]*$/.test(value)
  const schoolErrors = [
    { id: "name", label: "School Name", condition: noCondition },
    { id: "address", label: "Address", condition: noCondition },
    { id: "postal", label: "Postal Code", condition: noCondition },
    { id: "phone", label: "Phone Number", condition: invalidNumber },
    { id: "province", label: "Province/State", condition: invalidName },
    { id: "email", label: "Email", condition: invalidEmail },
  ];
  const homeSchoolOptions = participantFiltered.find((p) => p.key === "type")
    ?.state !== 0 && { id: -1, name: "Home School" };
  const actualSchoolOptions = [homeSchoolOptions, ...schoolOptions].filter(
    Boolean
  );
  const actualMassSchoolOptions = [
    homeSchoolOptions,
    ...massSchoolOptions,
  ].filter(Boolean);
  const checkAutocomplete = (id, value) => {
    switch (id) {
      case "school":
        return !actualMassSchoolOptions.find(
          (s) => s.name && value && s.name.toLowerCase() === value.toLowerCase()
        );
      case "tuition_centre":
        return !tuitionCentreOptions.find(
          (t) => t.name && value && t.name.toLowerCase() === value.toLowerCase()
        );
      case "grade":
        return !gradeOptions.find(
          (g) => g.name && value && g.name.toLowerCase() === value.toLowerCase()
        );
      default:
    }
  };
  const participantErrors = () =>
    [
      { id: "name", label: "Name", condition: () => noCondition() },
      (isAdminOrPartnerOrAssistant() || isFromTuitionCentre()) && {
        id: "school",
        label: "School",
        condition: (value) => checkAutocomplete("school", value),
      },
      isAdminOrPartnerOrAssistant() && {
        id: "tuition_centre",
        label: "Tuition",
        condition: (value, school) => {
          if (participantFiltered.find((p) => p.key === "type").state === 0) {
            if (school === "Home School")
              return checkAutocomplete("tuition_centre", value);
          }
          if (value === "") return false;
          return checkAutocomplete("tuition_centre", value);
        },
      },
      {
        id: "grade",
        label: "Grade",
        condition: (value) => checkAutocomplete("grade", value),
      },
      { id: "class", label: "Class", condition: () => noCondition() },
    ].filter(Boolean);
  const uploadError = uploadSchool ? schoolErrors : participantErrors();
  const sortOptions = () => {
    let options = [];
    uploadError.forEach((e) => {
      list.forEach((l) => {
        if (e.id === "tuition_centre") {
          if (e.condition(l[e.id], l.school) && !options.includes(e))
            options.push(e);
        } else {
          if (e.condition(l[e.id]) && !options.includes(e)) options.push(e);
        }
      });
    });

    return options;
  };
  const onChangeSort = (value) => {
    setSort(value);
    if (list) {
      let newList = [...list];
      let ids = [];
      newList.forEach((l) => {
        if (
          sortOptions()
            .find((s) => s.id === value)
            .condition(l[value])
        )
          ids.push(l.id);
      });
      ids.forEach((id) => {
        let newOne = newList.find((n) => n.id === Number(id));
        if (newOne !== undefined) {
          newList = newList.filter((n) => n.id !== Number(id));
          newList.unshift(newOne);
        }
      });
      setList(newList);
    }
  };
  const checkOrUncheck = () => {
    let rowsWithError = [];
    list.forEach((row) => {
      if (checkForErrorInRow(row)) rowsWithError.push(row);
    });
    let newSelected = [...selected];
    let newSelecting = [...selecting];
    for (let i = 0; i < rowsWithError.length; i++) {
      if (newSelected.includes(rowsWithError[i].id)) {
        let index = newSelected.indexOf(rowsWithError[i].id);
        newSelected.splice(index, 1);
        newSelecting.splice(index, 1);
      } else {
        newSelected.push(Number(rowsWithError[i].id));
        newSelecting.push(
          list.find((l) => l.id === Number(rowsWithError[i].id)).name
        );
      }
    }
    setSelected(newSelected);
    setSelecting(newSelecting);
  };
  const checkForErrorInRow = (row) => {
    // return true upon encountering error at any column
    for (let i = 0; i < uploadError.length; i++) {
      if (uploadError[i].id === "tuition_centre") {
        if (uploadError[i].condition(row[uploadError[i].id], row.school))
          return true;
      } else {
        if (uploadError[i].condition(row[uploadError[i].id])) return true;
      }
    }
    return false;
  };
  const checkForError = (list) => {
    if (list === undefined) return false;
    // return true upon encountering error at any row
    for (let i = 0; i < list.length; i++)
      if (checkForErrorInRow(list[i])) {
        return true;
      }
    return false;
  };
  const isDuplicate = (list, row) => {
    let occurences = 0;
    list.forEach((l) => {
      if (
        l.name === row.name &&
        l.school === row.school &&
        l.tuition_centre === row.tuition_centre &&
        l.grade === row.grade &&
        l.class === row.class
      ) {
        occurences += 1;
      }
    });
    return occurences > 1;
  };
  const correctColumnsInCSV = (arr) => {
    return new Set(arr).size === arr.length;
    // return Object.keys(csvHeaders).length === headers.length * new Set(arr).size !== arr.length
    //     && new Set(arr).size === arr.length
  };
  const onClickSubmitCSVMapFields = () => {
    return correctColumnsInCSV(Object.values(csvMapFields))
      ? list.length
        ? null
        : submitCSVAsList(copyCSVArray)
      : showWarningSwal("Please assign only one column to each field.");
  };
  const templateDivStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    border: "1px solid #707070",
    borderRadius: 12,
    height: 220,
    width: "100%",
  };
  const massAssignStyle = {
    display: "flex",
    alignItems: "center",
    marginRight: 30,
  };
  let owoHeaders = [];
  if (isAdminOrPartnerOrAssistant()) {
    owoHeaders =
      participantFiltered.find((p) => p.key === "type").state === -1
        ? headers
        : headers.filter((h) => h.id !== "tuition_centre");
  } else {
    owoHeaders = isPrivate()
      ? headers
      : headers.filter((h) => h.id !== "tuition_centre");
  }

  let mapheaders =
    participantFiltered[0].state === -1
      ? headers
      : headers.filter((el) => {
          return el.label !== "Tuition";
        });
  console.log(mapheaders, headers);
  return (
    <Box style={{ flexGrow: 1 }}>
      <div style={{ display: "flex", alignItems: "center", padding: 12 }}>
        <ReusableButton
          text="Dashboard"
          fontSize={14}
          bgColor="#F16774"
          height={36}
          width={125}
          br={18}
          to="/dashboard"
          iconType="home"
        />
        <ChevronRightIcon />
        <ReusableButton
          text="CSV Upload"
          fontSize={14}
          bgColor="#F16774"
          height={36}
          br={18}
        />
      </div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ paddingInline: 42, marginTop: 30 }}
      >
        <NunitoText
          value="CSV Upload"
          fontSize={40}
          fontWeight={700}
          italic
          color="#144A94"
        />
        {isAdminOrPartnerOrAssistant() && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <NunitoText
              value="Upload Type"
              fontSize={20}
              fontWeight={600}
              marginRight={120}
            />
            <ReusableToggle
              type="text"
              width={250}
              falseValue="Participants"
              trueValue="Schools"
              state={uploadSchool}
              setState={onChangeUploadSchool}
            />
          </div>
        )}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 30 }}
        >
          {uploadSchool
            ? Boolean(schoolFiltered.length) && (
                <CSVUploadFilter
                  filtered={schoolFiltered}
                  options={allCountryOptions}
                  setState={onChangeSchoolFiltered}
                  uploadingFor="school"
                  list={list}
                  setFiltered={setSchoolFiltered}
                />
              )
            : Boolean(participantFiltered.length) && (
                <CSVUploadFilter
                  filtered={participantFiltered}
                  options={[
                    typeOptions,
                    competitionOptions,
                    organizationOptions,
                    countryOptions,
                    actualSchoolOptions,
                    tuitionCentreOptions,
                  ]}
                  setState={onChangeParticipantFiltered}
                  uploadingFor="participant"
                  list={list}
                  setFiltered={setParticipantFiltered}
                />
              )}
        </Grid>
        {/* school: {JSON.stringify(schoolFiltered)}<br /><br /> */}
        {/* participant: {JSON.stringify(participantFiltered)}<br /><br /> */}
        <Grid
          container
          justifyContent="space-between"
          style={{ marginTop: 15 }}
        >
          <div>
            <NunitoText
              value={`File Select:${csvFile ? ` ${csvFile.name}` : ""}`}
              fontSize={20}
              fontWeight={600}
              color="#144A94"
              align="left"
            />
            <input
              id="file-upload-for-csv"
              type="file"
              accept=".csv"
              onChange={(e) => onChangeCSVFile(e, e.target.files[0])}
              style={{ display: "none" }}
            />
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 20 }}
            >
              <ReusableButton
                text="Select File"
                bgColor="#144A94"
                fontSize={16}
                height={59}
                width={160}
                iconType="file"
                onClick={() => checkSelect()}
              />
              {/* <ReusableTextField type='clickOnly' width={500} height={60} bgColor='#F2F2F2'
                                marginLeft={120} onClick={() => checkSelect()} /> */}
            </div>
            {Boolean(csvArray.length) && isAdmin() && (
              <NunitoText
                value="Map Fields"
                fontSize={30}
                fontWeight={700}
                color="#144A94"
                marginTop={60}
                marginLeft={30}
                align="left"
              />
            )}
          </div>
          {!uploadSchool && isAdminOrPartnerOrAssistant() ? (
            <Grid
              flexDirection={"row"}
              width={"70%"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <div style={{ width: "45%" }}>
                <div style={templateDivStyle}>
                  <NunitoText
                    value="PLEASE DOWNLOAD AND USE TEMPLATE HERE FOR SCHOOL/ORGANIZATION"
                    fontSize={24}
                    fontWeight={400}
                    color="#F16774"
                  />
                  <ArrowDownwardIcon
                    style={{ color: "#F16774", fontSize: 45 }}
                  />
                  <ReusableButton
                    text="Download Template"
                    bgColor="#F16774"
                    fontSize={20}
                    height={57}
                    width={325}
                    iconType="download"
                    br={24}
                    onClick={() => downloadTemplate("notprivate")}
                  />
                </div>
                {uploadSchool ? (
                  <NunitoText
                    value="Name, Phone Number and Email are mandatory"
                    fontSize={20}
                    fontWeight={600}
                  />
                ) : (
                  <>
                    <NunitoText
                      value={`Name, School and Grade are mandatory`}
                      fontSize={20}
                      fontWeight={600}
                    />
                    <NunitoText
                      value={`Please ensure that the entered value for ${
                        isAdminOrPartnerOrAssistant() &&
                        `School, Tuition, ${isAdmin() && "Partner"} and `
                      }Grade matches the options exactly
                        (even the casing)`}
                      fontSize={20}
                      fontWeight={600}
                    />
                  </>
                )}
              </div>
              {
                <div style={{ width: "45%" }}>
                  <div style={templateDivStyle}>
                    <NunitoText
                      value="PLEASE DOWNLOAD AND USE TEMPLATE HERE FOR PRIVATE CANDIDATE"
                      fontSize={24}
                      fontWeight={400}
                      color="#F16774"
                    />
                    <ArrowDownwardIcon
                      style={{ color: "#F16774", fontSize: 45 }}
                    />
                    <ReusableButton
                      text="Download Template"
                      bgColor="#F16774"
                      fontSize={20}
                      height={57}
                      width={325}
                      iconType="download"
                      br={24}
                      onClick={() => downloadTemplate("private")}
                    />
                  </div>

                  <>
                    <NunitoText
                      value={`Name , School , Tuition and Grade are mandatory`}
                      fontSize={20}
                      fontWeight={600}
                    />
                    <NunitoText
                      value={`Please ensure that the entered value for ${
                        isAdminOrPartnerOrAssistant() &&
                        `School, Tuition, ${isAdmin() && "Partner"} and `
                      }Grade matches the options exactly
                        (even the casing)`}
                      fontSize={20}
                      fontWeight={600}
                    />
                  </>
                </div>
              }
            </Grid>
          ) : !uploadSchool & isPrivate() ? (
            <div style={{ width: 700 }}>
              <div style={templateDivStyle}>
                <NunitoText
                  value="PLEASE DOWNLOAD AND USE TEMPLATE HERE FOR PRIVATE CANDIDATE"
                  fontSize={24}
                  fontWeight={400}
                  color="#F16774"
                />
                <ArrowDownwardIcon style={{ color: "#F16774", fontSize: 45 }} />
                <ReusableButton
                  text="Download Template"
                  bgColor="#F16774"
                  fontSize={20}
                  height={57}
                  width={325}
                  iconType="download"
                  br={24}
                  onClick={() => downloadTemplate("private")}
                />
              </div>
              <>
                <NunitoText
                  value={`Name , School , Tuition and Grade are mandatory`}
                  fontSize={20}
                  fontWeight={600}
                />
                <NunitoText
                  value={`Please ensure that the entered value for ${
                    isAdminOrPartnerOrAssistant() &&
                    `School, Tuition, ${isAdmin() && "Partner"} and `
                  }Grade matches the options exactly
                        (even the casing)`}
                  fontSize={20}
                  fontWeight={600}
                />
              </>
            </div>
          ) : !uploadSchool & !isPrivate() ? (
            <div style={{ width: 700 }}>
              <div style={templateDivStyle}>
                <NunitoText
                  value="PLEASE DOWNLOAD AND USE TEMPLATE HERE FOR SCHOOL/ORGANIZATION CANDIDATE"
                  fontSize={24}
                  fontWeight={400}
                  color="#F16774"
                />
                <ArrowDownwardIcon style={{ color: "#F16774", fontSize: 45 }} />
                <ReusableButton
                  text="Download Template"
                  bgColor="#F16774"
                  fontSize={20}
                  height={57}
                  width={325}
                  iconType="download"
                  br={24}
                  onClick={() => downloadTemplate("notprivate")}
                />
              </div>

              <>
                <NunitoText
                  value={`Name , School and Grade are mandatory`}
                  fontSize={20}
                  fontWeight={600}
                />
                <NunitoText
                  value={`Please ensure that the entered value for ${
                    isAdminOrPartnerOrAssistant() &&
                    `School, Tuition, ${isAdmin() && "Partner"} and `
                  }Grade matches the options exactly
                        (even the casing)`}
                  fontSize={20}
                  fontWeight={600}
                />
              </>
            </div>
          ) : (
            uploadSchool && (
              <div style={{ width: 700 }}>
                <div style={templateDivStyle}>
                  <NunitoText
                    value="PLEASE DOWNLOAD AND USE TEMPLATE HERE"
                    fontSize={24}
                    fontWeight={400}
                    color="#F16774"
                  />
                  <ArrowDownwardIcon
                    style={{ color: "#F16774", fontSize: 45 }}
                  />
                  <ReusableButton
                    text="Download Template"
                    bgColor="#F16774"
                    fontSize={20}
                    height={57}
                    width={325}
                    iconType="download"
                    br={24}
                    onClick={() => downloadTemplate()}
                  />
                </div>
                {uploadSchool ? (
                  <NunitoText
                    value="Name, Phone Number and Email are mandatory"
                    fontSize={20}
                    fontWeight={600}
                  />
                ) : (
                  <>
                    <NunitoText
                      value={`Name${
                        isAdminOrPartnerOrAssistant() && ", School"
                      } and Grade are mandatory`}
                      fontSize={20}
                      fontWeight={600}
                    />
                    <NunitoText
                      value={`Please ensure that the entered value for ${
                        isAdminOrPartnerOrAssistant() &&
                        `School, Tuition, ${isAdmin() && "Partner"} and `
                      }Grade matches the options exactly
                        (even the casing)`}
                      fontSize={20}
                      fontWeight={600}
                    />
                  </>
                )}
              </div>
            )
          )}
        </Grid>
      </Grid>
      {/* <div>
                {(uploadSchool ? schoolFiltered : participantFiltered).map(({ label, state, key, header }, i) => (
                    <div key={i} style={{ border: '1px solid red', paddingLeft: 20, fontSize: 20, display: 'flex' }}>
                        <b style={{ width: uploadSchool ? 240 : 380 }}>label: {label}</b>
                        <p style={{ margin: 0, width: 120 }}>state: {JSON.stringify(state)}</p>
                        <p style={{ margin: 0, width: 220 }}>key: {key}</p>
                        <p style={{ margin: 0, width: 220 }}>header: {header}</p>
                    </div>
                ))}
            </div> */}
      {isAdmin() && csvHeaders && Boolean(csvArray.length) && (
        <Grid container style={{ paddingInline: 42, marginTop: 20 }}>
          <Grid
            container
            alignItems="center"
            style={{ border: "1px solid", paddingBlock: 24 }}
          >
            <Grid item xs={4} style={gridStyle}>
              <NunitoText
                value="Field"
                fontSize={25}
                fontWeight={800}
                italic
                color="#144A94"
              />
            </Grid>
            <Grid item xs={4} style={gridStyle}>
              <NunitoText
                value="First row of data"
                fontSize={25}
                fontWeight={800}
                italic
                color="#144A94"
              />
            </Grid>
            <Grid item xs={4} style={gridStyle}>
              <NunitoText
                value="CSV Header"
                fontSize={25}
                fontWeight={800}
                italic
                color="#144A94"
              />
            </Grid>
          </Grid>
          {Boolean(copyCSVArray.length) &&
            headers &&
            csvHeaders &&
            mapheaders.map((header, index) => (
              <MapField
                key={index}
                count={mapheaders.indexOf(header)}
                id={header.key}
                label={header.label}
                firstRow={copyCSVArray[0]}
                csvHeaders={csvHeaders}
                csvMapFields={csvMapFields}
                setCSVMapFields={setCSVMapFields}
                csvArray={csvArray}
                copyCSVArray={copyCSVArray}
                setCopyCSVArray={setCopyCSVArray}
                noClick={list.length}
              />
            ))}
          <Grid container justifyContent="flex-end" style={{ marginBlock: 20 }}>
            <ReusableButton
              text="Cancel"
              bgColor="#8D8D8D"
              fontSize={16}
              height={50}
              width={130}
              marginRight={20}
              onClick={() => cancelCSVFile()}
            />
            <ReusableButton
              text="Submit"
              bgColor={
                correctColumnsInCSV(Object.values(csvMapFields)) && !list.length
                  ? "#5E75C3"
                  : "#8D8D8D"
              }
              fontSize={16}
              height={50}
              width={130}
              onClick={() => onClickSubmitCSVMapFields()}
            />
            {/* <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} height={50} width={130}
                            onClick={() => submitCSVAsList(copyCSVArray)} /> */}
          </Grid>
        </Grid>
      )}
      {Boolean(list.length) && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: 60 }}
        >
          <NunitoText
            value={uploadSchool ? "Schools" : "Participants"}
            fontSize={30}
            fontWeight={800}
            italic
            color="#144A94"
            marginLeft={40}
          />
          <div
            style={{
              marginTop: 20,
              paddingRight: 55,
              display: "flex",
              alignItems: "center",
            }}
          >
            {checkForError(list) && (
              <>
                <ReusableAutocompleteWithID
                  type="default"
                  state={sort}
                  setState={onChangeSort}
                  placeholder="Sort by error"
                  width={240}
                  borderColor="#000"
                  marginRight={30}
                  options={sortOptions().map((o) => ({
                    id: o.id,
                    option: o.label,
                  }))}
                />
                <ReusableButton
                  text="Check/Uncheck Errors"
                  bgColor="#5E75C3"
                  fontSize={16}
                  height={50}
                  width={240}
                  iconType="check"
                  marginRight={20}
                  onClick={() => checkOrUncheck()}
                />
              </>
            )}
            <ReusableButton
              text="Mass Delete"
              bgColor="#E83D4D"
              fontSize={16}
              height={50}
              iconType="delete"
              marginRight={20}
              onClick={() => massDeleteList()}
            />
            {Boolean(selected.length) ? (
              <CSVLink
                filename={uploadSchool ? "schoollist" : "participantlist"}
                style={{ textDecoration: "none" }}
                data={list.filter((p) => selected.includes(p.id))}
              >
                <ReusableButton
                  text={`Export Selected ${
                    uploadSchool ? "School" : "Participant"
                  }(s)`}
                  fontSize={16}
                  bgColor="#5E75C3"
                  height={50}
                  iconType="export"
                />
              </CSVLink>
            ) : (
              <ReusableButton
                text={`Export Selected ${
                  uploadSchool ? "School" : "Participant"
                }(s)`}
                bgColor="#707070"
                fontSize={16}
                height={50}
                iconType="export"
                onClick={() =>
                  showWarningSwal(
                    `Please select at least one ${
                      uploadSchool ? "school" : "participant"
                    }(s) to export`
                  )
                }
              />
            )}
          </div>
        </Grid>
      )}
      {Boolean(list.length && !uploadSchool) && (
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ marginTop: 20, paddingInline: 42 }}
        >
          {isAdminOrPartnerOrAssistant() && (
            <Grid container alignItems="center">
              <div style={massAssignStyle}>
                <ReusableAutocomplete
                  type="table"
                  state={massAssignSchool}
                  setState={setMassAssignSchool}
                  placeholder="School"
                  width={240}
                  borderColor="#000"
                  marginRight={30}
                  freeSolo
                  options={actualMassSchoolOptions.map((s) => ({
                    id: s.id,
                    option: s.name,
                  }))}
                />
                <ReusableButton
                  text="Mass Assign School"
                  fontSize={15}
                  bgColor="#5E75C3"
                  height={50}
                  onClick={() => massUpdateList("school", massAssignSchool)}
                />
              </div>
              {participantFiltered.find((p) => p.key === "type").state !==
                0 && (
                <div style={massAssignStyle}>
                  <ReusableAutocomplete
                    type="table"
                    state={massAssignTuition}
                    setState={setMassAssignTuition}
                    placeholder="Tuition"
                    width={240}
                    borderColor="#000"
                    marginRight={30}
                    freeSolo
                    options={massTuitionCentreOptions.map((t) => ({
                      id: t.id,
                      option: t.name,
                    }))}
                  />
                  <ReusableButton
                    text="Mass Assign Tuition"
                    fontSize={15}
                    bgColor="#5E75C3"
                    height={50}
                    onClick={() =>
                      massUpdateList("tuition_centre", massAssignTuition)
                    }
                  />
                </div>
              )}
              <div style={massAssignStyle}>
                <ReusableAutocomplete
                  type="table"
                  state={massAssignGrade}
                  setState={setMassAssignGrade}
                  placeholder="Grade"
                  width={240}
                  borderColor="#000"
                  marginRight={30}
                  freeSolo
                  options={gradeOptions.map((g) => ({
                    id: g.id,
                    option: g.name,
                  }))}
                />
                <ReusableButton
                  text="Mass Assign Grade"
                  fontSize={15}
                  bgColor="#5E75C3"
                  height={50}
                  onClick={() => massUpdateList("grade", massAssignGrade)}
                />
              </div>
            </Grid>
          )}
          {isManagerOrTeacher() && (
            <Grid container alignItems="center" style={{ marginTop: 20 }}>
              <div style={massAssignStyle}>
                <ReusableAutocomplete
                  type="table"
                  state={massAssignGrade}
                  setState={setMassAssignGrade}
                  placeholder="Grade"
                  width={240}
                  borderColor="#000"
                  marginRight={30}
                  freeSolo
                  options={gradeOptions.map((g) => ({
                    id: g.id,
                    option: g.name,
                  }))}
                />
                <ReusableButton
                  text="Mass Assign Grade"
                  fontSize={15}
                  bgColor="#5E75C3"
                  height={50}
                  onClick={() => massUpdateList("grade", massAssignGrade)}
                />
              </div>
            </Grid>
          )}
        </Grid>
      )}
      {loading && <Loader height={800} />}
      <div style={{ display: loading && "none" }}>
        {Boolean(list.length) && (
          <Grid container style={{ paddingInline: 42 }}>
            <CSVUploadTable
              headers={owoHeaders}
              data={list}
              error={uploadError}
              errorslist={errors}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selecting={selecting}
              setSelecting={setSelecting}
              selected={selected}
              setSelected={setSelected}
              setState={setList}
              schoolOptions={actualMassSchoolOptions}
              tuitionCentreOptions={massTuitionCentreOptions}
              gradeOptions={gradeOptions}
              checkForErrorInRow={checkForErrorInRow}
              isDuplicate={isDuplicate}
              uploadSchool={uploadSchool}
              isPrivate={
                isAdminOrPartnerOrAssistant()
                  ? participantFiltered.find((p) => p.key === "type").state !==
                    0
                  : isPrivate()
              }
            />
            {Boolean(
              list.length &&
                list.map((l) => isDuplicate(list, l)).includes(true) &&
                !uploadSchool
            ) && (
              <ReusableCheckbox
                type="default"
                state={okToHvDupes}
                setState={setOkToHvDupes}
                label="I acknowledege there are duplicate particiant entries"
                fontSize={20}
              />
            )}
          </Grid>
        )}
        {Boolean(list.length) && (
          <Grid
            container
            justifyContent="flex-end"
            style={{ marginBlock: "50px 20px", paddingInline: 42 }}
          >
            <ReusableButton
              text="Cancel"
              bgColor="#8D8D8D"
              fontSize={16}
              height={50}
              width={130}
              marginRight={20}
              onClick={() => cancelList()}
            />
            <ReusableButton
              text="Submit"
              bgColor="#5E75C3"
              fontSize={16}
              height={50}
              width={130}
              onClick={() => onSubmit()}
            />
          </Grid>
        )}
      </div>
      {/* {error && sortErrorByRow(error).map((e, i) => (
                <Grid key={i} container>{JSON.stringify(e)}</Grid>
            ))}<br /> */}
      {/* {error && sortErrorByType(error).map((e, i) => (
                <Grid key={i} container>{JSON.stringify(e)}</Grid>
            ))}<br />
            {error && sortErrorByRow(error).map((e, i) => (
                <Grid key={i} container>{JSON.stringify(e)}</Grid>
            ))}<br /> */}
      {/* {JSON.stringify(selected)} */}
      {/* <p>keys: {JSON.stringify(headers.map(h => h.id))}</p>
            {list.length && JSON.stringify(checkForError(list))}
            {list.length && list.map((l, i) => (
                <div key={i} style={{ border: '1px solid gold', marginBottom: 10 }}>
                    <p>row: {JSON.stringify(l)}</p>
                    <p>error: {JSON.stringify(checkForErrorInRow(l))}</p>
                </div>
            ))} */}
    </Box>
  );
}
