import React, { useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import NunitoText from "../general/NunitoText";
import { useState } from "react";

import LightTable from "../table/LightTable";
import LoadingBackdrop from "../general/LoadingBackdrop";
import NoData from "../general/NoData";
import { IoClose } from "react-icons/io5";

export default function ShowCorrectAnswerParticipantsModal({
  answerText,
  correctAnswerParticipants,
  onClose,
}) {
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState();
  const [header, setHeader] = useState([
    { label: "Index Number", state: "", id: "index_no", header: "index_no" },
    { label: "Name", state: "name", id: "name", header: "name" },
    { label: "School", state: [], id: "school", header: "school" },
    { label: "Country", state: [], id: "country", header: "country" },
    { label: "Grade", state: [], id: "grade", header: "grade" },
  ]);

  const getParticipantsData = () => {
    const structuredParticipants = correctAnswerParticipants.map(
      (participant) => {
        return {
          country: participant.country.display_name,
          grade: participant.grade,
          index_no: participant.index_no,
          name: participant.name,
          school: participant.school.name,
          participantAnswerId: participant.participant_answer_id,
        };
      }
    );
    setData(structuredParticipants);
  };

  useEffect(() => {
    getParticipantsData();
  }, []);
  return (
    <>
      <LoadingBackdrop loading={data === undefined} />
      {data && (
        <Box
          className="popUpModal horizontalScrollable"
          style={{
            width: "80%",
            minHeight: "200px",
            maxHeight: "80%",
            justifyContent: "flex-start",
            padding: "10px 0",
          }}
        >
          <Button
            style={{
              position: "absolute",
              top: "5%",
              right: "4%",
            }}
            onClick={onClose}
          >
            <IoClose style={{ fontSize: 30, color: "gray" }} />
          </Button>
          <NunitoText
            value={"Unique Answer Participants"}
            fontSize={30}
            fontWeight={600}
            color="#5E75C3"
            marginTop={20}
          />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <NunitoText
              value={"Participants answer: "}
              fontSize={20}
              fontWeight={600}
              color="#5E75C3"
              marginRight={20}
            />
            <NunitoText value={answerText} fontSize={20} fontWeight={600} />
          </Box>

          {data.length > 0 ? (
            <Grid
              className="tableContainer"
              container
              position={"relative"}
              width={"100%"}
              alignSelf={"center"}
            >
              <LightTable
                insideModal={true}
                hasPagination={true}
                hasSelect={false}
                selected={selected}
                selecting={[]}
                setSelecting={() => {}}
                setSelected={setSelected}
                fixed={[]}
                data={data}
                headers={header}
              />
            </Grid>
          ) : (
            <Grid marginBottom={"3%"}>
              <NoData />
            </Grid>
          )}
        </Box>
      )}
    </>
  );
}
