import React, { useState } from 'react'
import { Grid, IconButton, Checkbox, Modal } from '@mui/material'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp'
import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp'
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp'
import { getBase64 } from '../../functions/upload'
import { useDispatch } from 'react-redux'
import { updateTask } from '../../actions'
import AddAnswersModal from './AddAnswersModal'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
const Preview = ({ width, count, answerCount, image }) => {
  const dispatch = useDispatch()
  const update = (count, head, target, text, event) => dispatch(updateTask(count, head, target, text, event))
  const previewImage = () => {
    var newTab = window.open();
    newTab.document.title = 'Image Preview'
    newTab.document.body.innerHTML = `<div style="text-align:center;">
            <h1>Image preview</h1>
            <img src=${image} alt="Preview of image" width=600 />
        </div>`
  }
  const containerStyle = { width }
  return (
    <Grid container alignItems="center" style={containerStyle}>
      <ReusableButton text='Preview Image' height={44} bgColor='#144A94' fontSize={15}
        onClick={() => previewImage()} iconType='preview' />
      <ReusableButton text='Clear' height={40} bgColor='#E83D4D' fontSize={15} marginLeft={10}
        onClick={() => update([count, answerCount], 'answerKeyText', 'image', '')} iconType='delete' />
    </Grid>
  )
}
const downIconBtnStyle = { marginLeft: 18 }
const upIconBtnStyle = { marginRight: 28 }
const iconStyle = { color: "#000" }
const Arrows = ({ count, answerCount, task }) => {
  const dispatch = useDispatch()
  const update = (count, head, target, text, event) => dispatch(updateTask(count, head, target, text, event))
  const { answers } = task
  if (answers.length > 1) {
    if (!answerCount) {
      //First answer with only downward arrow
      return (
        <IconButton edge="start" onClick={() => update([count, answerCount], 'moveDown', 'answers')} style={downIconBtnStyle}>
          <ArrowDownwardSharpIcon style={iconStyle} />
        </IconButton>
      )
    } else if (answerCount === answers.length - 1) {
      //Last answer with only upward arrow
      return (
        <IconButton edge="start" onClick={() => update([count, answerCount], 'moveUp', 'answers')} style={upIconBtnStyle}>
          <ArrowUpwardSharpIcon style={iconStyle} />
        </IconButton>
      )
    } else {
      //Any answers in between would have both upward and downward arrows
      return (
        <div>
          <IconButton edge="start" onClick={() => update([count, answerCount], 'moveUp', 'answers')}>
            <ArrowUpwardSharpIcon style={iconStyle} />
          </IconButton>
          <IconButton edge="start" onClick={() => update([count, answerCount], 'moveDown', 'answers')}>
            <ArrowDownwardSharpIcon style={iconStyle} />
          </IconButton>
        </div>
      )
    }
  }
  //Don't show any arrow if there is only 1 answer
  return null
}
const AddAndRemoveAnswerButtons = ({ count, answerCount }) => {
  const dispatch = useDispatch()
  const update = (count, head, target, text, event) => dispatch(updateTask(count, head, target, text, event))
  return (
    <>
      <IconButton color="inherit"
        onClick={() => update([count, answerCount + 1], 'answerKeyAddAnswers', 'answers')}>
        <AddCircleSharpIcon />
      </IconButton>
      {!!answerCount && <IconButton color="inherit" onClick={() => update([count, answerCount], 'answerKeyDeleteAnswers', 'answers')}>
        <RemoveCircleSharpIcon />
      </IconButton>}
    </>
  )
}
const arrowWrapperStyle = {
  display: 'flex', justifyContent: 'center', alignItems: 'center'
}
const titleStyle = { display: 'flex', justifyContent: 'center' }
const taskLabelContainerStyle = { display: 'flex', flexDirection: 'column', paddingInline: 30 }
const Title = value => <NunitoText value={value} fontSize={26} fontWeight={700} marginTop={20}
  borderBottom='1px solid' width={200} paddingBottom={5} />
export default function Answer({ count, answerCount, task, answer }) {
  const [addAnswers, setAddAnswers] = useState(false)
  const dispatch = useDispatch()
  const update = (count, head, target, text, event) => dispatch(updateTask(count, head, target, text, event))
  const handleFileInputChange = (counts, file) => {
    getBase64(file).then(result => update(counts, 'answerKeyText', 'image', result)).catch(err => {
      console.log(err)
      alert(`Error at handeFileInputChange: ${JSON.stringify(err)}`)
    })
  }
  const openFile = () => document.getElementById(`file-upload-${count}${answerCount}`).click()
  const { uploadImage, type, structure } = task
  const { image, taskLabel, correct,
    // answers
  } = answer
  const AnswerLabelOrImageTextField = () => <ReusableTextField type='default'
    placeholder={uploadImage ? "Click to upload image" : `Enter ${structure === 4 ? 'Task' : 'Answer'} Label`}
    state={uploadImage ? image : taskLabel} setState={update}
    count={[count, answerCount]} head='answerKeyText' target='taskLabel' onClick={uploadImage && openFile}
    readOnly={uploadImage} onBlur={!uploadImage} />
  const HiddenInput = () => <input id={`file-upload-${count}${answerCount}`} type="file" style={{ display: 'none' }}
    onChange={e => handleFileInputChange([count, answerCount], e.target.files[0])} />
  const answerContainerStyle = { marginTop: 10 }
  const centerStyle = { textAlign: 'center' }
  if (type === 1) {
    if (structure === 3) {
      return (
        <>
          {answerCount === 0 && <Grid container alignItems="center">
            <Grid item xs={2} style={titleStyle}>{Title('Answer ID')}</Grid>
            <Grid item xs={1.5} style={titleStyle}>{Title('Sequence')}</Grid>
            <Grid item xs={7.5} style={titleStyle}>{Title('Answer Label')}</Grid>
          </Grid>}
          <Grid container style={answerContainerStyle} alignItems="center">
            <Grid item xs={2} style={centerStyle}>
              <NunitoText value='-' fontSize={24} />
            </Grid>
            <Grid item xs={1.5} style={arrowWrapperStyle}>
              <NunitoText value={(answerCount + 1).toString()} fontSize={25} marginRight={20} />
              <Arrows count={count} answerCount={answerCount} task={task} />
            </Grid>
            <Grid item xs={4.5 + ((image === '' || !uploadImage) && 3)} style={taskLabelContainerStyle}>
              <AnswerLabelOrImageTextField />
              <HiddenInput />
            </Grid>
            {image !== '' && uploadImage && <Grid item xs={3}>
              <Preview count={count} answerCount={answerCount} image={image} />
            </Grid>}
            <Grid item xs={1}>
              <AddAndRemoveAnswerButtons count={count} answerCount={answerCount} />
            </Grid>
          </Grid>
        </>
      )
    } else {
      //Default and Group are similar in MCQ
      return (
        <>
          {answerCount === 0 && <Grid container alignItems="center">
            <Grid item xs={2} style={titleStyle}>{Title('Answer ID')}</Grid>
            <Grid item xs={1.5} style={titleStyle}>{Title('Position')}</Grid>
            <Grid item xs={5.5} style={titleStyle}>{Title('Answer Label')}</Grid>
            <Grid item xs={1} />
            <Grid item xs={2} style={titleStyle}>{Title('Correct Answer')}</Grid>
          </Grid>}
          <Grid container style={answerContainerStyle} alignItems="center">
            <Grid item xs={2} style={centerStyle}>
              <NunitoText value='-' fontSize={24} />
            </Grid>
            <Grid item xs={1.5} style={arrowWrapperStyle}>
              <Arrows count={count} answerCount={answerCount} task={task} />
            </Grid>
            <Grid item xs={2.5 + ((image === '' || !uploadImage) && 3)} style={taskLabelContainerStyle}>
              <AnswerLabelOrImageTextField />
              <HiddenInput />
            </Grid>
            {image !== '' && uploadImage && <Grid item xs={3}>
              <Preview count={count} answerCount={answerCount} image={image} />
            </Grid>}
            <Grid item xs={1}>
              <AddAndRemoveAnswerButtons count={count} answerCount={answerCount} />
            </Grid>
            <Grid item xs={2} style={centerStyle}>
              <Checkbox style={{ color: '#000' }} checked={correct}
                onChange={() => update([count, answerCount], 'answerKeyBoolean', 'correct')} />
            </Grid>
          </Grid>
        </>
      )
    }
  } else {
    if (structure === 4) {
      return (
        <>
          {answerCount === 0 && <Grid container alignItems="center">
            <Grid item xs={2} style={titleStyle}>{Title('Answer ID')}</Grid>
            <Grid item xs={1.5} style={titleStyle}>{Title('Position')}</Grid>
            <Grid item xs={7.5} style={titleStyle}>{Title('Task Label')}</Grid>
          </Grid>}
          <Grid container style={answerContainerStyle} alignItems="center">
            <Grid item xs={2} style={centerStyle}>
              <NunitoText value='-' fontSize={24} />
            </Grid>
            <Grid item xs={1.5} style={arrowWrapperStyle}>
              <Arrows count={count} answerCount={answerCount} task={task} />
            </Grid>
            <Grid item xs={4.5 + ((image === '' || !uploadImage) && 3)} style={taskLabelContainerStyle}>
              <AnswerLabelOrImageTextField />
              <HiddenInput />
            </Grid>
            {image !== '' && uploadImage && <Grid item xs={3}>
              <Preview count={count} answerCount={answerCount} image={image} />
            </Grid>}
            <Grid item xs={1}>
              <AddAndRemoveAnswerButtons count={count} answerCount={answerCount} />
            </Grid>
          </Grid>
        </>
      )
    } else {
      //Default and Group are similar as well
      return (
        <>
          {answerCount === 0 && <Grid container alignItems="center">
            <Grid item xs={2} style={titleStyle}>{Title('Answer ID')}</Grid>
            <Grid item xs={1.5} style={titleStyle}>{Title('Position')}</Grid>
            <Grid item xs={5.5} style={titleStyle}>{Title('Answer Label')}</Grid>
            <Grid item xs={2} style={titleStyle}>{Title('Answer')}</Grid>
          </Grid>}
          <Grid container style={answerContainerStyle} alignItems="center" justifyContent='space-between'>
            <Grid item xs={2} style={centerStyle}>
              <NunitoText value='-' fontSize={24} />
            </Grid>
            <Grid item xs={1.5} style={arrowWrapperStyle}>
              <Arrows count={count} answerCount={answerCount} task={task} />
            </Grid>
            <Grid item xs={2.5 + ((image === '' || !uploadImage) && 3)} style={taskLabelContainerStyle}>
              <AnswerLabelOrImageTextField />
              <HiddenInput />
            </Grid>
            {image !== '' && uploadImage && <Grid item xs={3}>
              <Preview count={count} answerCount={answerCount} image={image} />
            </Grid>}
            <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column' }}>
              {/* <div style={{
                              fontFamily: 'Nunito', fontSize: 20, color: '#8E7DD0', cursor: 'pointer',
                              textDecoration: 'underline', textAlign: 'center'
                          }} onClick={() => setAddAnswers(true)}>
                              {answers.length ? 'Edit' : 'Add'} Correct Answers
                          </div> */}
              <ReusableTextField type='default' placeholder="Enter answer" state={answer.answer} setState={update}
                count={[count, answerCount]} head='answerKeyText' target='answer' onBlur />
            </Grid>
            <Grid item xs={1}>
              <AddAndRemoveAnswerButtons count={count} answerCount={answerCount} />
            </Grid>
          </Grid>
          <Modal open={addAnswers} onClose={() => setAddAnswers(false)}>
            <>
              <AddAnswersModal count={count} answerCount={answerCount} task={task} answer={answer}
                setAddAnswers={setAddAnswers} />
            </>
          </Modal>
        </>
      )
    }
  }
}