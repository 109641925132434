import React from 'react'
import { Select, MenuItem } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FaInfoCircle } from 'react-icons/fa'
import { isAdmin, isPartner } from '../../functions/checkrole'
import LightTooltip from '../general/LightTooltip'
const infoStyle = { color: '#000', fontSize: 14 }
const iconStyle = { color: '#67A8F3', fontSize: 32, marginLeft: 20 }
const Info = () => <div>
    <p style={infoStyle}><span style={{ color: '#6FC9B6' }}>Active</span> - Teachers and Country Partners can upload</p>
    <p style={infoStyle}><span style={{ color: '#8E7DD0' }}>Ready</span> - Only Country Partners can upload</p>
    <p style={infoStyle}><span style={{ color: '#F3A867' }}>Locked</span> - Only Admins can upload</p>
</div>
export default function StatusAndInfo({ state, target, setState, originalStatus }) {
    let color = ''
    switch (state) {
        case 'active': color = '#6FC9B6'; break;
        case 'ready': color = '#8E7DD0'; break;
        case 'lock': color = '#F3A867'; break;
        case 'added': color = 'grey'; break;
        default: color = '#000';
    }
    const selectStyle = {
        height: 50, width: 210, backgroundColor: '#FFF',
        border: `1px solid ${color}`, color, outline: 'none'
    }
    const selectAttributes = {
        value: state,
        onChange: e => {
            if (target) setState(target, e.target.value)
            else setState(e.target.value)
        },
        style: selectStyle,
        IconComponent: ExpandMoreIcon,
        SelectDisplayProps: {
            style: { outline: 'none', cursor: isPartner() && state === 'lock' && 'not-allowed' }
        },
        readOnly: isPartner() && state === 'lock'
    }
    return (
        <>
            {isAdmin() && <LightTooltip title={<Info />} rollover={1} topic={0} hide={0}>
                <div><FaInfoCircle style={iconStyle} /></div>
            </LightTooltip>}
            <Select {...selectAttributes}>
                <MenuItem value='added' style={{ display: originalStatus !== 'added' && 'none' }}>Added</MenuItem>
                <MenuItem value='active'>Active</MenuItem>
                <MenuItem value='ready'>Ready</MenuItem>
                <MenuItem value='lock' style={{ display: !isAdmin() && 'none' }}>Lock</MenuItem>
            </Select>
            {isPartner() && <LightTooltip title={<Info />} rollover={1} topic={0} hide={0}>
                <div><FaInfoCircle style={iconStyle} /></div>
            </LightTooltip>}
        </>
    )
}