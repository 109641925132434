import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import DeleteModal from '../components/modal/DeleteModal'
import ApproveModal from '../components/modal/ApproveModal'
import { useSelector } from 'react-redux'
import {
    makeSureIsArray, makeOptions, checkIfAllSelectedRowsMatchStatus,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showNotification } from '../functions/snackbar'

import { showWarningSwal } from '../functions/alert'
import { getCollections } from '../functions/getData'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import ManagePermission from '../components/Collections/ManagePermissionsModal'
import RejectModal from '../components/modal/RejectModal'
import { approveCollection, rejectCollection } from '../functions/postData'
import { useSnackbar } from 'notistack'
import ReasonModal from '../components/modal/ReasonModal'
import { deleteCollection } from '../functions/deleteData'

const collectionHeader = [
    { id: 'identifier', label: 'Collection Identifier' },
    { id: 'name', label: 'Collection Name' },
    { id: 'status', label: 'Status' },
    { id: 'description', label: 'Description' },
    { id: 'tags', label: 'Tags' },
    { id: 'grade_difficulty', label: 'Recommended Difficulty' },
    { id: 'sections', label: 'No. of section' },
    // { id: 'noOfQuestions', label: 'No. of questions' },
    { id: 'initial_points', label: 'Initial Points' },
    { id: 'time_to_solve', label: 'Time to solve' },
    { id: 'competitions', label: 'Competition' },
    // { id: 'permission', label: 'Permission to Verify' },
    { id: 'created_by', label: 'Created By' },
    { id: 'last_modified_by', label: 'Last Modified By' },
    // { id: 'lastVerifiedBy', label: 'Last Verified By' },
]
const initialParams = '?currentPage=moderation'
export default function CollectionsModeration() {
    document.title = 'Moderate Collections'
    const isMounted = useRef(false)
    const user = useSelector(state => state.user)
    const [header, setHeader] = useState(collectionHeader)
    const [collectionTable, setCollectionTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: "Tags", state: [], key: 'tag_id', header: 'tags' },
        { label: 'Competition', state: '', key: 'competition_id', header: 'competition' },
        { label: 'Status', state: '', key: 'status', header: 'status' }
    ])
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        { label: "Tags", state: true, key: 'private' },
        { label: "Recommended Difficulty", state: true, key: 'address' },
        { label: "No. of Sections", state: true, key: 'postal' },
        { label: "No. of Questions", state: true, key: 'province' },
        { label: "Initial Points", state: true, key: 'phone' },
        { label: "Time to Solve", state: true, key: 'status' },
        { label: "Competition", state: true, key: 'created_by_username' },
        { label: 'Permission to Verify', state: true, key: 'approved_by_username' },
        { label: 'Created By', state: true, key: 'rejected_by_username' },
        { label: 'Last Modified By', state: true, key: 'modified_by_username' },
        { label: 'Last Verified By', state: true, key: 'reject_reason' },
        { label: "Status", state: true, key: 'status' }
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [id, setId] = useState('')
    const [onReasons , setOnReasons] = useState(false)
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onReject , setOnReject] = useState(false)
    const [onApprove, setOnApprove] = useState(false)
    const [isSingle , setIssingle] = useState(false)
    const [onManagePermission , setOnManagePermission] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setId)
    const cancelApprove = () => cancelAction(setOnApprove, setId)
    const cancelReject = () => cancelAction(setOnReject, setId)
    const cancelReason = ()=> cancelAction(setOnReasons , setId)
    const cancelPermission = ()=>cancelAction(setOnManagePermission , setId)
  
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

   
    const getData = (params, signal) => {
        setSelected([])
        setSelecting([])
        loadData(setLoadingTable, setFirstLoaded, getCollections, params, setCollectionTable, setFilterOptions, false, signal)
    }
    const onChangeViewOptions = value => changeViewOptions(collectionHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(value, rowsPerPage, filtered, search, signal, setRowsPerPage, setParams, getData)
    }
    const onChangeFiltered = value => {
        changeFiltered(value, filtered, search, rowsPerPage, signal, setFiltered, setParams, getData,true)
    }
    const onChangeSearch = value => {
        changeSearch(value, search, filtered, rowsPerPage, signal, setSearch, setParams, getData , true)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`, signal)
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    useEffect(() => {
        isMounted.current = true
        getData(initialParams, signal)
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [controller, signal])
    const onClickAddToCompetition = () => {
        // return selected.length ? {} :
        //     showWarningSwal('Please select at least one collection to add to competition')
    }
    const onClickMassApprove = () => {
        return collectionTable && checkIfAllSelectedRowsMatchStatus(selected, collectionTable.data, ['pending'], user) ?
            setOnApprove(true) : showWarningSwal('Please only select pending collections that are not added by you to mass approve')
    }
    const onClickMassDelete = () => {
       if(selected.length>1){
        setIssingle(false)
        setOnDelete(true)
       }else{
        showWarningSwal('you must select atleast 2 collecitons')
       }
    }
    const approveFunction = () => {
        setLoading(true)
      let payload = []
        if(isSingle){
            payload = {ids:[id]}
        }else{
            payload = {ids:selected}
        }
        approveCollection(payload).then(res=>{
            if(res.status===200){
                showNotification('success', res.message, enqueueSnackbar, closeSnackbar)
                getData(initialParams , signal)
            }
        })
        setOnApprove(false)
        setLoading(false)
         
    }
    const rejectFunction =(id , reason)=>{
        if(reason===''){
            showWarningSwal('reason field cannot be empty')
        }else{
        setLoading(true)
        rejectCollection(id , {reason:reason}).then(res=>{
            console.log(id , reason )
            if(res.status===200){
                setLoading(false)
                showNotification('success', res.message, enqueueSnackbar, closeSnackbar)
                getData(initialParams , signal)
            }else{
                setLoading(false)
            }

        })
        setOnReject(false)}
    }
   
     
    const deleteFunction = () => {
        console.log({ id: id === '' ? selected : [id] })
        setLoading(true)
        deleteCollection({ id: id === '' ? selected : [id] }).then(d => {
        getData(params)
        cancelDelete()
        setLoading(false)
        showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
        })
    }
    
    console.log('data' , collectionTable)
    return (    
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            <Collapse in={filtering}>
                {filterOptions && <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />}
            </Collapse>
            <Grid className='firstRowContainer' container justifyContent="space-between">
                <div className='dashboardAndSelfBtnDiv'>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Moderate Collections' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div className='viewAndFilterBtnDiv'>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={handleFilter} />
                </div>
            </Grid>
            <Grid className='tableContainer' container>
                <NunitoText value='Moderate Collections' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid className='searchAndBtnContainer' container alignItems="center" justifyContent="space-between">
                    <div>
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={search} setState={onChangeSearch} onBlur />
                         <NunitoText value='Search only in "Name", "Index", "School" and "Tuition Centre" column'
                        fontSize={20} fontWeight={400} italic color='#144A94' />
                        </div>
                    <div className='endBtnDiv'>
                        <ReusableButton text='Mass Approve' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            onClick={() => {
                                if(selected.length>1){
                                    setIssingle(false)
                                    setOnApprove(true)

                                }else{
                                    showWarningSwal('select atleast 2 collections')
                                }
                            }} iconType='check' />
                        <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46} marginRight={20}
                            onClick={() => onClickMassDelete()} iconType='delete' />
                    </div>
                   
                </Grid>
                {loadingTable && <Loader height={600} />}
                {!loadingTable && collectionTable && makeSureIsArray(collectionTable.data).length &&
                    <HeavyTable headers={header} list={collectionTable} title='collections' rowsPerPage={rowsPerPage} setOnManagePermission={setOnManagePermission} setOnApprove={setOnApprove} setOnReasons={setOnReasons}
                        setOnDelete={setOnDelete} onChangePage={onChangePage} fixed={['name', 'identifier']}
                        selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected}
                        setId={setId} setIssingle={setIssingle} setOnReject={setOnReject} />}
                {!loadingTable && firstLoaded && collectionTable && !makeSureIsArray(collectionTable.data).length && <NoData height={600} />}
            </Grid>
            <Modal open={onDelete} onClose={() => cancelDelete()}>
                <>
                    {Boolean(collectionTable) && <DeleteModal table={collectionTable} id={id} selected={selected} isSingle={isSingle}
                        deleteFunction={deleteFunction} cancelDelete={cancelDelete} />}
                </>
            </Modal>
            <Modal open={onApprove} onClose={() => cancelApprove()}>
                <>
                    {Boolean(collectionTable) && <ApproveModal table={collectionTable} id={id} selected={selected} singleApprove={isSingle}
                        approveFunction={approveFunction} cancelApprove={cancelApprove} object='collection' />}
                </>
            </Modal>
            <Modal open={onReject} onClose={() => cancelReject()}>
                <>
                    {Boolean(collectionTable) && <RejectModal table={collectionTable} id={id} selected={selected} 
                        rejectFunction={rejectFunction} cancelReject={cancelReject} object='collection' />}
                </>
            </Modal>
            <Modal open={onReasons} onClose={() => cancelReason()}>
                <>
                    {Boolean(collectionTable) && <ReasonModal table={collectionTable} id={id} selected={selected} 
                         object='collection' />}
                </>
            </Modal>
        </Box >
    )
}