import React, { useState, useEffect, useRef } from 'react'
import { Grid, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { FaInfoCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { formatDateTime } from '../../functions/general'
import { showNotification } from '../../functions/snackbar'
import { showWarningSwal } from '../../functions/alert'
import { isUnchanged, deepCopy, getDaysArray, validateCountryPartner } from '../../functions/edit'
import { patchCompetitionOrganization } from '../../functions/patchData'
import StatusAndInfo from './StatusAndInfo'
import TitleBar from '../general/TitleBar'
import InputContainer from '../general/InputContainer'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableDatePicker from '../general/ReusableDatePicker'
import ReusableToggle from '../general/ReusableToggle'
import ReusableButton from '../general/ReusableButton'
import NunitoText from '../general/NunitoText'
import LightTooltip from '../general/LightTooltip'
import LoadingBackdrop from '../general/LoadingBackdrop'
import { useSnackbar } from 'notistack'
const competitionContainerStyle = { backgroundColor: '#F3F0F4', borderRadius: '0 0 12px 12px' }
const dateContainerStyle = { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }
const selectWrapperStyle = { display: 'flex', alignItems: 'center' }
const helpStyle = { color: '#000', fontSize: 18, paddingInline: 10 }
const radioStyle = { color: '#000' }
const modes = ['Paper Based', 'Online', 'Both']
const Help = () => <div style={helpStyle}>
    <p>Step 1: Please edit the country date, country competition dates before you upload the participants.</p>
    <p>Step 2: Please upload the schools if they are new to participate in SIMCC competition.</p>
    <p>Step 3: SIMCC needs to approve the schools before you can upload the participants.</p>
</div>
export default function CompetitionForPartner({ original, setOriginal, competition, setCompetition }) {
    const isMounted = useRef(false)
    const [expand, setExpand] = useState(true)
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    let count = 0
    let foundOrganization = competition.competition_organization[count]
    const { name, global_registration_date, global_registration_end_date,
        competition_start_date, competition_end_date, format } = competition
    const { id, organization_id, registration_open_date, originalDates, dates, status, originalStatus, type,
        translate, translating , comp_end_date } = foundOrganization
    const updatePartner = (identifier, value) => {
        let newCompetition = { ...competition }
        newCompetition.competition_organization[count][identifier] = value
        if (identifier === 'translating') {
            if (!value) newCompetition.competition_organization[count].translate = []
        }
        setCompetition(newCompetition)
    }
    const languageOptions = useSelector(state => state.languageOptions)
    let hasPassed = new Date().setHours(0, 0, 0, 0) >= originalDates[0]
    const onSubmit = () => {
        if (isUnchanged(original, competition)) return showWarningSwal('Please make some changes before submitting')
        let msg = validateCountryPartner(format, global_registration_date, global_registration_end_date,
            competition_start_date, competition_end_date, registration_open_date, dates, originalDates , comp_end_date)
        if (msg) return showWarningSwal(msg)
        actuallySubmit()
    }
    const actuallySubmit = () => {
        let payload = {
            id: id,
            organization_id: organization_id,
            registration_open_date: formatDateTime(registration_open_date, '/'),
            competition_dates: getDaysArray(dates.sort((a, b) => a - b)).filter(d => d >= new Date().setHours(0, 0, 0, 0))
                .map(d => formatDateTime(d, '/')),
            status: status === 'added' ? 'active' : status,
            competition_mode: Number(type),
            translate: JSON.stringify(translate)
        }
        setLoading(true)
        console.log(JSON.stringify(payload))
        patchCompetitionOrganization(payload).then(d => {
            console.log(d)
            if (d.status === 200) {
                showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
                if (isMounted.current) {
                    let newCompetition = window.structuredClone(competition)
                    setCompetition(newCompetition)
                    setOriginal(deepCopy(newCompetition))
                }
            }else{
                showWarningSwal(d.message)
            }
        }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
    }
    useEffect(() => {
        isMounted.current = true
        return () => isMounted.current = false
    }, [])
    console.log(comp_end_date)
    return (
        <Grid container style={{ marginBottom: 20 }}>
            <LoadingBackdrop loading={loading} />
            <TitleBar title={name} state={expand} setState={setExpand} main
                info={<LightTooltip title={<Help />} rollover={1} topic={1} hide={0}>
                    <div><FaInfoCircle style={{ color: '#FFF', fontSize: 32, marginLeft: 20 }} /></div>
                </LightTooltip>} />
            {expand && <Grid container style={competitionContainerStyle}>
                <InputContainer label='Competition Name' first
                    field={<ReusableTextField type='default' placeholder='Competition Name' state={name}
                        borderColor='#000' required readOnly disabled fullWidth />} />
                <InputContainer label={`Global Registration Start ${format ? 'and End ' : ''}Date`}
                    field={<div style={dateContainerStyle}>
                        <ReusableDatePicker width={250} state={global_registration_date}
                            required disabled />
                        {Boolean(format) && <>
                            <NunitoText value='TO' fontSize={20} fontWeight={600} italic />
                            <ReusableDatePicker width={250} state={global_registration_end_date}
                                required disabled />
                        </>}
                    </div>} />
                <InputContainer label='Competition Start and End Date'
                    field={<div style={dateContainerStyle}>
                        <ReusableDatePicker width={250} state={competition_start_date} required disabled />
                        <NunitoText value='TO' fontSize={20} fontWeight={600} italic />
                        <ReusableDatePicker width={250} state={comp_end_date?new Date(comp_end_date):competition_end_date} required disabled />
                    </div>} />
                <InputContainer label='Country Registration Start Date*'
                    field={<ReusableDatePicker width={250} state={registration_open_date}
                        target='registration_open_date' setState={updatePartner} required />} />
                <InputContainer label='Country Competition Dates*'
                    field={<ReusableDatePicker width={250} state={dates} maxDate={comp_end_date?new Date(comp_end_date):new Date(competition_end_date)}
                        target='dates' setState={updatePartner} multiple required /> }  />
                <InputContainer label='Status*'
                    field={<div style={selectWrapperStyle}>
                        <StatusAndInfo state={status} setState={updatePartner} target='status' originalStatus={originalStatus} />
                    </div>} />
                <InputContainer label='Mode of Competition'
                    field={hasPassed ? modes[type] : <RadioGroup value={type} onChange={e => updatePartner('type', e.target.value)} row>
                        <FormControlLabel value='0' control={<Radio style={radioStyle} />} label="Paper Based" />
                        <FormControlLabel value='1' control={<Radio style={radioStyle} />} label="Online" />
                        <FormControlLabel value='2' control={<Radio style={radioStyle} />} label="Both" />
                    </RadioGroup>} />
                <InputContainer last label='Translation'
                    field={<div style={{ display: 'flex' }}>
                        <ReusableToggle type='text' width={160} falseValue='No' trueValue='Yes'
                            state={translating} target='translating' setState={updatePartner} />
                        <ReusableAutocompleteWithID type='default' placeholder='Languages' width={460} height={55} marginLeft={30}
                            bgColor='#FFF' btnBgColor='#144A94' btnWidth={64} state={translate} setState={updatePartner} multiple
                            target='translate' borderColor='#707070' options={languageOptions.map(l => ({ id: l.id, option: l.name }))}
                            grayedOut={!translating} readOnly={!translating} />
                    </div>} />
                <Grid container justifyContent='flex-end' style={{ marginBottom: 20, marginRight: 30 }}>
                    <ReusableButton text='Save' bgColor='#5E75C3' fontSize={16} onClick={() => onSubmit()} iconType='save' />
                </Grid>
            </Grid>}
        </Grid >
    )
}