import Swal from 'sweetalert2'
export const showInfoSwal = (title, text) => {
   Swal.fire({
      title, text, icon: 'info',
      customClass: { container: 'swalContainer' }
   })
}
export const showWarningSwal = text => {
   let obj = {
      title: 'Warning!', icon: 'warning',
      customClass: { container: 'swalContainer' }
   }
   if (['<div>', '<br>'].some(el => text.includes(el))) obj.html = text
   else obj.text = text
   Swal.fire(obj)
}
export const functionError = text => {
   Swal.fire({
      title: 'An error has occurred', text, icon: 'error',
      customClass: { container: 'swalContainer' }
   })
}
export const isEmpty = arr => {
   for (var i = 0; i < arr.length; i++) if (arr[i] === '' && arr[i] !== false) return true
   return false
}
export const warningMessage = array => {
   let warning = 'Please fill in'
   let arr = array.filter(a => !a.state)
   arr.forEach(({ name }, i) => warning += ` ${name}${i < arr.length - 1 ? i === arr.length - 2 ? ' and' : ',' : '.'}`)
   return Swal.fire({
      title: 'Warning!', text: warning, icon: 'warning',
      customClass: { container: 'swalContainer' }
   })
}