import React, { useState, useEffect, useRef } from 'react'
import { Box, Grid } from '@mui/material'
import { invalidNumber, invalidEmail, makeSureIsArray } from '../../functions/general'
import { isEmpty, warningMessage, showWarningSwal } from '../../functions/alert'
import { patchSchool } from '../../functions/patchData'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
import { isAdminOrPartnerOrAssistant } from '../../functions/checkrole'
import ReusableCheckbox from '../general/ReusableCheckbox'
const boxStyle = { width: 1200, minHeight: 400, maxHeight: '90%' }
const rejectionWrapperStyle = {
    backgroundColor: '#F3F0F4', border: '1px solid', borderRadius: 8,
    marginBottom: 20, marginInline: 30, paddingInline: 20, paddingBlock: '10px 20px',
    height: 180, overflow: 'auto'
}
const rejectedByStyle = {
    display: 'flex', alignItems: 'center', justifyContent: 'space-between',
    marginTop: 20, borderBottom: '1px solid #707070'
}
const centerFlexStyle = { display: 'flex', alignItems: 'center' }
const firstRowStyle = { marginTop: 20, marginBottom: 30 }
const rowStyle = { marginBottom: 30 }
const buttonsContainerStyle = { marginBottom: 30, paddingInline: 100 }
export default function EditSchoolModal({ setLoading, table, id, afterEditingSchool, cancelEdit }) {
    const isMounted = useRef(false)
    const school = makeSureIsArray(table.data).find(s => s.id === id)
    const [name, setName] = useState(school.name || '')
    const [address, setAddress] = useState(school.address || '')
    const [postalCode, setPostalCode] = useState(school.postal || '')
    const [province, setProvince] = useState(school.province || '')
    const [isTuitionCentre, setIsTuitionCentre] = useState(Number(school.private))

    const [phoneNumber, setPhoneNumber] = useState(school.phone || '')
    const [email, setEmail] = useState(school.email || '')
    const [name_in_certificate , setCert] = useState(school.name_in_certificate||'')

    const original = {
        name: school.name || '', address: school.address || '', postalCode: school.postal || '', province: school.province || '',
        phoneNumber: school.phone || '', email: school.email || '' , private: Number(school.private) , name_in_certificate : school.name_in_certificate||''

    }
    const current = {
        name, address, postalCode, province, phoneNumber, email , private:Number(isTuitionCentre) , name_in_certificate
    }
    const unchanged = JSON.stringify(original) === JSON.stringify(current)
    const onSubmit = () => {
        if (unchanged) return showWarningSwal('Please make some changes before submitting')
        let arr = [
            { name: 'Phone Number', state: phoneNumber },
            { name: 'Email', state: email }
        ]
        if (school.status !== 'active') {
            arr.unshift({ name: 'School Name', state: name })
            arr.shift({ name: 'Province', state: province })
        }
        if (isEmpty(arr.map(a => a.state))) return warningMessage(arr)
        setLoading(true)
        let msg = ''
        // const invalidSchlName = value => !/^[\’\'\;\.\,\s\(\)\[\]\w-]*$/.test(value)
        // if (invalidSchlName(name)) msg += 'Invalid school name. '
        if (invalidNumber(phoneNumber)) msg += 'Invalid phone number. '
        if (invalidEmail(email)) msg += 'Invalid email'
        if (msg) return showWarningSwal(msg)
        // let payload = { id, name: name.toUpperCase() }
        let payload = {
            id, email, phone: phoneNumber, country_id: school.country_id , private: Number(isTuitionCentre) , name_in_certificate:name_in_certificate
        }
        if (name.toLowerCase() !== school.name.toLowerCase()) payload.name = name.toUpperCase()
        if (province!==''&&(province.toLowerCase() !== school.province.toLowerCase())) payload.province = province.toUpperCase()
        if (address !== '') payload.address = address
        if (postalCode !== '') payload.postal = postalCode
        console.log(original , current , payload)
        patchSchool(payload).then(d => {
            console.log(payload , province)
            isMounted.current && setLoading(false)
            afterEditingSchool(cancelEdit, d)
        }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
    }
    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])
    return (
        <Box className='popUpModal horizontalScrollable' style={boxStyle}>
            <div>
                <NunitoText value='EDIT SCHOOL' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
                {isAdminOrPartnerOrAssistant()&&<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -42 }}>
                    <ReusableCheckbox type='default' label='Tuition Centre' marginRight={100}
                        state={isTuitionCentre} setState={setIsTuitionCentre} fontSize={20} />
                </div>}
                {Boolean(school.reject_reason.length && ['rejected', 'pending'].includes(school.status)) && <div style={rejectionWrapperStyle}>
                    <NunitoText value='Reason(s) for rejection' fontSize={24} />
                    {school.reject_reason.map(({ reject_id, user, role, reason, created_by }) => (
                        <div key={reject_id}>
                            <div style={rejectedByStyle}>
                                <div style={centerFlexStyle}>
                                    <NunitoText value={`Rejected by: ${user.username}(${role.name})`}
                                        fontSize={24} fontWeight={300} color='#5E75C3' italic />
                                    <NunitoText value={`On: ${created_by.slice(-20)}`}
                                        fontSize={15} fontWeight={300} color='#F16774' italic marginLeft={10} />
                                </div>
                            </div>
                            <NunitoText value={reason} fontSize={20} marginTop={16} align='left' />
                        </div>
                    ))}
                </div>}
                <Grid container justifyContent="space-evenly" style={firstRowStyle}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='School Name*'
                        state={name} setState={setName} required
                        readOnly={!isAdminOrPartnerOrAssistant()} grayedOut={!isAdminOrPartnerOrAssistant()} />
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Address'
                        state={address} setState={setAddress} />
                </Grid>
                <Grid container justifyContent="space-evenly" style={rowStyle}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Postal Code'
                        state={postalCode} setState={setPostalCode} />
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Province'
                        state={province} setState={setProvince}
                        readOnly={!isAdminOrPartnerOrAssistant()} grayedOut={!isAdminOrPartnerOrAssistant()} />
                </Grid>
                <Grid container justifyContent="space-evenly" style={rowStyle}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Phone Number*'
                        state={phoneNumber} setState={setPhoneNumber} required />
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Email*'
                        state={email} setState={setEmail} required />
                </Grid>
                <Grid container justifyContent="space-evenly" style={rowStyle}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Name in Certificate'
                        state={name_in_certificate} setState={setCert}/>
                </Grid>
            </div>
            <Grid container justifyContent='space-evenly' style={buttonsContainerStyle}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => cancelEdit()} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
            {/* {JSON.stringify({
                id, name, address: address, postal: postalCode, phone: phoneNumber,
                province: province
            })} */}
        </Box>
    )
}