import React, { useState, useEffect } from 'react'
import { Grid, ButtonGroup, Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { updateTask } from '../../actions'
import AnswerKey from './AnswerKey'
import TitleBar from '../general/TitleBar'
import AddPageWrapper from '../general/AddPageWrapper'
const typeOptions = [{ id: 1, name: 'MCQ' }, { id: 2, name: 'Input' }]
const structureOptions = [
    { id: 1, name: 'Default' }, { id: 2, name: 'Group' },
    { id: 3, name: 'Sequence' }, { id: 4, name: 'Open' }
]
const layoutOptions = [{ id: 1, name: 'Vertical' }, { id: 2, name: 'Horizontal' }]
const sortingOptions = [{ id: 1, name: 'Fix Order' }, { id: 2, name: 'Random' }]

const containerStyle = { paddingBottom: 20 }
const divStyle = { margin: 36, userSelect: 'none' }
const flexStyle = { display: 'flex' }
const buttonGroupWrapperStyle = {
    border: '1px solid', borderRadius: 5,
    backgroundColor: '#F3F0F4', width: 240, marginRight: 60
}
const buttonGroupStyle = { paddingInline: 8, paddingBottom: 10 }
const titleWrapperStyle = { display: 'flex', justifyContent: 'space-around' }
const titleUnderlineStyle = { borderBottom: '1px solid' }
const normalStyle = { textTransform: 'none' }
const selectedStyle = { textTransform: 'none', color: '#E83D4D' }
export default function AnswerSetting({ count, task }) {
    const [expandAnswerSetting, setExpandAnswerSetting] = useState(false)
    const [settingOptions, setSettingOptions] = useState([{ target: 'type', options: typeOptions }])
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateTask(count, head, target, text, event))
    const { type, structure, layout, sorting } = task
    useEffect(() => {
        if (type === 1) {
            setSettingOptions([
                { target: 'type', options: typeOptions }, { target: 'layout', options: layoutOptions },
                { target: 'structure', options: structureOptions.filter(s => s.id !== 4) },
                { target: 'sorting', options: sortingOptions }
            ])
        } else if (type === 2) {
            setSettingOptions([
                { target: 'type', options: typeOptions },
                { target: 'structure', options: structureOptions.filter(s => s.id !== 3) }
            ])
        } else setSettingOptions([typeOptions])
    }, [type])
    console.log(task  , 'answer')
    return (
        <Grid container style={containerStyle}>
           
            <TitleBar title='Answer Setting' state={expandAnswerSetting} setState={setExpandAnswerSetting} />
           
            {expandAnswerSetting && <AddPageWrapper>
                <div style={divStyle}>
                    <div style={flexStyle}>
                        {settingOptions.map(({ target, options }, index) => (
                            <div key={index} style={buttonGroupWrapperStyle}>
                                <div style={titleWrapperStyle}>
                                    <h2 style={titleUnderlineStyle}>{target.charAt(0).toUpperCase() + target.slice(1)}</h2>
                                </div>
                                <ButtonGroup fullWidth variant="outlined" style={buttonGroupStyle}>
                                    {options.map(({ id, name }, i) => (
                                        <Button key={i} style={id === task[target] ? selectedStyle : normalStyle}
                                            onClick={() => update(count, 'taskText', target, id)}>{name}</Button>
                                    ))}
                                </ButtonGroup>
                            </div>
                        ))}
                    </div>
                    {(type === 1 && layout !== '' && sorting !== '' && structure !== '') ||
                        (type === 2 && structure !== '') ? <AnswerKey count={count} task={task} /> : null}
                </div>
            </AddPageWrapper>}
        </Grid>
    )
}