import { makeSureIsArray } from "../functions/general"
const countryOptions = []
const roleOptions = []
const languageOptions = []
export const countryOptionsReducer = (state = countryOptions, action) => {
    let newState = makeSureIsArray(state).slice()
    switch (action.type) {
        case 'SET_COUNTRY_OPTIONS':
            newState = action.options
            return newState
        default: return state
    }
}
export const roleOptionsReducer = (state = roleOptions, action) => {
    let newState = makeSureIsArray(state).slice()
    switch (action.type) {
        case 'SET_ROLE_OPTIONS':
            newState = action.options
            return newState
        default: return state
    }
}
const sortLanguages = data => {
    let importantInFront = []
    let notSoImportantAtTheBack = []
    let impt = ['English', 'Chinese', 'Malay', 'Tamil', 'Vietnamese', 'Egypt']
    impt.forEach(a => {
        data.forEach(d => {
            if (d.name.includes(a)) importantInFront.push(d)
        })
    })
    data.forEach(d => {
        if (importantInFront.find(a => a.name === d.name) === undefined) notSoImportantAtTheBack.push(d)
    })
    return [importantInFront, notSoImportantAtTheBack].flat()
}
export const languageOptionsReducer = (state = languageOptions, action) => {
    let newState = makeSureIsArray(state).slice()
    switch (action.type) {
        case 'SET_LANGUAGE_OPTIONS':
            newState = sortLanguages(action.options)
            return newState
        default: return state
    }
}