import { showWarningSwal } from "./alert";
export const getDaysArray = (startAndEndArr) => {
  if (startAndEndArr.length !== 2) return startAndEndArr;
  let arr = [];
  for (
    let dt = new Date(startAndEndArr[0]);
    dt <= new Date(startAndEndArr[1]);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};
export const isUnchanged = (original, competition) =>
  JSON.stringify(original) === JSON.stringify(competition);
export const deepCopy = (obj) => window.structuredClone(obj);
export const afterEdit = (getData, populate, setOriginal, state, setState) => {
  getData(`?limits=50&id=${state.id}`)
    .then((c) => {
      let found = populate(Object.values(c)[1]);
      setOriginal(deepCopy(found));
      setState(found);
    })
    .catch((e) => console.log(e));
};
export const populateTask = (list) => {
  let foundTask = list.data[0];
  if (foundTask === undefined) return showWarningSwal("Task not found");
  if (foundTask.solutions === null) foundTask.solutions = "";
  foundTask.domains = foundTask.tags.filter((t) => !t.is_tag).map((t) => t.id);
  foundTask.tags = foundTask.tags.filter((t) => t.is_tag).map((t) => t.id);
  if (foundTask.image_label) {
    for (const task_answer of foundTask.task_answers) {
      task_answer.correct = task_answer.answer === "1";
      for (const task_label of task_answer.task_labels) {
        task_label.content = "";
        task_label.image = task_label.content;
      }
      task_answer.answer = "";
    }
  } else {
    for (const task_answer of foundTask.task_answers) {
      task_answer.correct = task_answer.answer === "1";
      for (const task_label of task_answer.task_labels) {
        task_label.image = "";
      }
      // task_answer.answer = ''
    }
  }
  return foundTask;
};
export const populateCollection = (list) => {
  let foundCollection = list.data[0];
  if (foundCollection === undefined)
    return showWarningSwal("Collection not found");
  foundCollection.tags = foundCollection.tags.map((t) => t.id);
  for (const section of foundCollection.sections) {
    section.allow_skip = Boolean(section.allow_skip);
    section.sort_randomly = Boolean(section.sort_randomly);
  }
  return foundCollection;
};
export const validateCountryPartner = (
  format,
  global_registration_date,
  global_registration_end_date,
  competition_start_date,
  competition_end_date,
  registration_open_date,
  dates,
  originalDates,
  extended_end_date
) => {
  let msg = "";
  let tdy = new Date().setHours(0, 0, 0, 0);
  let pastOriginalDates = [];
  let pastCompetitionDates = [];
  if (originalDates.includes(tdy)) {
    pastOriginalDates = originalDates.filter((o) => o <= tdy);
    pastCompetitionDates = dates.filter((o) => o <= tdy);
  } else {
    pastOriginalDates = originalDates.filter((o) => o < tdy);
    pastCompetitionDates = dates.filter((o) => o < tdy);
  }
  if (registration_open_date === 0)
    msg += "Please select Country Registration Start Date.<br>";
  else {
    if (global_registration_date > registration_open_date) {
      msg +=
        "Country Registration Start Date must be after or equal to Global Registration Start Date.<br>";
    }
    if (format && global_registration_end_date < registration_open_date) {
      msg +=
        "Country Registration Start Date must be before or equal to Global Registration End Date.<br>";
    }
  }
  if (dates.length === 0 && format === 0)
    msg += "Please select at least one Country Competition Date.<br>";
  else {
    if (
      JSON.stringify(pastOriginalDates) !== JSON.stringify(pastCompetitionDates)
    ) {
      msg += `Please don't tamper with past Country Competition Dates.<br>`;
    }
    let sortedCompetitionDates = dates.sort((a, b) => a - b);
    if (
      new Date(sortedCompetitionDates[0]).getTime() < competition_start_date
    ) {
      msg +=
        "Earliest Country Competition Date must be after or equal to Global Competition Start Date.<br>";
    }

    if (!format && sortedCompetitionDates[0] < registration_open_date) {
      msg +=
        "Country Registration Start Date must be before or equal to Earliest Country Competition Date.<br>";
    }
  }
  return msg;
};
export const populateCompetition = (list) => {
  let foundCompetition = list;
  console.log(foundCompetition, "in pop");
  if (foundCompetition === undefined)
    return showWarningSwal("Competition not found");
  if (foundCompetition.status !== "active")
    return showWarningSwal("Invalid competition");
  foundCompetition.allowed_grades = foundCompetition.allowed_grades.map((g) =>
    Number(g)
  );
  foundCompetition.global_registration_date = new Date(
    foundCompetition.global_registration_date
  ).getTime();
  foundCompetition.global_registration_end_date = new Date(
    foundCompetition.global_registration_end_date
  ).getTime();
  foundCompetition.competition_start_date = new Date(
    foundCompetition.competition_start_date
  ).getTime();
  foundCompetition.competition_end_date = new Date(
    foundCompetition.competition_end_date
  ).getTime();
  let compOrgArr = [];
  let roundArr = [];
  let overallAwardsArr = [];
  for (const organization of foundCompetition.competition_organization) {
    const {
      id,
      organization_id,
      organization_name,
      country_id,
      translate,
      competition_mode,
      edit_sessions,
      status,
      registration_open_date,
      dates,
      extended_end_date,
    } = organization;
    let parsedTranslate = ["", "[]"].includes(translate)
      ? []
      : JSON.parse(translate);
    compOrgArr.push({
      id: id,
      competition_id: foundCompetition.id,
      organization_id: organization_id,
      organization_name: organization_name,
      country_id: country_id,
      registration_open_date: new Date(registration_open_date).getTime(),
      originalDates: dates.map((d) => new Date(d).getTime()),
      dates: dates.map((d) => new Date(d).getTime()),
      type: competition_mode.toString(),
      translate: parsedTranslate,
      translating: !!parsedTranslate.length,
      allowEdit: !!edit_sessions,
      languagesToView: [],
      status: status,
      originalStatus: status,
      comp_end_date: extended_end_date,
    });
  }
  foundCompetition.competition_organization = compOrgArr;
  for (const round of foundCompetition.rounds) {
    const {
      id,
      name,
      round_type,
      team_setting,
      individual_points,
      levels,
      award_type,
      assign_award_points,
      default_award_name,
      default_award_points,
      rounds_awards,
    } = round;
    //  console.log(award_type);
    roundArr.push({
      id: id,
      name: name,
      round_type: round_type,
      team_setting: team_setting,
      individual_points: individual_points,
      levels: levels,
      award_type: award_type == "Percentage" ? true : false,
      assign_award_points: !!assign_award_points,
      default_award_name: default_award_name,
      default_award_points: default_award_points ?? "",
      rounds_awards: rounds_awards
        ? rounds_awards.map((ra) => ({
            id: ra.id,
            name: ra.name,
            min_points: ra.min_marks,
            percentage: ra.percentage,
            award_points: ra.award_points,
          }))
        : [],
    });
  }
  foundCompetition.rounds = roundArr;
  if (foundCompetition.overall_awards_groups) {
    for (const overallAward of foundCompetition.overall_awards_groups) {
      const { id, name, percentage, overall_awards } = overallAward;
      overallAwardsArr.push({
        id,
        name,
        percentage,
        criterias: overall_awards.map((oa) => oa.competition_rounds_awards_id),
      });
    }
  } else {
    overallAwardsArr = [];
  }
  foundCompetition.overall_awards_groups = overallAwardsArr;
  console.log(foundCompetition);
  return foundCompetition;
};
