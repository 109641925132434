import React from 'react'
import { Box, Button, Grid } from '@mui/material'
import { makeSureIsArray } from '../../functions/general'
import NunitoText from '../general/NunitoText'
import CloseIcon from '@mui/icons-material/Close'
import './collections.css'
import ReusableButton from '../general/ReusableButton'
import ReusableSelect from '../general/ReusableSelect'
import { useState } from 'react'
import { CheckBox } from '@mui/icons-material'
export default function ManagePermission({ table, id, selected, approveFunction, cancelApprove, object }) {
    const [user,setUser] = useState([{name:'user1' , id:1}])
    const selectedRows = makeSureIsArray(table.data).filter(s => id === '' ? selected.includes(s.id) : s.id === id)
    const closeIconStyle = { fontSize: 36, cursor: 'pointer' }
    const closeIconDivStyle = { display: 'flex' }

    return (
        <Box className='popUpModal' style={{ width: 705 }}>
            <NunitoText value='Collection Permession Managment' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={30} />
            {selectedRows.map((s, i) => (
                <NunitoText marginBottom={'2%'} key={i} value={`${s.name || s.competition_name}${i < selectedRows.length - 1 && ', '}`} fontSize={20} marginTop={0} fontWeight={700} />
            ))}
            <div className='select-wrapper'>
                <select>
                    <option disabled selected hidden>Select User/Role</option>
                    <option>opt1</option>
                </select>
                </div>
                <div className='all-users'>
                    <div className='single-user'>
                        <div style={closeIconDivStyle}><CloseIcon style={closeIconStyle}/></div>
                        <h2>testUser1</h2>
                        <div className='select-boxes'>
                            <div className='box edit-box'>
                                <CheckBox></CheckBox><h5>Edit</h5>
                            </div>
                            <div className='box delete-box'>
                                <CheckBox></CheckBox><h5>Delete</h5>
                            </div>
                            <div className='box moderate-box'>
                                <CheckBox></CheckBox><h5>Moderate</h5>
                            </div>
                        </div>
                    </div>
                    <div className='single-user'>
                        <div style={closeIconDivStyle}><CloseIcon style={closeIconStyle}/></div>
                        <h2>testUser1</h2>
                        <div className='select-boxes'>
                            <div className='box edit-box'>
                                <CheckBox></CheckBox><h5>Edit</h5>
                            </div>
                            <div className='box delete-box'>
                                <CheckBox></CheckBox><h5>Delete</h5>
                            </div>
                            <div className='box moderate-box'>
                                <CheckBox></CheckBox><h5>Moderate</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer'>
                  <ReusableButton text='Save' bgColor='#5E75C3' fontSize={16}  iconType='save'/>
                </div>
            {/* <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63} width={280}
                    onClick={() => cancelApprove()} />
                <ReusableButton text='Confirm' bgColor='#5E75C3' fontSize={25} height={63} width={280}
                    onClick={() => approveFunction()} />
            </Grid> */}
        </Box>
    )
}