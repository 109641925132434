import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import NunitoText from '../general/NunitoText'
import { CountryIntegrityData, getConfirmedCountries } from '../../functions/getData'
import { Checkbox } from '@mui/material'
import ReusableButton from '../general/ReusableButton'
import { confirmedCountries } from '../../functions/postData'
import { useSnackbar } from 'notistack'
import { showNotification } from '../../functions/snackbar'
import ReusableToggle from '../general/ReusableToggle'
import ReusableAutocomplete from '../general/ReusableAutocomplete'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import { showWarningSwal } from '../../functions/alert'
import LightTable from '../table/LightTable'
import NoData from '../general/NoData'
import ReusableCheckbox from '../general/ReusableCheckbox'


export default function ConfirmCountriesModal({id , cancelFucntion , getData}) {
        const [Countries , setcountries] = useState()
        const [selectedCountries , setSelectedCountries] = useState()
        const [rerender , setrerender] = useState(true)
        const [isConfirm , setIsConfirm] = useState(true)
        const [countriesConfirmed , setConfirmedCountries] = useState([])
        const [unconfirmedCountries , setUnConfirmedCountreis] = useState([])
        const [header ,setHeader] = useState([
            { label: "Index Number", state: '', id: 'index_no', header: 'index_no' },
            { label: 'Name', state: 'name', id: 'name', header: 'name' },
            { label: 'School', state: [], id: 'school', header: 'school' },
            { label:'Country',state: [], id: 'country', header: 'country'},
            { label:'Integrity Issue Description',state: [], id: 'reason', header: 'reason'},
            { label:'Grade',state: [], id: 'grade', header: 'grade'},   
            { label:'type',state: [], id: 'type', header: 'type'}
        ])
        const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

        const [data , setData] = useState([])
        const [displayTable , setDisplayTable] = useState(false)
        const [checked , setChecked] = useState(false)
        const getCountriesData = ()=>{
            getConfirmedCountries(id).then(res=>{
                setcountries(res.countries)
                let confirmed = res.countries.filter(el=>{
                    return el.is_confirmed===1;
                })
                let unconfirmed = res.countries.filter(el=>{
                    return el.is_confirmed===0;
                })
                setConfirmedCountries(confirmed)
                setUnConfirmedCountreis(unconfirmed)
                
            })
        }
        useEffect(()=>{
            getCountriesData()
        },[])
        useEffect(()=>{
            setDisplayTable(false)
           
        },[isConfirm])
        const { enqueueSnackbar, closeSnackbar } = useSnackbar();

        const submit = ()=>{

            let payload = Countries.filter(el=>{
               return el.id===selectedCountries
            })
            let country = payload[0];
            country.is_confirmed = isConfirm?1:0;
            if(isConfirm){
                country.force_confirm = checked
            }
           if(selectedCountries===undefined){
            return showWarningSwal('please select a country')
           }
           console.log(payload)
            confirmedCountries(id,{ "countries":[country]}).then((res)=>{
            if(res.status==200){
                setChecked(false)
                showNotification(  "success",
                res.message,
                enqueueSnackbar,
                closeSnackbar)
                getData('')
                getCountriesData()
                setDisplayTable(false)
            }
           })
        }
        const checkData = ()=>{
            if(selectedCountries===undefined){
                return showWarningSwal('please select a country first')
            }

            CountryIntegrityData(id , selectedCountries).then(res=>{
                console.log(res)
                setData(res)
                setDisplayTable(true)
            })
        }
        console.log([
            {
                name: "Cambodia",
                id: 41,
                is_confirmed: 0
            },
            {
                name: "Singapore",
                id: 202,
                is_confirmed: 0
            }
        ].sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        }))
    return  <Box className='popUpModal horizontalScrollable' style={{ width: 1200, height: 500 }}>
                
                    <NunitoText value={`${isConfirm?'Confirm':'Revoke'} Country IAC Completion`} fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} marginBottom={20} />
                 
                <Grid alignItems={'center'} display={"flex"} justifyContent={'space-between'} width={'80%'} margin={'0 auto'}><ReusableToggle type="text" width={250} falseValue="Revoke" trueValue="confirm" state={isConfirm} setState={setIsConfirm} />
              { isConfirm&& <ReusableCheckbox
                type="modal"
                label={'No Participants from This Country'}
                fontSize="24px"
                state={checked}
                setState={setChecked}
              />}
               
                </Grid>
                <Grid display={'flex'} width={'80%'} margin={'0 auto'} justifyContent={'space-between'} alignItems={'flex-end'} alignContent={'center'} marginBottom={'3%'} marginTop={'3%'} >
                    <ReusableAutocompleteWithID state={selectedCountries} setState={setSelectedCountries}  type='withLabel' width={550} placeholder='Select the country' borderColor='#707070' 
                            options={!isConfirm?countriesConfirmed.sort((a, b) => {
                                if (a.name < b.name) return -1;
                                if (a.name > b.name) return 1;
                                return 0;
                            }).map(c => ({ id: c.id, option: c.name })):unconfirmedCountries.sort((a, b) => {
                                return (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
                            }).map(c => ({ id: c.id, option: c.name }))} marginRight={'2%'}/>
                     <ReusableButton  text={'Check'}  fontSize={22} height={60} width={380} bgColor='#5E75C3'  onClick={()=>{checkData()}} />
                </Grid>
                {<Grid container>
                  { displayTable? data.length>0?<LightTable  insideModal={true}  hasPagination={true}  selected={[]} selecting={[]} setSelecting={()=>{}} setSelected={()=>{}}  fixed={[]} data={data} headers={header}/>:<NoData/>:<></>}
                </Grid>}
                <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63} width={280}  
                    onClick={() => {
                        cancelFucntion()
                    }} />
                <ReusableButton text='Confirm' bgColor='#5E75C3' fontSize={25} height={63} width={280}
                    onClick={() => {
                       
                        submit()
                    }}   />
            </Grid>
            </Box>
}