import React, { useState } from "react";
import { Box, Grid, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import NunitoText from "../general/NunitoText";
import ReusableButton from "../general/ReusableButton";
import InputContainer from "../general/InputContainer";
import ReusableTextField from "../general/ReusableTextField";
import ReusableSelect from "../general/ReusableSelect";
const radioStyle = { color: "#000" };

export default function PatchMarkingOptionsModal({
  confirmFunction,
  cancelConfirm,
  participant,
}) {
  console.log(participant);
  // participant.award = null;
  const [award, setAward] = useState(
    participant.award ? participant.award : null
  );
  // const [award, setAward] = useState(null);
  const [schoolRank, setSchoolRank] = useState(
    participant.school_rank ? participant.school_rank : null
  );
  const [countryRank, setCountryRank] = useState(
    participant.country_rank ? participant.country_rank : null
  );
  const [globalRankString, setGlobalRankString] = useState(
    participant.global_rank
      ? participant?.global_rank?.substring(
          0,
          participant.global_rank.lastIndexOf(" ")
        )
      : null
  );
  const [globalRankNumber, setGlobalRankNumber] = useState(
    participant.global_rank
      ? participant?.global_rank?.substring(
          participant.global_rank.lastIndexOf(" ") + 1
        )
      : null
  );

  const handleConfirm = () => {
    let changedValues = {};
    if (participant?.award !== award) {
      changedValues["award"] = award;
    }
    if (participant?.school_rank !== schoolRank) {
      changedValues["school_rank"] = schoolRank;
    }
    if (participant?.country_rank !== countryRank) {
      changedValues["country_rank"] = countryRank;
    }
    if (
      participant?.global_rank !==  globalRankNumber &&
      globalRankNumber !== null
    ) {
      changedValues["global_rank"] =  globalRankNumber;
    }
    if (Object.keys(changedValues).length === 0) {
      cancelConfirm();
    } else {
      confirmFunction(participant.index_no, changedValues);
    }
  };

  return (
    <Box
      className="popUpModal"
      style={{
        width: 705,
        minHeight: 310,
        position: "fixed",
        top: "50%",
        right: "50%",
        zIndex: 15,
      }}
    >
      <NunitoText
        value="Edit Participant Ranking"
        fontSize={30}
        fontWeight={600}
        color="#5E75C3"
        marginTop={30}
      />
      <NunitoText
        value={`Update ranking of ${participant.name}`}
        fontSize={20}
        fontWeight={600}
      />
      <NunitoText
        value={`All field are optional`}
        fontSize={20}
        fontWeight={600}
      />

      {/* CONTENT */}
      <Box
        style={{
          margin: "30px 50px 20px",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginLeft="35px"
          gap={20.5}
        >
          <NunitoText
            value={`Award:`}
            fontSize={20}
            fontWeight={600}
            marginTop={2}
            color={participant?.award === award ? "gray" : "black"}
          />
          <ReusableSelect
            type="type2"
            state={award ? award : "null"}
            setState={setAward}
            btnBgColor="#144A94"
            height={45}
            width={300}
            options={[
              participant.award === null && {
                value: null,
                option: "_Not_Calculated",
              },
              { value: "PERFECT SCORE", option: "PERFECT SCORE" },
              { value: "GOLD", option: "GOLD" },
              { value: "SILVER", option: "SILVER" },
              { value: "BRONZE", option: "BRONZE" },
              { value: "HONORABLE MENTION", option: "HONORABLE MENTION" },
              {
                value: "CERTIFICATE OF PARTICIPATION",
                option: "CERTIFICATE OF PARTICIPATION",
              },
            ]}
          />
        </Box>
        <InputContainer
          label="School_Rank:"
          labelColor={
            participant?.school_rank === schoolRank ? "gray" : "black"
          }
          field={
            <ReusableTextField
              type="default"
              number
              width={300}
              placeholder="Enter school rank here"
              state={schoolRank}
              setState={(value) => {
                value >= 1 ? setSchoolRank(value) : setSchoolRank(null);
              }}
            />
          }
        />
        <InputContainer
          label="Country_Rank:"
          labelColor={
            participant?.country_rank === countryRank ? "gray" : "black"
          }
          field={
            <ReusableTextField
              type="default"
              number
              width={300}
              placeholder="Enter country rank here"
              state={countryRank}
              // setState={setCountryRank}
              setState={(value) => {
                value >= 1 ? setCountryRank(value) : setCountryRank(null);
              }}
            />
          }
        />
        <InputContainer
          label="Global_Rank:"
          labelColor={
            (participant?.global_rank === null && globalRankString == null) ||
            participant?.global_rank ===
              globalRankString + " " + globalRankNumber
              ? "gray"
              : "black"
          }
          field={
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={1}
              marginLeft={9.5}
            >
            
              <ReusableTextField
                type="default"
                number
                width={300}
                placeholder="global rank NO"
                // required
                state={globalRankNumber}
                // setState={setGlobalRankNumber}
                setState={(value) => {
                  value >= 1
                    ? setGlobalRankNumber(Number(value))
                    : setGlobalRankNumber(null);
                }}
              />
            </Box>
          }
        />
      </Box>

      <Grid
        container
        justifyContent="space-evenly"
        style={{ marginBlock: "30px 20px" }}
      >
        <ReusableButton
          text="Cancel"
          bgColor="#8D8D8D"
          fontSize={25}
          height={63}
          width={280}
          onClick={() => cancelConfirm()}
        />
        <ReusableButton
          text="Confirm"
          bgColor="#5E75C3"
          fontSize={25}
          height={63}
          width={280}
          onClick={handleConfirm}
        />
      </Grid>
    </Box>
  );
}
