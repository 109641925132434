import React from 'react'
import { Box, Grid } from '@mui/material'
import { makeSureIsArray } from '../../functions/general'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
export default function ConfirmAddTaskModal({ table, id, selected, addFunction, cancelAddTask, sectionCount , selectNames , setSelectedNames}) {
    const selectedRows = makeSureIsArray(table.data).filter(s => id === '' ? selected.includes(s.id) : s.id === id)
    console.log(selected)
    return (
        <Box className='popUpModal horizontalScrollable' style={{ width: 1000, height: 500 }}>
            <NunitoText value='ADD TASK TO COLLECTION' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
            <NunitoText value={`Are you sure you want to add these task(s) to Section ${sectionCount + 1}`}
                fontSize={20} fontWeight={600} />
            <div>
                {selected.length===0? <NunitoText  value={makeSureIsArray(table.data).find(t=>t.id===id).title} fontSize={20} fontWeight={700} />:selected.map((s, i) => (
                    <NunitoText key={i} value={`${selectNames.find(t=>t.id===s).title}${i < selected.length - 1 && ', '}`} fontSize={20} fontWeight={700} />
                ))}
            </div>
            <Grid container justifyContent='center' style={{ marginBlock: '20px 40px' }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={22} width={300} marginRight={60}
                    onClick={() => cancelAddTask()} />
                <ReusableButton text='Confirm' bgColor='#5E75C3' fontSize={22} width={300} marginLeft={60}
                    onClick={() => addFunction()} />
            </Grid>
        </Box>
    )
}