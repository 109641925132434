import React from 'react'
import { Grid } from '@mui/material'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
import Error404Image from '../assets/images/404.png'
import { isLoggedIn } from '../functions/checkrole'
export default function Error404() {
    document.title = 'Error 404'
    return (
        <Grid container>
            <Grid item xs={7} style={{ padding: 120 }}>
                <NunitoText value='404' fontSize={135} color='#008ECC' />
                <NunitoText value='Sorry, the page you are looking for does not exist.' fontSize={50} color='#707070'
                    marginTop={40} />
                <ReusableButton text={`Back to ${isLoggedIn() ? 'Dashboard' : 'Sign In'}`} fontSize={30} bgColor='#008ECC'
                    height={80} width={460} marginTop={80} to={isLoggedIn() ? '/dashboard' : '/signin'} />
            </Grid>
            <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={Error404Image} alt='Error Logo' />
            </Grid>
        </Grid >
    )
}