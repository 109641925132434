import React, { useState, useEffect } from 'react'
import { Box, Grid, Checkbox } from '@mui/material'
import { makeSureIsArray } from '../../functions/general'
import { getCompetitions } from '../../functions/getData'
import CloseIcon from '@mui/icons-material/Close'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
const TaskRow = ({ count, task, assignedTasks, setAssignedTasks, checked, setChecked, selected, setSelected,
    sectionOptions, difficultyOptions, collection }) => {
    const handleSelectClick = id => {
        let newChecked = [...checked]
        newChecked.includes(id) ? newChecked.splice(newChecked.findIndex(n => n === id), 1) : newChecked.push(id)
        setChecked(newChecked)
    }
    const remove = id => {
        setAssignedTasks(assignedTasks.filter(a => a.id !== id))
        setSelected(selected.filter(s => s !== id))
    }
    const updateAssignedTasks = (count, target, value) => {
        let newAssignedTasks = [...assignedTasks]
        if (target === 'correct') {

        } else {
            newAssignedTasks[count][target] = value
        }
        setAssignedTasks(newAssignedTasks)
    }
    const checkBoxStyle = {
        color: "#000", marginTop: 6
    }
    const closeIconStyle = {
        cursor: 'pointer', fontSize: 32, marginTop: 12
    }
    const divStyle = {
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: 30, marginTop: 6
    }
    const titleDivStyle = {
        whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: 'hidden', width: 100, textAlign: 'left'
    }
    const showLine = task.structure === 'open' && assignedTasks.length > 1 && assignedTasks[count - 1].structure !== 'open'
    return (
        <>
            {showLine && <div style={{ width: '100%', borderBottom: '1px solid ' }} />}
            <Grid container justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 10 }}>
                <Checkbox style={checkBoxStyle} onClick={() => handleSelectClick(task.id)} checked={checked.includes(task.id)} />
                <div style={divStyle}>
                    <div style={titleDivStyle}>
                        {`${collection !== '' ? `${collection}-` : ''}${task.title}`}
                    </div>
                    {collection !== '' && 'Recommendations'}
                </div>
                <ReusableAutocompleteWithID type='default' width={290} height={50} placeholder='Section*' marginTop={6}
                    state={task.section} setState={updateAssignedTasks} count={count} target='section' btnBgColor='#5E75C3' required
                    options={sectionOptions.map(c => ({ id: c.id, option: c.name }))} />
                <ReusableAutocompleteWithID type='default' width={290} height={50} placeholder='Difficulty*' marginLeft={20} marginTop={6}
                    state={task.difficulty} setState={updateAssignedTasks} count={count} target='difficulty' btnBgColor='#5E75C3' required
                    options={difficultyOptions.map(c => ({ id: c.id, option: c.name }))} />
                {task.structure === 'open' ? <div style={{ display: 'flex', justifyContent: 'space-between', width: 680 }}>
                    <ReusableTextField type='default' width={240} height={60} bgColor='#F2F2F2'
                        placeholder='Minimum Points*' count={count} target='min' number state={task.min}
                        setState={updateAssignedTasks} marginLeft={20} required />
                    <ReusableTextField type='default' width={240} height={60} bgColor='#F2F2F2'
                        placeholder='Minimum Points*' count={count} target='max' number state={task.max}
                        setState={updateAssignedTasks} marginLeft={20} required />
                </div> : <div style={{ display: 'flex', width: 680 }}>
                    <div style={{ width: 420 }}>
                        <Grid container>
                            {Object.keys(task).filter(key => key.includes('correct')).map((key, i) => (
                                <div key={key} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <ReusableTextField type='default' width={110} height={60} bgColor='#F2F2F2'
                                        placeholder='' count={count} target={key} number state={task[key]}
                                        setState={updateAssignedTasks} marginLeft={20} required />
                                    <a style={{ marginLeft: 20 }} href={`/edittask?id=${task.id}#${task.answers[i]?.id}`}>{task.answers[i]?.id}</a>
                                </div>
                            ))}
                        </Grid>
                    </div>
                    <ReusableTextField type='default' width={110} height={60} bgColor='#F2F2F2'
                        placeholder='' count={count} target='wrong' number state={task.wrong}
                        setState={updateAssignedTasks} marginLeft={20} required />
                    <ReusableTextField type='default' width={110} height={60} bgColor='#F2F2F2'
                        placeholder='' count={count} target='blank' number state={task.blank}
                        setState={updateAssignedTasks} marginLeft={20} required />
                </div>}
                <CloseIcon style={closeIconStyle} onClick={() => remove(task.id)} />
            </Grid>
        </>
    )
}
export default function AddTaskToCompetitionAndCollectionModal({ afterAddTask, tasks, selected, setSelected, cancelAssignTask }) {
    const determineNumberOfCorrect = task => {
        let arr = []
        if (task.answer_type === 'mcq' && task.answer_structure === 'sequence') arr.push('')
        else if (['sequence', 'group', 'open'].includes(task.answer_structure)) arr.push('')
        else {
            task.task_answers.forEach(a => {
                if (task.answer_type === 'mcq') {
                    if (a.answer === '1') arr.push('')
                } else arr.push('')
            })
        }
        return arr
    }
    const selectedTasks = selected.map(s => {
        let t = makeSureIsArray(tasks).find(t => t.id === s)
        let obj = {
            id: t.id, structure: t.answer_structure, title: t.title, answers: t.task_answers,
            section: '', difficulty: '', wrong: '', blank: '', min: '', max: ''
        }
        determineNumberOfCorrect(t).forEach((a, i) => obj[`correct_${i}`] = '')
        return obj
    })
    const [assignedTasks, setAssignedTasks] = useState(selectedTasks)
    const [competition, setCompetition] = useState('')
    const [collection, setCollection] = useState('')
    const [section, setSection] = useState('')
    const [difficulty, setDifficulty] = useState('')
    const [checked, setChecked] = useState([])

    const [competitionOptions, setCompetitionOptions] = useState([])
    // const [collectionOptions, setCollectionOptions] = useState([])
    const sectionOptions = []
    const difficultyOptions = []
    // const [sectionOptions, setSectionOptions] = useState([])
    // const [difficultyOptions, setDifficultyOptions] = useState([])

    useEffect(() => {
        getCompetitions('?limits=50&status=active').then(c => setCompetitionOptions(c.competitionList.data))
            .catch(e => setCompetitionOptions([]))
    }, [])

    const onChangeCompetition = value => {
        setCompetition(value)
    }
    const onChangeCollection = value => {
        setCollection(value)
    }
    const onChangeSection = value => {
        setSection(value)
    }
    const onChangeDifficulty = value => {
        setDifficulty(value)
    }
    const massAssignScore = target => {
        let newAssignedTasks = [...assignedTasks]
        if (target === 'correct') {
            let allCorrectKeys = new Set([...assignedTasks.map(a => Object.keys(a)).flat().filter(key => key.includes('correct'))])
            allCorrectKeys.forEach(key => {
                let firstEncounter = newAssignedTasks.find(n => n[key] !== undefined)[key]
                newAssignedTasks.forEach(n => {
                    if (checked.includes(n.id) && n[key] !== undefined) n[key] = firstEncounter
                })
            })
        } else {
            newAssignedTasks.forEach(n => {
                if (checked.includes(n.id)) n[target] = newAssignedTasks[0][target]
            })
        }
        setAssignedTasks(newAssignedTasks)
    }
    const onSubmit = () => {

    }
    const sortByStructure = tasks => {
        // Place open at the bottom
        return tasks.sort((a, b) => {
            if (a.structure === 'open') return 1
            else if (b.structure === 'open') return -1
            else return 0
        })
    }
    return (
        <Box className='popUpModal horizontalScrollable' style={{ width: 1600, maxWidth: 1800, height: 800, maxHeight: 900 }}>
            <div>
                <NunitoText value='Assign Tasks to Competition and Collection' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
                <Grid container style={{ marginTop: 40, paddingInline: 230 }}>
                    <Grid container style={{ border: '1px solid #707070', borderRadius: 6, padding: 20 }}>
                        <Grid container>
                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                <ReusableAutocompleteWithID type='withLabel' width={510} height={50} placeholder='Competition*'
                                    state={competition} setState={onChangeCompetition} btnBgColor='#5E75C3' required
                                    options={competitionOptions.map(c => ({ id: c.id, option: c.name }))} />
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                <ReusableAutocompleteWithID type='withLabel' width={510} height={50} placeholder='Collection*'
                                    state={collection} setState={onChangeCollection} btnBgColor='red' required
                                    options={[{ id: 1, name: 'c1' }, { id: 2, name: 'c2' }].map(c => ({ id: c.id, option: c.name }))} />
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: 20 }}>
                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-end' }}>
                                <ReusableAutocompleteWithID type='withLabel' width={290} height={50} placeholder='Section*'
                                    state={section} setState={onChangeSection} btnBgColor='#5E75C3' required
                                    options={sectionOptions.map(c => ({ id: c.id, option: c.name }))} />
                                <ReusableButton text='Mass Assign Section' fontSize={15} height={50} width={200} bgColor='#5E75C3'
                                    onClick={() => massAssignScore('section')} />
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-end' }}>
                                <ReusableAutocompleteWithID type='withLabel' width={290} height={50} placeholder='Difficulty*'
                                    state={difficulty} setState={onChangeDifficulty} btnBgColor='#5E75C3' required
                                    options={difficultyOptions.map(c => ({ id: c.id, option: c.name }))} />
                                <ReusableButton text='Mass Assign Difficulty' fontSize={15} height={50} width={200} bgColor='#5E75C3'
                                    onClick={() => massAssignScore('difficulty')} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent='space-between' style={{ marginTop: 20, paddingInline: 140 }}>
                    <ReusableButton text='Mass Assign Correct Points' fontSize={15} height={45} width={240} bgColor='#6FC9B6'
                        onClick={() => massAssignScore('correct')} />
                    <ReusableButton text='Mass Assign Wrong Points' fontSize={15} height={45} width={240} bgColor='#F16774'
                        onClick={() => massAssignScore('wrong')} />
                    <ReusableButton text='Mass Assign Blank Points' fontSize={15} height={45} width={240} bgColor='#707070'
                        onClick={() => massAssignScore('blank')} />
                    <ReusableButton text='Mass Assign Minimum Points' fontSize={15} height={45} width={270} bgColor='#5E75C3'
                        onClick={() => massAssignScore('min')} />
                    <ReusableButton text='Mass Assign Maximum Points' fontSize={15} height={45} width={270} bgColor='#5E75C3'
                        onClick={() => massAssignScore('max')} />
                </Grid>
                <Grid container style={{ marginTop: 40 }} justifyContent="center">
                    {sortByStructure(assignedTasks).map((task, i) => (
                        <TaskRow key={task.id} count={i} task={task} assignedTasks={sortByStructure(assignedTasks)}
                            setAssignedTasks={setAssignedTasks} checked={checked} setChecked={setChecked} selected={selected} setSelected={setSelected}
                            sectionOptions={sectionOptions} difficultyOptions={difficultyOptions} collection={collection} />
                    ))}
                </Grid>
            </div>
            <Grid container justifyContent='space-evenly' style={{ marginBlock: 30, paddingInline: 300 }}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => cancelAssignTask()} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
        </Box>
    )
}