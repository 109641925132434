import React, { useState } from 'react'
import { Grid } from '@mui/material'
import Answer from './Answer'
import TitleBar from '../general/TitleBar'
import AddPageWrapper from '../general/AddPageWrapper'
import NunitoText from '../general/NunitoText'
import ReusableToggle from '../general/ReusableToggle'
const containerStyle = { marginTop: 20 }
const uploadImageContainerStyle = { marginTop: 30 }
const answersContainerStyle = { margin: '10px 30px 30px 30px' }
export default function AnswerKey({ original, setOriginal, task, setTask,isread}) {
    const [expand, setExpand] = useState(true)
    const { image_label, task_answers } = task
    const updateTask = (identifier, value) => {
        let newTask = window.structuredClone(task)
        newTask[identifier] = value
        setTask(newTask)
    }
    console.log('answers',task_answers)
    return (
        <Grid container style={containerStyle}>
            <TitleBar title='Answer Key' state={expand} setState={setExpand} />
            {expand && <AddPageWrapper>
                <Grid container justifyContent='center' alignItems='center' style={uploadImageContainerStyle}>
                    <NunitoText value='Upload Answers as Image' fontSize={22} fontWeight={600} marginRight={10} />
                    <ReusableToggle type='icon' width={124} state={image_label} setState={updateTask} disabled={isread}
                        target='image_label' marginRight={20} />
                </Grid>
                <Grid container style={answersContainerStyle}>
                    {task_answers.map((answer, index) => (
                        <Answer key={index} count={index} answer={answer} original={original} setOriginal={setOriginal} isread={isread}
                            task={task} setTask={setTask} />
                    ))}
                </Grid>
            </AddPageWrapper>}
        </Grid >
    )
}