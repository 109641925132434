import React, { useState, useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { checkPassword, invalidName, invalidEmail, makeSureIsArray } from '../../functions/general'
import { showWarningSwal, isEmpty, warningMessage } from '../../functions/alert'
import { getSchools, getOrganizations } from '../../functions/getData'
import { addUser } from '../../functions/postData'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableCheckbox from '../general/ReusableCheckbox'
import ReusableButton from '../general/ReusableButton'
import { isAdmin, isManager, isPartnerOrAssistant } from '../../functions/checkrole'
const boxStyle = { width: 1200, minHeight: 400, maxHeight: '90%' }
const gridContainerStyle = { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
const firstRowStyle = { ...gridContainerStyle, marginTop: 20, marginBottom: 20, paddingInline: 100 }
const rowStyle = { ...gridContainerStyle, marginBottom: 20, paddingInline: 100 }
const checkBoxDivStyle = { width: 435, textAlign: 'left' }
const passwordRowStyle = { ...gridContainerStyle, paddingInline: 100 }
const buttonsContainerStyle = { ...gridContainerStyle, marginBlock: '10px 30px', paddingInline: 230 }
export default function AddUserModal({ setLoading, setOnAddUser, table, id, afterAddingUser, cancelAddUser }) {
    const isMounted = useRef(false)
    const selectedSchool = table === undefined ? undefined : makeSureIsArray(table.data).find(s => s.id === id)
    const [name, setName] = useState('')
    const [role, setRole] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [organization, setOrganization] = useState('')
    const [email, setEmail] = useState('')
    const [school, setSchool] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [country, setCountry] = useState('')

    const [teacherBelongsToTuitionCentre, setTeacherBelongsToTuitionCentre] = useState(false)

    const allCountryOptions = useSelector(state => state.countryOptions)
    const roleOptions = useSelector(state => state.roleOptions)
    const [schoolOptions, setSchoolOptions] = useState([])
    const [organizationOptions, setOrganizationOptions] = useState([])
    // Only Country Partner will use getSchools
    useEffect(() => {
        console.log('fire once user')
        isMounted.current = true
        const controller = new AbortController()
        const signal = controller.signal
        if (id !== undefined) {
            onChangeTeacherBelongs(selectedSchool.private)
            setRole(3)
            setCountry(selectedSchool.country_id)
            setSchoolOptions([selectedSchool])
            setSchool(selectedSchool.id)
            if (isAdmin()) {
                getOrganizations('?status=active&limits=100', signal).then(o => {
                    let organizations = o.OrganizationLists.data.filter(d =>
                        d.users.map(u => u.country_id).includes(selectedSchool.country_id)
                    )
                    setOrganizationOptions(organizations.map(d => ({ id: d.id, name: d.name, users: d.users })))
                }).catch(e => console.log(e))
            }
        } else {
            if (isAdmin()) {
                getOrganizations('?status=active&limits=100', signal).then(o => {
                    setOrganizationOptions(o.OrganizationLists.data.map(d => ({ id: d.id, name: d.name, users: d.users })))
                }).catch(e => console.log(e))
            }
            if (isPartnerOrAssistant()) {
                getSchools(`?status=active&limits=100`, signal).then(s => {
                    setSchoolOptions(s.SchoolLists.data.map(d => ({ id: d.id, name: d.name })))
                }).catch(e => console.log(e))
            }
        }
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [id, selectedSchool])
    // Only Admin will use onChangeCountry
    const onChangeRole = value => {
        setRole(value)
        if (['', 1].includes(value)) setCountry('')
        if (value > 2) setCountry('')
    }
    let countries = [...new Set(organizationOptions.find(o => o.id === organization)?.users
        .filter(u => u.status !== 'deleted').map(u => u.country_id))]
    const countryOptions = selectedSchool ? allCountryOptions : role === 2 ? allCountryOptions : allCountryOptions.filter(c => countries.includes(c.id))
    const onChangeOrganization = value => {
        setOrganization(value)
        if (role > 2 && id === undefined) setCountry('')
    }
    const onChangeCountry = value => {
        setSchool('')
        setCountry(value)
        if (value !== '') {
            getSchools(`?country_id=${value}&status=active&limits=100`)
                .then(d => setSchoolOptions(d.SchoolLists.data))
                .catch(e => console.log(e))
        } else {
            setSchoolOptions([])
        }
    }
    const onChangeTeacherBelongs = value => {
        setTeacherBelongsToTuitionCentre(value)
        setSchool('')
    }
    const onSubmit = () => {
        let arr = [
            { name: 'Full Name', state: name },
            { name: 'Username', state: username },
            { name: 'Role', state: role },
            { name: 'Password', state: password },
            { name: 'Password Confirmation', state: confirmPassword },
            { name: 'Email', state: email },
            { name: 'Phone', state: phoneNumber }
        ]
        if (isAdmin()) {
            if (role > 1) arr.push({ name: 'Organization', state: organization }, { name: 'Country', state: country })
        }
        if ([3, 5].includes(role) && !isManager()) {
            arr.push({ name: teacherBelongsToTuitionCentre ? 'Tuition Centre' : 'School', state: school })
        }
        if (isEmpty(arr.map(a => a.state))) return warningMessage(arr)
        let msg = ''
        if (invalidName(name)) msg += 'Invalid name.<br>'
        if (invalidName(username)) msg += 'Invalid username.<br>'
        if (invalidEmail(email)) msg += 'Invalid email.<br>'
        if (isNaN(phoneNumber) || !phoneNumber.toString().length) {
            msg += 'Phone number must be number.<br>'
        }
        if (checkPassword(password)) msg += 'Password does not meet requirement.<br>'
        if (password !== confirmPassword) msg += 'Password and Confirm Password must match.'
        if (msg.length) return showWarningSwal(msg)
        setLoading(true)
        let payload = [{
            name, username, password, password_confirmation: confirmPassword, about: 'test',
            role_id: role, email, organization_id: null, school_id: null, phone: phoneNumber
        }]
        if (isAdmin()) {
            if (role > 1) {
                payload[0].organization_id = organization
                payload[0].country_id = country
            }
        }

        if ([3, 5].includes(role)) {
            if (!isManager()) payload[0].school_id = school
        }
        addUser(payload).then(d => {
            isMounted.current && setLoading(false)
            afterAddingUser(cancelFunction, d)
        }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
    }
    const cancelFunction = () => cancelAddUser ? cancelAddUser() : setOnAddUser(false)
    return (
        <Box className='popUpModal horizontalScrollable' style={boxStyle}>
            <div>
                <NunitoText value={selectedSchool ? 'ADD NEW TEACHER' : 'ADD NEW USER'} fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
                <div style={firstRowStyle}>
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Full Name*'
                        state={name} setState={setName} required={!name} />
                    <ReusableAutocompleteWithID type='withLabel' width={435} height={55} btnWidth={63} btnBgColor='#144A94'
                        borderColor='#707070' placeholder='Role*' state={role} setState={onChangeRole} required
                        options={roleOptions.filter(r => r.id > 0).map(r => ({ id: r.id, option: r.name }))}
                        grayedOut={selectedSchool} readOnly={selectedSchool} />
                </div>
                <div style={rowStyle}>
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Username*'
                        state={username} setState={setUsername} required />
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='E-Mail*'
                        state={email} setState={setEmail} required />
                </div>
                <div style={passwordRowStyle}>
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Password*'
                        state={password} setState={setPassword} required={checkPassword(password)} />
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Confirm Password*'
                        state={confirmPassword} setState={setConfirmPassword}
                        required={checkPassword(password) || password !== confirmPassword} />
                </div>
                <NunitoText value='Minimum 8 characters with one uppercase, one lowercase letter, one number and one of the following 
                letters: !$#%@'  fontSize={16} fontWeight={400} marginTop={10} color={checkPassword(password) && '#E83D4D'} />
                <NunitoText value='Password must match confirm password' fontSize={16} fontWeight={400} marginTop={10}
                    color={(checkPassword(password) || password !== confirmPassword) && '#E83D4D'} />
                <div style={rowStyle}>
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2'
                        placeholder={`Phone Number${country !== '' ? `(+${countryOptions.find(c => c.id === country)?.Dial})` : ''}*`}
                        state={phoneNumber} setState={setPhoneNumber} required />
                    {[3, 5].includes(role) && !isManager() && <div style={checkBoxDivStyle}>
                        {selectedSchool ?
                            <NunitoText value={`Add to a ${teacherBelongsToTuitionCentre ? 'Tuition centre' : 'School'}`}
                                fontSize={20} /> :
                            <ReusableCheckbox type='default' label='Add to a Tuition Centre?'
                                state={teacherBelongsToTuitionCentre} setState={onChangeTeacherBelongs} fontSize={20} />}
                    </div>}
                </div>
                {[2, 3, 4, 5].includes(role) && isAdmin() && <div style={rowStyle}>
                    <ReusableAutocompleteWithID type='withLabel' width={435} height={55} btnWidth={63} btnBgColor='#144A94'
                        borderColor='#707070' placeholder='Organization*' state={organization} setState={onChangeOrganization}
                        options={organizationOptions.map(o => ({ id: o.id, option: o.name }))} required />
                    <ReusableAutocompleteWithID type='withLabel'
                        width={435} height={55} btnWidth={63} btnBgColor='#144A94' borderColor='#707070' placeholder='Country*'
                        state={country} setState={onChangeCountry}
                        options={countryOptions.map(c => ({ id: c.id, option: c.display_name }))} required
                        readOnly={selectedSchool || (role > 2 && !organization)} grayedOut={selectedSchool || (role > 2 && !organization)} />
                </div>}
                {[3, 5].includes(role) && isAdmin() && <div style={rowStyle}>
                    <ReusableAutocompleteWithID type='withLabel' width={435} height={55} btnWidth={63}
                        btnBgColor='#144A94' borderColor='#707070' state={school} setState={setSchool}
                        placeholder={teacherBelongsToTuitionCentre ? 'Tuition Centre*' : 'School*'}
                        options={selectedSchool ? schoolOptions.map(s => ({ id: s.id, option: s.name })) :
                            teacherBelongsToTuitionCentre ?
                                schoolOptions.filter(s => s.private).map(s => ({ id: s.id, option: s.name })) :
                                schoolOptions.filter(s => !s.private).map(s => ({ id: s.id, option: s.name }))}
                        required grayedOut={!country || selectedSchool} readOnly={!country || selectedSchool} />
                </div>}
                {[3, 5].includes(role) && isPartnerOrAssistant() && <div style={rowStyle}>
                    <ReusableAutocompleteWithID type='withLabel' width={435}
                        height={55} btnWidth={63} btnBgColor='#144A94' borderColor='#707070'
                        placeholder={teacherBelongsToTuitionCentre ? 'Tuition Centre*' : 'School*'}
                        state={school} setState={setSchool}
                        options={teacherBelongsToTuitionCentre ?
                            schoolOptions.filter(s => s.private).map(s => ({ id: s.id, option: s.name })) :
                            schoolOptions.filter(s => !s.private).map(s => ({ id: s.id, option: s.name }))}
                        required grayedOut={!role || selectedSchool} readOnly={!role || selectedSchool} />
                </div>}
            </div>
            <div style={buttonsContainerStyle}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => cancelFunction()} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </div>
        </Box>
    )
}