import { Box, Collapse, Grid, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { downloadCheater, getCheaters, getConfirmedCountries, getCountries2, getSameParticipants } from '../functions/getData'
import { showNotification } from '../functions/snackbar'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useMemo } from 'react'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
import CheatingBar from '../components/CheatingBar'
import CheatDownloadModal from '../components/modal/CheatDownloadModal'
import ComputeCheating from '../components/modal/ComputeCheating'

import ElemParticipant from '../components/modal/ElemParticipant'
import LightTable from '../components/table/LightTable'
import { changeFiltered, changeSearch, makeOptions } from '../functions/general'
import Filter from '../components/Filter'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import { elemParticipants } from '../functions/postData'
import { dieliminate } from '../functions/deleteData'
import NoData from '../components/general/NoData'
import ReusableTextField from '../components/general/ReusableTextField'
import ConfirmCountriesModal from '../components/modal/ConfirmCountriesModal'
import { showWarningSwal, warningMessage } from '../functions/alert'
import { FaCircleQuestion } from 'react-icons/fa6'
import CheatingListInstructionsModal from '../components/modal/CheatingListInstructionsModal'
import CustomeElemModal from '../components/modal/CustomElemModal'
import CustomeDeelemModal from '../components/modal/CustomeDeElemModal'
import IntegrityCheckSummaryModal from '../components/modal/IntegrityCheckSummaryModal'
export default function CheatingList(){
    const [percentage , setPercentage] = useState(0)
    const [compName , setCompName] = useState('')
    const [canBeComputed , setCanBeComputed] = useState(true)
    const [recomute , setRecompute] = useState(false)
    const [recomputePotinal , setRecomputePotinal] = useState(false)
    const [canBeDownloaded , setCanBeDownload] = useState(false)
    const [canBeDownloadedMap , setCanBeDownloadedMap] = useState(false)
    const [tableHeader , setTableHeader] = useState()
    const [data , setData] = useState()
    const [reCallEndpoint, setRecallEndpoint] = useState(false)
    const [reload , setReload] = useState(true)
    const [onDownload , setOnDownload] = useState(false)
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onElem , setOnElem] = useState(false)
    const [potinalMsg , setPotinalMsg] = useState('')
    const [displayFiltering, setDisplayFiltering] = useState(false);
    const [computedContries , setComputedConties] = useState('')
    const [params , setParams] = useState('?')
    const [allCountryOptions , setallCountryOptions] = useState()
    const [remainingCountries , setRemainingcountries] = useState('')
    const [confirmedCountries , setConfirmedCountries] = useState('')
    const [customElem , setOnCustomeElem] = useState(false)
    const [customDeElem , setOnCustomeDeElem] = useState(false)
    const [summary , onSummary] = useState(false)
    const [potinalHeader , setPotinalHeader] = useState([]);
    const [potinalData , setPotinalData] = useState();
    const [filterOptions, setFilterOptions] = useState();
    const [PotinalfilterOptions, setPotinalFilterOptions] = useState();
    const [msg , setMsg] = useState('')
    const [CheatingTable , setCheatingTable] = useState(true);
    const [instructions , setInsetructions] = useState(false)
    const [comp , onComp] = useState(false)
    const [restore , onRestore] = useState(false)
    const [onConfirmCountries , setOnConfirmCountries] = useState(false)
    const [err , setError] = useState()
    const [search , setSearch] = useState('')
    const [filtered, setFiltered] = useState(
        [
          {
            label: "Country",
            state: "",
            key: "country",
            header: "country",
          },
          {
            label: "Grade",
            state: "",
            key: "grade",
            header: "grade",
          },
        ].filter(Boolean)
      );
      const [computePercentage , setComputePercentage] = useState()
      const [number , setNumber] = useState()
      const [country , country_id] = useState([])

     //handling what are the params that will be called on IC or MAP
    const handleparam =(potinal)=>{
      if(potinal){
        if(country.length>0){
          return `country=[${country}]`
        }else{
          return ''
        }
      }
      if(number===undefined||computePercentage===undefined){
        return ''
      }
      if(country.length>0){
          return `percentage=${computePercentage}&number_of_incorrect_answers=${number}&country=[${country}]`
      }else{
          return `percentage=${computePercentage}&number_of_incorrect_answers=${number}`
      }
    }
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    //toggle filter display
    const handleFilter = () => {
        setDisplayFiltering(!displayFiltering);
      };

    //calling endpoint (IC or MAP) with diffrent filter values
      const onChangeFiltered = value => {
        changeFiltered(value, filtered, search, '', signal, setFiltered, setParams,CheatingTable? getData:getPotinalData ,false , true )
    }

    //calling endpoint (IC or MAP) with diffrent search value
    const onChangeSearch = value => {
      changeSearch(value, search, filtered, '', signal, setSearch, setParams, CheatingTable? getData:getPotinalData , false , undefined , true)
  }


  //get MAP data
    const getPotinalData = (param)=>{
      getSameParticipants(location.search.slice(4),param,signal).then(res=>{
         //checking if the last compute resulted of no value , we show a pop up warning with this msg
         if(res.status===201&&res.message.slice(0,2)==='No'){
          showWarningSwal(String(res.message))
          setPotinalMsg('')
        }else{
          setPotinalMsg(res.message)
        }

        //getting contries that has been computed and others that hasnt , to display under search bar
        //changing state variables according to the current state of computing 
        handlePotinalResponseCases(res.status , res.message)

        //if status is 201 then data is computed and can be displayed
        if(res.status===201){
           
          let formattedheader = []
          //mapping header object to convert to array
          for (const key in res.headers) {
              if (res.headers.hasOwnProperty(key)) {
               formattedheader.push({id:res.headers[key] , label:key})
              }
            }
          //setting table header data
          setPotinalHeader(formattedheader)
          //setting filter data
          setPotinalFilterOptions(res.filter_options)
          //setting table data
          setPotinalData(res.data)
         }

         setPercentage(res.progress)
      })
    }

    //getting IAC data
    const getData = (param)=>{
      getCheaters(location.search.slice(4),param,signal).then((res)=>{
        console.log(res)
        //getting contries that has been computed and others that hasnt , to display under search bar
        setConfirmedCountries(res.computed_countries)
        setRemainingcountries(res.remaining_countries)

        //checking if the last compute resulted of no value , we show a pop up warning with this msg
        if(res.status===201&&res.message.slice(0,2)==='No'){
          showWarningSwal(String(res.message))
          setMsg('')
        }else{
          setMsg(res.message)
        }

        //changing state variables according to the current state of computing 
        handleResponseCases(res.status , res.message)
         //if status is 201 then data is computed
         if(res.status===201||res.status===200){
          let formattedheader = []
           //mapping header object to convert to array
          for (const key in res.headers) {
              if (res.headers.hasOwnProperty(key)) {
               formattedheader.push({id:res.headers[key] , label:key})
              }
            }
            //get computed contries
            setComputedConties(res.computed_countries)
            setTableHeader(formattedheader)
            setFilterOptions(res.filter_options)
            setData(res.data)
         }
          setPercentage(res.progress)
          setCompName(res.competition)
      })
     
    }
    let location = useLocation()
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    const handleResponseCases = (status,msg)=>{
        if(status===206){
            setPercentage(0)
            setCanBeComputed(true)
            setCanBeDownload(false)
            setRecallEndpoint(false)
            setMsg(msg)
        }else if(status===202){
            setCanBeComputed(false)
            setRecompute(true)
            setCanBeDownload(false)
            setRecallEndpoint(true)
            setMsg(msg)
        }else if(status===201||status===200){
            setCanBeComputed(false)
            setRecompute(true)
            setCanBeDownload(true)
            setRecallEndpoint(false)
        }else{
            setCanBeComputed(false)
            setRecompute(true)
            setCanBeDownload(false)
            setRecallEndpoint(false)
            setError(msg)
        }
     }

     const handlePotinalResponseCases = (status,msg)=>{
      if(status===206){
          setPercentage(0)
          setCanBeComputed(true)
          setCanBeDownloadedMap(false)
          setRecallEndpoint(false)
          setPotinalMsg(msg)
      }else if(status===202){
          setCanBeComputed(false)
          setRecompute(true)
          setRecomputePotinal(true)
          setCanBeDownloadedMap(false)
          setRecallEndpoint(true)
          setPotinalMsg(msg)
      }else if(status===201||status===200){
          setCanBeComputed(false)
          setRecomputePotinal(true)
          setRecompute(true)
          setCanBeDownloadedMap(true)
          setRecallEndpoint(false)
      }else{
          setCanBeComputed(false)
          setRecompute(true)
          setRecomputePotinal(true)
          setCanBeDownloadedMap(false)
          setRecallEndpoint(false)
          setError(msg)
      }
   }


     const downloadcsv = (fileName,country)=>{
       //calling download csv endpoint
       downloadCheater(location.search.slice(4) , fileName,country)     
     }
     //calling compute for IC 
     const compute = (param)=>{
        param+='&recompute=1'
        //validating that the input has a percentage and min matching number if not fire a warning
        if((number===undefined||number=='')||(computePercentage===undefined||computePercentage=='')){
          return showWarningSwal("you must select a percentage and  Number of Min Matching Incorrect Answers")
        }
       //calling iac endpoint
       getCheaters(location.search.slice(4),`?${param}`,signal).then(res=>{
        if(!Array.isArray(res)){
           handleResponseCases(res.status , res.message)
           setRecallEndpoint(true)
           setCanBeDownload(false)
           setPercentage(0)
           if(res.status!==417){
               showNotification(  "success",
               res.message,
               enqueueSnackbar,
               closeSnackbar)
           }
           onComp(false)
         }
       })
     } 

    //calling compute for MAP 
    const computePotinal = (param)=>{
     param+='&recompute=1'
      
       //calling iac endpoint
       getSameParticipants(location.search.slice(4),`?${param}`,signal).then(res=>{
         if(!Array.isArray(res)){
           handlePotinalResponseCases(res.status , res.message)
           setRecallEndpoint(true)
           setCanBeDownloadedMap(false)
           setPercentage(0)
           if(res.status!==417){
               showNotification(  "success",
               res.message,
               enqueueSnackbar,
               closeSnackbar)
           }
           onComp(false)
         }
       })
     
     }


    useEffect(()=>{
      getCountries2(`?competition_id=${location.search.slice(4)}`).then(res=>{
          setallCountryOptions(res.sort((a, b) => {
            return (a.display_name < b.display_name ? -1 : (a.display_name > b.display_name ? 1 : 0));
        }))
      })
      //calling ic and map data in the start of the render
         getData('')
         getPotinalData('')
         
   },[location.search , signal])
     
    useEffect(()=>{
      if(CheatingTable){
        if(reCallEndpoint){   
            setTimeout(() => {
            getCheaters(location.search.slice(4),`?${handleparam()}&get_data=1`,signal).then((res)=>{
              console.log(res)
              if((res.status===201||res.status===200)&&res.message.slice(0,2)==='No'){
                showWarningSwal(String(res.message))
                setMsg('')
              }else{
                setMsg(res.message)
              }
              if(res.status===201||res.status===200){

                setConfirmedCountries(res.computed_countries)
                setRemainingcountries(res.remaining_countries)
                let formattedheader = []
                //mapping header object to convert to array
               for (const key in res.headers) {
                   if (res.headers.hasOwnProperty(key)) {
                    formattedheader.push({id:res.headers[key] , label:key})
                   }
                 }
                 //get computed contries
                 setComputedConties(res.computed_countries)
                 setTableHeader(formattedheader)
                 setFilterOptions(res.filter_options)
                 setData(res.data)
              }
                setPercentage(res.progress)
                handleResponseCases(res.status , res.message)
                setReload(!reload)
                })
            }, 5000);
          }
      }else{
        if(reCallEndpoint){   
          setTimeout(() => {
          getSameParticipants(location.search.slice(4),`?${handleparam(true)}&get_data=1`,signal).then((res)=>{
            if((res.status===201||res.status===200)&&res.message.slice(0,2)==='No'){
              showWarningSwal(String(res.message))
              setPotinalMsg('')
            }else{
              setPotinalMsg(res.message)
            }
            if(res.status===201||res.status===200){
           
              let formattedheader = []
              //mapping header object to convert to array
              for (const key in res.headers) {
                  if (res.headers.hasOwnProperty(key)) {
                   formattedheader.push({id:res.headers[key] , label:key})
                  }
                }
              //setting table header data
              setPotinalHeader(formattedheader)
              //setting filter data
              setPotinalFilterOptions(res.filter_options)
              //setting table data
              setPotinalData(res.data)
             }
              setPercentage(res.progress)
              handlePotinalResponseCases(res.status , res.message)
              setReload(!reload)
              })
          }, 5000);
        }
      }
      },[ reCallEndpoint , setReload , setRecallEndpoint,reload , setCanBeDownload , canBeDownloadedMap ])
  
      const submitElem = (id  , list,reason , mode)=>{
        elemParticipants(id,{participants:list} , `?mode=${mode}`).then(res=>{
          if(res.status===200||res.status===201){
              showNotification(  "success",
              res.message,
              enqueueSnackbar,
              closeSnackbar)
              getData('')
              getPotinalData(``)
              setSelected([])
              setSelecting([])
              setOnElem(false)
              setCanBeDownload(true)
              
              setOnCustomeElem(false)
          }
        })
      }

      const submitdiElem = (id , list,mode)=>{
        dieliminate(id,{participants:list},`?mode=${mode}`).then(res=>{
          if(res.status===200||res.status===201){
              showNotification(  "success",
              res.message,
              enqueueSnackbar,
              closeSnackbar)
              getData('')
              getPotinalData(``)
              setSelected([])
              setSelecting([])
              onRestore(false)
              setCanBeDownload(true)
              setOnCustomeDeElem(false)
          }
      })
     
      }
    return <Box className='wrapperBox'>
              <Collapse in={displayFiltering}>
        { filterOptions&&PotinalfilterOptions&&(
          <Filter
            type="collapse"
            filterOptions={filtered.map((f) =>
              makeOptions(CheatingTable?filterOptions:PotinalfilterOptions, f.header)
            )}
            // filterOptions={filterOptions}
            filtered={filtered}
            onChangeFiltered={onChangeFiltered}
          />
        )}
                </Collapse>
                 <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <NunitoText value={`${compName} Integrity Check`} fontSize={40} fontWeight={700} width={'30%'} italic color='#144A94' align={'left'} marginLeft={'20px'} marginTop={'20px'} />
                <Grid display={'flex'} alignItems={'center'} >
                <ViewAndFilterButton
                  text="Filter"
                  state={displayFiltering}
                  fontSize={14}
                  height={46}
                  onClick={handleFilter}
                  marginRight={30}
                />
                
                         <ReusableButton
                          onClick={()=>{
                            setOnConfirmCountries(true)
                          }}
                          text={"Confirm/Revoke Country IAC Completion"}
                          bgColor={canBeComputed||recomute ? "#5E75C3" : "red"}
                          height={46}
                          fontSize={15}
                          marginRight={30}
                          
                          />
                          <ReusableButton
                        text="Download Csv"
                        fontSize={15}
                        bgColor={"#5E75C3"}
                        height={46}
                        marginRight={30}
                        
                        // onClick={() => computeAll()}
                        onClick={()=>{
                            setOnDownload(true)
                        }}
                        iconType="download"
                        />
                         <FaCircleQuestion
                          style={{
                            color: "#5E75C3",
                            fontSize: "45px",
                            textAlign: "center",
                            marginRight:'30px',
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setInsetructions(true);
                          }}
                        />


                </Grid>
                </Grid>
            
              <Grid display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'flex-end'} >
             { <Grid width={'35%'} className='searchAndBtnContainer' alignSelf={'flex-start'} justifyContent="space-between" >
                   {!reCallEndpoint&&<> <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={search} setState={onChangeSearch} onBlur /> 
                      { CheatingTable&& <Grid alignContent={'flex-start'} alignItems={'flex-start'}  display={'flex'} flexDirection={'column'}  textAlign={'left'} width={'100%'} style={{overflowWrap:'break-word'}} >
                  {confirmedCountries!==''&&<NunitoText align={'left'} value={`List of countries that have started integrity generation: ${confirmedCountries&&confirmedCountries.length>0?confirmedCountries:'-'}`} fontSize={20} marginBottom={10} marginTop={20} fontWeight={400} italic color='#144A94'  style={{overflowWrap:'break-word'}} width={'100%'}/>}
                  {remainingCountries!==''&&<NunitoText value={`List of countries pending integrity generation: ${remainingCountries&&remainingCountries.length>0?remainingCountries:'-'}`} fontSize={20} fontWeight={400} italic color='red'   align={'left'} style={{overflowWrap:'break-word'}} width={'100%'}/>}
                  </Grid> }</>}
                </Grid>}

                <Grid display={'flex'} flexDirection={'column'}>
                <Grid container display={'flex'} alignSelf={'flex-start'}   flexDirection={'column'}  marginBottom={'2%'}  width={'100%'}  >
                  <NunitoText value={'Identify IAC Incidents'} align={'left'} fontSize={20} fontWeight={700}  italic color='#144A94'/>
                  <Grid alignSelf={'flex-end'} display={'flex'} container>
                  <ReusableButton
                        text={"View IAC Incident cases"}
                        fontSize={15}
                        bgColor={"#5E75C3" }
                        height={46}
                        marginRight={30}
                        // onClick={() => computeAll()}
                        onClick={()=>{
                          setOnCustomeDeElem(true)
                         }}
                     
                        />
                <ReusableButton
                        text={"Create IAC Incident"}
                        fontSize={15}
                        bgColor={ "#5E75C3"}
                        height={46}
                        marginRight={30}
                        // onClick={() => computeAll()}
                        onClick={()=>{
                        setOnCustomeElem(true)
                          }}
                        iconType="banned"
                        />
                </Grid>
                </Grid>


               
                <Grid  display={'flex'} flexDirection={'column'} alignSelf={'flex-start'}   marginBottom={'2%'} width={'100%'} >
                  <NunitoText value={'Identify System Generated Integrity Cases'} align={'left'} fontSize={20} fontWeight={700}  italic color='#144A94'/>
                 
                  <Grid marginLeft={''} display={'flex'} alignSelf={'flex-start'} justifyContent={'space-between'}>
                
                  <Grid justifyContent={'space-between'} display={'flex'} >
                  <ReusableButton
                        text={"View selected integrity IAC"}
                        fontSize={15}
                        bgColor={ "#5E75C3"}
                        height={46}
                        marginRight={30}
                        // onClick={() => computeAll()}
                        onClick={()=>{setCheatingTable(true)
                          changeSearch('', search, filtered, '', signal, setSearch, setParams,  getData)
                          setParams('?')
                          setSelected([])
                        }}
                     
                        />
                          <ReusableButton
                        text={"Integrity Check Summary"}
                        fontSize={15}
                        bgColor={CheatingTable ? "#5E75C3" : "red"}
                        height={46}
                        marginRight={'2%'}
                        
                        // onClick={() => computeAll()}
                        onClick={CheatingTable?()=>{
                         onSummary(true)
                          }:() => {}}
                        iconType="stats"
                        />
                          <ReusableButton
                        text={"Select Integrity IAC"}
                        fontSize={15}
                        bgColor={CheatingTable ? "#5E75C3" : "red"}
                        height={47}
                        marginRight={'2%'}
                       
                        // onClick={() => computeAll()}
                        onClick={CheatingTable?()=>{
                          if(selected.length>0){
                            setOnElem(true)
                          }else{
                            showWarningSwal("Please select at least one participant")
                          }
                          }:() => {}}
                        iconType="banned"
                        />
                           <ReusableButton
                        text={"Remove Integrity IAC"}
                        fontSize={15}
                        bgColor={CheatingTable ? "#5E75C3" : "red"}
                        height={47}
                        marginRight={'2%'}
                        // onClick={() => computeAll()}
                        onClick={CheatingTable?()=>{
                          if(selected.length>0){
                            onRestore(true)
                          }else{
                            showWarningSwal("Please select at least one participant")
                          }
                         }:() => {}}
                        iconType="restore"
                        />

                        <ReusableButton
                        text={"Generate Integrity IAC"}
                        fontSize={15}
                        bgColor={(canBeComputed||recomute)&&CheatingTable ? "#5E75C3" : "red"}
                        height={47}
                        marginRight={30}
                        // onClick={() => computeAll()}
                        onClick={(canBeComputed||recomute)&&CheatingTable?()=>onComp(true):()=>{}}
                        iconType="compute"
                        />
              
                </Grid >
               
                </Grid>
         
                </Grid>

                <Grid  display={'flex'} flexDirection={'column'} alignSelf={'flex-start'}   marginBottom={'2%'} width={'100%'}>
                <NunitoText value={'Identify Multiple Attempts participants'} align={'left'} fontSize={20} fontWeight={700}  italic color='#144A94'/>
                <Grid  display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'}>
                  <Grid justifyContent={'flex-start'} display={'flex'} container>
                  <ReusableButton
                        text={"View selected MAP IAC"}
                        fontSize={15}
                        bgColor={ "#5E75C3" }
                        height={46}
                        marginRight={30}
                        // onClick={() => computeAll()}
                        onClick={()=>{setCheatingTable(false)
                          changeSearch('', search, filtered, '', signal, setSearch, setParams, getPotinalData)
                          setParams('?')
                          setSelected([])

                          
                        }}
                        />
                 
                        <ReusableButton
                        text={"Select MAP IAC"}
                        fontSize={15}
                        bgColor={!CheatingTable ? "#5E75C3" : "red"}
                        height={47}
                        marginRight={'2%'}
                       
                        // onClick={() => computeAll()}
                        onClick={!CheatingTable?()=>{
                          if(selected.length>0){
                            setOnElem(true)
                          }else{
                            showWarningSwal("Please select at least one participant")
                          }
                          }:() => {}}
                        iconType="banned"
                        />
                           <ReusableButton
                        text={"Remove MAP IAC"}
                        fontSize={15}
                        bgColor={!CheatingTable ? "#5E75C3" : "red"}
                        height={47}
                        marginRight={'2%'}
                        // onClick={() => computeAll()}
                        onClick={!CheatingTable?()=>{
                          if(selected.length>0){
                            onRestore(true)
                          }else{
                            showWarningSwal("Please select at least one participant")
                          }
                         }:() => {}}
                        iconType="restore"
                        />
                           <ReusableButton
                        text={"Generate MAP IAC"}
                        fontSize={15}
                        bgColor={(canBeComputed||recomute)&&!CheatingTable ? "#5E75C3" : "red"}
                        height={47}
                        marginRight={30}
                        // onClick={() => computeAll()}
                        onClick={(canBeComputed||recomute)&&!CheatingTable?()=>onComp(true):()=>{}}
                        iconType="compute"
                        />
                      
                </Grid >
               
                </Grid>
                </Grid>
                </Grid>
                </Grid>
                {<Grid className='tableContainer' container>
              
               
                <Grid display={'flex'} justifyContent={'space-between'}  flexDirection={'row'} width={'100%'} marginTop={'3%'}>
                      
                  
                
                </Grid>
                
                  {!reCallEndpoint&&canBeDownloaded&&data&&tableHeader&&data.length>0&&CheatingTable&&
                  <>
                  <NunitoText value={'Integrity Cases List'} fontSize={20} fontWeight={700}  italic color='#144A94'/>
                  <LightTable hasPagination={true}  hasSelect={true} selected={selected} selecting={selecting} setSelecting={setSelecting} setSelected={setSelected} key={1} fixed={['index_no','name','country']} data={data} headers={tableHeader} />
                  </>}
                  {!reCallEndpoint&&canBeDownloaded&&data&&tableHeader&&data.length==0&&CheatingTable&&<NoData text={'No Integrity Cases Found'}/>}
                  

                  {!reCallEndpoint&&!CheatingTable&&potinalData&&potinalHeader&&potinalData.length>0&&
                  <>
                  <NunitoText value={'Multiple Attempts Participants List'} fontSize={20} fontWeight={700}  italic color='#144A94'/>
                  <LightTable hasPagination={true}  hasSelect={true} fixed={[]} key={2} data={potinalData} headers={potinalHeader} selected={selected} selecting={selecting} setSelecting={setSelecting} setSelected={setSelected}/></>}
                 
                  </Grid>}

                  
                  {reCallEndpoint&&<CheatingBar msg={CheatingTable?msg:potinalMsg} reCallEndpoint={reCallEndpoint} percentage={percentage} handleResponseCases={handleResponseCases} handlePotinalResponseCases={handlePotinalResponseCases} setPercentage={setPercentage} id={location.search.slice(4)}/>}
                  {!reCallEndpoint&&!CheatingTable&&potinalData&&potinalHeader&&potinalData.length==0&&<NoData text={'No MAP Cases Found'}/>}
                              
                 <NunitoText value={CheatingTable?msg:potinalMsg} fontSize={20} fontWeight={700}  italic color='#144A94'/>
               
                <Modal open={onDownload} onClose={() => setOnDownload(false)} err={err}>
                    <>
                    {<CheatDownloadModal downloadcsv={downloadcsv} compName={compName}  allCountryOptions={allCountryOptions} />}
                    </>
                </Modal>
                <Modal open={comp} onClose={() => onComp(false)} >
                    <>
                    {(canBeComputed||recomute)&&<ComputeCheating CheatingTable={CheatingTable} compPotinal={computePotinal} comp={compute} onComp={onComp} setCanBeDownload={setCanBeDownload} setComputePercentage={setComputePercentage} percentage={computePercentage} allCountryOptions={allCountryOptions} number={number} setNumber={setNumber} country_id={country_id} handleparam={handleparam} country={country}/>}
                    </>
                </Modal>
                <Modal open={onElem} onClose={() => setOnElem(false)} err={err}>
                    <>
                    {<ElemParticipant CheatingTable={CheatingTable} mark={true} title={'Select Integrity IAC'} handleSubmit={submitElem}  selected={selected} setOnElm={setOnElem}  id={location.search.slice(4)}/>}
                    </>
                </Modal>
                <Modal open={customDeElem} onClose={() => setOnCustomeDeElem(false)} err={err}>
                    <>
                    {<CustomeDeelemModal title={'IAC Incident list'} handleSubmit={submitdiElem}   setOnElm={setOnCustomeDeElem}  id={location.search.slice(4)}/>}
                    </>
                </Modal>
                <Modal open={customElem} onClose={() => setOnCustomeElem(false)} err={err}>
                    <>
                    {<CustomeElemModal title={'Create IAC Incident'} handleSubmit={submitElem}   setOnElm={setOnCustomeElem}  id={location.search.slice(4)}/>}
                    </>
                </Modal>
                <Modal open={restore} onClose={() => onRestore(false)} err={err}>
                    <>
                    {<ElemParticipant CheatingTable={CheatingTable} mark={false} title={'Unmark System Generated IAC'} handleSubmit={submitdiElem}  setOnElm={onRestore}  selected={selected} id={location.search.slice(4)}/>}
                    </>
                </Modal>
                <Modal open={onConfirmCountries} onClose={()=>setOnConfirmCountries(false)} err={err}>
                          <>
                            <ConfirmCountriesModal id={location.search.slice(4)} cancelFucntion={()=>{setOnConfirmCountries(false)}} getData={getData}/>
                          </>
                </Modal>
                <Modal open={instructions} onClose={()=>setInsetructions(false)} err={err}>
                          <>
                            <CheatingListInstructionsModal/>
                          </>
                </Modal>
                <Modal open={summary} onClose={()=>onSummary(false)} err={err}>
                          <>
                            <IntegrityCheckSummaryModal title={"Integrity Check Summary"} id={location.search.slice(4)} />
                          </>
                </Modal>
           </Box>
}

