import React, { useState, useEffect, useRef } from 'react'
import { Box, Grid, Tabs, Tab } from '@mui/material'
import { showWarningSwal, showInfoSwal } from '../../functions/alert'
import { addCompetitionOverallAward } from '../../functions/postData'
import CloseIcon from '@mui/icons-material/Close'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
const EachCriteria = ({ count, competition, criteriaCount, criteria, updateForm }) => {
   const criteriaOptions = i => competition.rounds[i].rounds_awards.filter(a => a.label !== '').map(a => ({ id: a.id, name: a.name }))
   const options = criteriaOptions(criteriaCount)
   useEffect(() => {
      if (!options.find(o => o.id === criteria) && criteria !== null) {
         updateForm([count, criteriaCount], 'criterias', null)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [options])
   return (
      <ReusableAutocompleteWithID type='withLabel' placeholder={`Round ${criteriaCount + 1} Criteria*`} width={320}
         bgColor='#FFF' borderColor='#707070' marginRight={30} state={criteria} setState={updateForm}
         count={[count, criteriaCount]} target='criterias' options={options.map(g => ({ id: g.id, option: g.name }))} required />
   )
}
const centerFlexStyle = { display: 'flex', alignItems: 'center' }
const formContainerStyle = { paddingInline: 46, paddingBottom: 20 }
function EachForm({ forms, setForms, count, competition }) {
   const { label, percentage, criterias } = forms[count]
   const { award_type } = competition
   const updateForm = (count, identifier, value) => {
      let newForms = [...forms]
      if (identifier === 'criterias') newForms[count[0]][identifier][count[1]] = value
      else newForms[count][identifier] = value
      setForms(newForms)
   }
   return (
      <Grid container style={formContainerStyle}>
         <Grid container justifyContent='space-between'>
            <div style={centerFlexStyle}>
               <ReusableTextField type='withLabel' placeholder='Label*' state={label} count={count} target='label'
                  setState={updateForm} marginRight={30} required />
               {!award_type && <ReusableTextField type='withLabel' placeholder='Percentage*' state={percentage}
                  count={count} target='percentage' setState={updateForm} required number />}
            </div>
         </Grid>
         {criterias.map((criteria, i) => (
            <EachCriteria key={i} count={count} competition={competition} criteriaCount={i} criteria={criteria}
               updateForm={updateForm} />
         ))}
      </Grid>
   )
}
const boxStyle = { width: 1500, maxHeight: '90%' }
const addAwardButtonDivStyle = { display: 'flex', justifyContent: 'flex-end', marginTop: -45, marginRight: 20 }
const divStyle = {
   borderRadius: 10, margin: '20px 20px 0 20px',
   boxShadow: '0px 1px 6px rgb(0,0,0,0.2), 0 1px 6px rgb(0,0,0,0.2)'
}
const tabPropsStyle = { height: 0 }
const tabStyle = (value, i) => ({
   color: value !== i && '#707070',
   backgroundColor: value !== i && '#E7E6E6',
   border: value === i && '1px solid #F0F0F0',
   boxShadow: value === i && '0px 1px 6px rgb(0,0,0,0.2), 0px 1px 6px rgb(0,0,0,0.2)',
   borderRadius: '10px 10px 0 0',
   textTransform: 'none',
   marginRight: 10, height: 40
})
const iconStyle = { position: 'relative', left: 12, height: 18, width: 18, color: '#707070' }
const buttonsContainerStyle = { paddingInline: 60, marginBlock: 30 }
export default function AddOverallAwardModal({ setLoading, competition, cancelAddOverallAward, afterAddingOverallAward }) {
   const isMounted = useRef(false)
   const [forms, setForms] = useState([{
      id: 1, label: '', percentage: '', criterias: competition.rounds.map(() => '')
   }])
   const { award_type, min_points, default_award_name } = competition
   const [value, setValue] = useState(0)
   const addForm = () => {
      if (forms.length >= 12) return showInfoSwal('Maximum number of tab is 12')
      setForms([...forms, {
         id: forms.map(f => f.id).reduce((a, b) => Math.max(a, b), 0) + 1,
         label: '', percentage: '', criterias: competition.rounds.map(() => '')
      }])
      setValue(forms.length)
   }
   const deleteForm = (e, id) => {
      e.stopPropagation()
      let newForms = forms.filter(f => f.id !== id)
      if (!newForms.length) return cancelAddOverallAward()
      let index = forms.findIndex(f => f.id === id)
      if (value === forms.length - 1 || index < value) setValue(value - 1)
      setForms(newForms)
   }
   const handleChange = (e, newValue) => setValue(newValue)
   const onSubmit = () => {
      let payload = { competition_id: competition.id, award_type, min_points, default_award_name, award: [] }
      let msg = ''
      for (let i = 0; i < forms.length; i++) {
         const { label, percentage, criterias } = forms[i]
         let warningMessage = ''
         if (label === '') warningMessage += 'Please fill in award label.<br>'
         if (percentage === '') warningMessage += 'Please fill in percentage.<br>'
         for (let j = 0; j < criterias.length; j++) {
            const criteria = criterias[j]
            if (criteria === '') warningMessage += `Award ${i + 1}'s Round ${j + 1} criteria is empty.<br>`
         }
         if (warningMessage) {
            msg += '<div>'
            msg += `<div style="text-align:center; font-weight: 800">Error in Award ${i + 1}</div>`
            msg += `<div style="text-align:left">${warningMessage}</div>`
            msg += '</div>'
         } else {
            let obj = {
               name: label, percentage, round_criteria: criterias
            }
            payload.award.push(obj)
         }
      }
      if (msg) return showWarningSwal(msg)
      setLoading(true)
      addCompetitionOverallAward(payload).then(d => {
         isMounted.current && setLoading(false)
         afterAddingOverallAward(cancelAddOverallAward, d)
      }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
   }
   useEffect(() => {
      isMounted.current = true
      return () => isMounted.current = false
   }, [])
   return (
      <Box className='popUpModal horizontalScrollable' style={boxStyle}>
         <div>
            <NunitoText value='ADD OVERALL AWARDS' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
            <div style={addAwardButtonDivStyle}>
               <ReusableButton text='Add another award' fontSize={15} height={40} bgColor='#5E75C3'
                  iconType='add' onClick={() => addForm()} allowSpam />
            </div>
         </div>
         <div style={divStyle}>
            <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: tabPropsStyle }}
               variant="scrollable" scrollButtons>
               {forms.map((f, i) => <Tab key={f.id} label={<div style={centerFlexStyle}>
                  Award {i + 1}
                  <CloseIcon style={iconStyle} onClick={e => deleteForm(e, f.id)} />
               </div>} style={tabStyle(value, i)} />)}
            </Tabs>
            <EachForm forms={forms} setForms={setForms} count={value} competition={competition} />
         </div>
         <Grid container justifyContent='space-evenly' style={buttonsContainerStyle}>
            <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
               onClick={() => cancelAddOverallAward()} />
            <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
               onClick={() => onSubmit()} />
         </Grid>
      </Box>
   )
}