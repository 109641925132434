import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import NunitoText from "../components/general/NunitoText";
import {BiCheckCircle} from 'react-icons/bi'
import { pdfFromReact } from "generate-pdf-from-react-html";
import {IoMdPerson } from 'react-icons/io'
import { IoCloseCircleOutline} from 'react-icons/io5'
import {FaGraduationCap} from 'react-icons/fa'
import html2canvas from 'html2canvas'
import {jsPDF} from 'jspdf'
import {AiFillFlag} from 'react-icons/ai'
import "../css/datatable.css"
import { makeSureIsArray } from "../functions/general";
function StudentAnswers({questions}){
    return <Grid container marginTop={'10px'} alignItems={'center'} display={'grid'} width={'100%'} gridTemplateColumns={'auto auto auto auto auto'} columnGap={'35px'} rowGap={'15px'}>
        {makeSureIsArray(questions).map((el , index)=>{
            return <Grid display={'flex'} flexDirection={'row'} width={'100%'} alignItems={'center'} justifyContent={'space-between'}  padding={'20px'} backgroundColor={'#dddddd'} borderRadius={'35px'}>
            <NunitoText value={`Q${index+1}`} fontSize={20} fontWeight={700}  color='#555' align={'left'}></NunitoText>
            <NunitoText value={el?'Correct':'Wrong'} fontSize={20} fontWeight={700}  color='#000' align={'center'}></NunitoText>
           {el?<BiCheckCircle style={{color:'#144A94' , fontSize:'20px'}}/>: <IoCloseCircleOutline style={{color:'red' , fontSize:'20px'}}/>}  
            </Grid>
        })}
    </Grid>
}
function StudentAnswersTopics({topics}){
    return <Grid container marginTop={'15px'} display={'grid'} width={'100%'} gridTemplateColumns={'auto auto auto auto auto'} columnGap={'35px'} rowGap={'15px'}>
        {topics.map((el)=>{
            return <Grid display={'flex'} flexDirection={'column'} justifyContent={'space-between'}  width={'100%'} padding={'20px'} backgroundColor={'#144A94'} borderRadius={'25px'}>
            <NunitoText value={el.topic} fontSize={30} fontWeight={700}  color='#fff' align={'left'} marginBottom={'10px'}></NunitoText>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <NunitoText value={`${el.participant}%`} fontSize={30} fontWeight={700}  color='#fff' align={'left'}></NunitoText>
               <IoMdPerson style={{fontSize:'30px', color:'#fff'}}/>
            </Grid>
            <hr width={'100%'}></hr>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <NunitoText value={`${el.school}%`} fontSize={20} fontWeight={700}  color='#fff' align={'left'}></NunitoText>
                <FaGraduationCap style={{fontSize:'30px', color:'#fff'}}/>
            </Grid>
            <hr width={'100%'}></hr>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <NunitoText value={`${el.country}%`} fontSize={20} fontWeight={700}  color='#fff' align={'left'}></NunitoText>
                <AiFillFlag style={{fontSize:'30px', color:'#fff'}}/>
            </Grid>
            
            </Grid>
        })}
       
    </Grid>
}

function DataTable({data , headers , isgrade}){

        const table =isgrade?data.map((el)=>{
            return <tr>
                <td>{el.topic}</td>
                <td>{el.participant_score}</td>
                <td>{el.school_range}</td>
                <td>{(el.school_average).toFixed(2)}</td>
            </tr>
        }):data.map((el)=>{
            return <tr>
                <td>{el.question}</td>
                <td>{el.topic}</td>
                <td>{el.is_correct?'Yes':'No'}</td>
                <td>{el.correct_in_school}</td>
                <td>{el.correct_in_country}</td>
                <td>{el.diffculty}</td>
            </tr>
        })
        
    return <table className="datatable">
        
        <tr>
            {headers.map((el,index)=>{
                return<th>{el}</th>
            })}
          
        </tr>
        {table}
    </table> 
}
export default function Indvedual(){
  let report = JSON.parse(localStorage.getItem("report"))
    document.title='indevidual report'
    const generateReport = ()=>{
      const input = document.getElementById('report')
      html2canvas(input).then(canvas => {
        let dataURL = canvas.toDataURL('image/png');
  
     
          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "in",
            format: [(input.offsetHeight*0.010417)+1, (input.offsetWidth*0.010417)+1]
          });
  console.log(input.clientHeight ,input.offsetWidth )
          pdf.addImage(dataURL, 'PNG', .6, .6);
          pdf.save(`${report.general_data.particiapnt}.pdf`);
  
        
      });
    }
    
return <Box className='wrapperBox'>
    {report&&<Grid container style={{padding:'30px' }} id={'report'} className="printable">
        <Grid width={'100%'}>
        <NunitoText value={report.general_data.competition} fontSize={40} fontWeight={700} italic color='#144A94'></NunitoText>
        <NunitoText value='Individual student Report' fontSize={30} fontWeight={700} italic color='#144A94'></NunitoText>
        <hr></hr>
        </Grid>
       
        <Grid container style={{marginTop:'20px' , width:'100%' ,}}>
                <Grid width={'20%'} marginBottom={'15px'}>
                  <Grid display={'flex'} alignItems={'center'}  marginBottom={'20px'} justifyContent={'space-between'}>
                <NunitoText value={report.general_data.particiapnt} fontSize={30} fontWeight={700} italic color='#144A94' align={'left'}></NunitoText>
                <Button style={{'fontSize':'15px' , 'border':'1px solid' , 'borderRadius':'18px'}} onClick={()=>generateReport()}>Pdf download</Button>
                </Grid>
                <Grid flexDirection={'row'} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                    <Grid style={{textAlign:'left' , width:'45%'}}>
                    <NunitoText value='School' fontSize={20} fontWeight={700}  color='#999' align={'left'}></NunitoText>
                    <NunitoText value={report.general_data.school} fontSize={20} fontWeight={700}  color='black'  align={'left'}></NunitoText>
                    </Grid>
                    <Grid style={{textAlign:'left' , width:'45%'}}>
                    <NunitoText value='Grade' fontSize={20} fontWeight={700}  color='#999'  align={'left'}></NunitoText>
                    <NunitoText value={report.general_data.grade} fontSize={20} fontWeight={700}  color='black'  align={'left'}></NunitoText>
                    </Grid>
                </Grid>
                </Grid>
                <Grid width={'100%'} marginBottom={'35px'}>
                <NunitoText value='Performance by Questions' fontSize={25} fontWeight={700}  color='black'  align={'left'}></NunitoText>
                <NunitoText value='Table belowshows which questions student got correct.' fontSize={20} fontWeight={700}  color='#999'  align={'left'}></NunitoText>
                <StudentAnswers questions={report.performance_by_questions}/>
                </Grid>
                <Grid width={'100%'} marginBottom={'35px'}>
                <NunitoText value='Performance by Topics' fontSize={25} fontWeight={700}  color='black'  align={'left'}></NunitoText>
                <NunitoText value="Percentage on top:Student's average by topics | Percentage middle:School average by topics | Percentage below:National average by topics" fontSize={20} fontWeight={700}  color='#999'  align={'left'}></NunitoText>
                <StudentAnswersTopics topics={report.performance_by_topics}/>
                </Grid>
                <Grid width={'100%'} marginBottom={'35px'}>
                <NunitoText value='Grade Performance Analysis' fontSize={25} fontWeight={700}  color='black'  align={'left'}></NunitoText>
                <DataTable data={report.grade_performance_analysis} headers={['Topic' , 'Your Score','SchoolRange','Average']} isgrade={true}/>
                </Grid>
                <Grid width={'100%'}>
                <NunitoText value='Grade Performance Analysis' fontSize={25} fontWeight={700}  color='black'  align={'left'}></NunitoText>
                <DataTable data={report.analysis_by_questions} headers={['Question','Topic','Answer Correct','% Correct in Your School' ,'% Correct in Your Country' ,'Level of Difficulty']} isgrade={false}/>
                </Grid>
        </Grid>
    </Grid>}
</Box>
}