import React from "react";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CloseIcon from "@mui/icons-material/Close";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import NunitoText from "../general/NunitoText";
import LightTooltip from "../general/LightTooltip";
const taskDivStyle = (length, taskIndex) => ({
  borderRadius: 10,
  height: 50,
  backgroundColor: "#A9ABD3",
  color: "#FFF",
  marginBlock: 10,
  marginInline: length > 1 && `${!taskIndex ? "15px" : "0"} 15px`,
  paddingInline: 18,
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});
const removeIconButtonStyle = { width: 26, marginLeft: 10 };
const addIconButtonStyle = (taskIndex) => ({
  width: 26,
  marginLeft: taskIndex <= 0 && 10,
});
const iconStyle = { color: "#FFF" };
const Item = SortableElement(
  ({
    value,
    groupIndex,
    taskIndex,
    length,
    setOnAddTask,
    update,
    count,
    sectionCount,
    setGroupCount,
    setTaskCount,
    prevSectionsTaskLength,
  }) => {
    return (
      <LightTooltip
        title={
          <Link to={`/edittask?id=${value.id}`} target="_blank">
            {value.task_title}
          </Link>
        }
        placement="top-start"
      >
        <div style={taskDivStyle(length, taskIndex)}>
          <NunitoText
            value={`Task ${groupIndex + 1 + prevSectionsTaskLength}${
              length > 1 && `.${taskIndex + 1}`
            }`}
            fontSize={14}
            fontWeight={700}
          />
          {taskIndex > 0 && (
            <IconButton
              style={removeIconButtonStyle}
              onClick={() =>
                update(
                  [count, sectionCount, groupIndex, taskIndex],
                  "deleteTaskFromGroup"
                )
              }
            >
              <RemoveCircleIcon
                style={iconStyle}
                onMouseDown={(e) => e.stopPropagation()}
              />
            </IconButton>
          )}
          <IconButton
            style={addIconButtonStyle(taskIndex)}
            onClick={() => {
              setTaskCount(taskIndex);
              setGroupCount(groupIndex);
              setOnAddTask(true);
            }}
          >
            <AddCircleIcon
              style={iconStyle}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </IconButton>
        </div>
      </LightTooltip>
    );
  }
);
const itemListContainerStyle = { display: "flex", alignItems: "center" };
const ItemList = SortableContainer(
  ({
    taskIDs,
    groupIndex,
    setOnAddTask,
    update,
    count,
    sectionCount,
    setGroupCount,
    setTaskCount,
    prevSectionsTaskLength,
  }) => {
    return (
      <div style={itemListContainerStyle}>
        {taskIDs.map((value, index) => (
          <Item
            level={1}
            key={`item-${index}`}
            index={index}
            value={value}
            groupIndex={groupIndex}
            taskIndex={index}
            length={taskIDs.length}
            setOnAddTask={setOnAddTask}
            update={update}
            count={count}
            sectionCount={sectionCount}
            setGroupCount={setGroupCount}
            setTaskCount={setTaskCount}
            prevSectionsTaskLength={prevSectionsTaskLength}
          />
        ))}
      </div>
    );
  }
);
const wrapperStyle = {
  display: "flex",
  alignItems: "center",
  marginTop: 10,
  height: 80,
};
const itemListDivStyle = {
  border: "1px solid #707070",
  borderRadius: 12,
  boxShadow: "0 0 3px #9E9E9E",
  backgroundColor: "#F2F2F2",
  display: "flex",
};
const cancelStyle = (value) => ({
  fontSize: 28,
  color: "#FFF",
  backgroundColor: "#E83D4D",
  cursor: "pointer",
  borderRadius: 16,
  border: "1px solid #707070",
  padding: 3,
  marginLeft: -12,
  position: "relative",
  bottom: value.length > 1 ? 30 : 20,
});
const iconButtonStyle = { height: 24, width: 24 };
const addIconStyle = { fontSize: 28 };
const Group = SortableElement(
  ({
    value,
    groupIndex,
    setOnAddTask,
    update,
    count,
    sectionCount,
    setGroupCount,
    setTaskCount,
    prevSectionsTaskLength,
  }) => {
    return (
      <div style={wrapperStyle}>
        <div style={value.length > 1 ? itemListDivStyle : { display: "flex" }}>
          <ItemList
            taskIDs={value}
            groupIndex={groupIndex}
            axis="x"
            pressDelay={200}
            setOnAddTask={setOnAddTask}
            update={update}
            count={count}
            sectionCount={sectionCount}
            setGroupCount={setGroupCount}
            setTaskCount={setTaskCount}
            prevSectionsTaskLength={prevSectionsTaskLength}
            onSortEnd={(e) =>
              update(
                [count, sectionCount, groupIndex],
                "sortTask",
                "tasks",
                "",
                e
              )
            }
          />
        </div>
        <CloseIcon
          onMouseDown={(e) => e.stopPropagation()}
          style={cancelStyle(value)}
          onClick={() =>
            update(
              [count, sectionCount, groupIndex],
              "deleteTaskGroup",
              "tasks"
            )
          }
        />
        <IconButton
          style={iconButtonStyle}
          onClick={() => {
            setGroupCount(groupIndex);
            setOnAddTask(true);
          }}
        >
          <AddCircleIcon
            style={addIconStyle}
            onMouseDown={(e) => e.stopPropagation()}
          />
        </IconButton>
      </div>
    );
  }
);
const divStyle = {
  backgroundColor: "lightgray",
  display: "contents",
  width: 1460,
};
const TaskGroupList = SortableContainer(
  ({
    tasks,
    setOnAddTask,
    update,
    count,
    sectionCount,
    setGroupCount,
    setTaskCount,
    prevSectionsTaskLength,
  }) => {
    return (
      <div style={divStyle}>
        {tasks.map((tasks, index) => (
          <Group
            key={`task-group-${index}`}
            index={index}
            value={tasks}
            groupIndex={index}
            setOnAddTask={setOnAddTask}
            update={update}
            count={count}
            sectionCount={sectionCount}
            setGroupCount={setGroupCount}
            setTaskCount={setTaskCount}
            prevSectionsTaskLength={prevSectionsTaskLength}
          />
        ))}
      </div>
    );
  }
);
export default TaskGroupList;
