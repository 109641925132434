import React from 'react'
import { Grid, IconButton } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SettingsIcon from '@mui/icons-material/Settings'
import BuildIcon from '@mui/icons-material/Build'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ForumIcon from '@mui/icons-material/Forum'
import { FaUser, FaTrophy } from 'react-icons/fa'
import NunitoText from './NunitoText'
export default function TitleBar({ title, state, setState, children, main, info, fixed }) {
    let icon;
    const iconStyle = { color: '#FFF', marginLeft: 10, fontSize: 24 }
    switch (title) {
        case 'Setting':
        case 'Answer Setting':
            icon = <SettingsIcon style={iconStyle} />; break;
        case 'Recommendations': icon = <ThumbUpAltIcon style={iconStyle} />; break;
        case 'Participating Countries':
        case 'Country Partners': icon = <FaUser style={iconStyle} />; break;
        case 'Rounds': icon = <BuildIcon style={iconStyle} />; break;
        case 'Awards': icon = <FaTrophy style={iconStyle} />; break;
        case 'Sections': icon = <ForumIcon style={iconStyle} />; break
        default:
    }
    const containerStyle = {
        backgroundColor: '#144A94', borderRadius: '10px 10px 0 0', height: 60,
        marginTop: 20, marginInline: !main && 30
    }
    const centerFlexStyle = { display: 'flex', alignItems: 'center' }
    return (
        <Grid container alignItems='center' justifyContent='space-between' style={containerStyle}>
            <div style={centerFlexStyle}>
                {title && <NunitoText value={title} fontSize={25} fontWeight={800} italic color='#FFF' marginLeft={50} />}
                {icon}
                {info}
            </div>
            <div style={centerFlexStyle}>
                {children}
                {!fixed && <IconButton style={{ color: '#FFF' }} onClick={() => setState(!state)} edge="start">
                    {state ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>}
            </div>
        </Grid>
    )
}