import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { Box, Grid, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import AssignMarksTable from '../components/table/AssignMarksTable'
import { useLocation } from 'react-router-dom'
import { makeSureIsArray } from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getDifficultyAndPoints } from '../functions/getData'
import { patchDifficultyAndPoints } from '../functions/patchData'
import { showNotification } from '../functions/snackbar'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ReusableAutocomplete from '../components/general/ReusableAutocomplete'
import ReusableAutocompleteWithID from '../components/general/ReusableAutocompleteWithID'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import Widget from '../components/general/Widget'
// import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
// import { useSnackbar } from 'notistack'
import { isAdmin } from '../functions/checkrole'
import { useSnackbar } from 'notistack'
import ConfirmVerfiy from '../components/modal/ConfrimVerfiy'
import { verfiyDiffPoints } from '../functions/postData'
const massAssignDivStyle = { padding: 10, border: '1px solid #707070', borderRadius: 10, marginRight: 20 }
const divStyle = { display: 'flex', alignItems: 'center', border: '1px solid #707070', borderRadius: 10, padding: 10 }
const massAssignPointsDivStyle = { display: 'flex', flexDirection: 'column', alignItems: 'center' }
const headers = [
    { id: 'name', label: 'Task Name', key: 'name', },
    { id: 'answer_structure', label: 'Open-Ended Task', key: 'address' },
    { id: 'task_difficulty', label: 'Difficulty', key: 'postal' },
    { id: 'section', label: 'Section', key: 'phone' },
    { id: 'task_marks', label: 'Points(Correct)', key: 'province' },
    { id: 'task_wrong', label: 'Points(Wrong)', key: 'email' },
    { id: 'task_blank', label: 'Points(Blank)', key: 'province' },
    { id: 'min_marks', label: 'Minimum Points', key: 'email' },
    { id: 'marks', label: 'Maximum Points', key: 'email' }
]
export default function VerfiyAssign() {
    document.title = 'Verfiy Difficulty'
    const isMounted = useRef(false)
    const [competitionName, setCompetitionName] = useState('')
    const [competitionID, setCompetitionID] = useState('')
    const [collectionName, setCollectionName] = useState('')
    const [roundName, setRoundName] = useState('')
    const [levelID, setLevelID] = useState('')
    const [roundid , setroudid] = useState('')
    const [collectionid , setcollectionid] = useState('')
    const [levelName, setLevelName] = useState('')
    const [taskTable, setTaskTable] = useState()
    const [verfiy , setConfirmVerfiy] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState([])
    const [search, setSearch] = useState('')
    const [sectionOptions, setSectionOptions] = useState([])
    const [verified , isverf]= useState()
    const [difficultyOptions, setDifficultyOptions] = useState([])
    const [sections, setSections] = useState([])
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    let location = useLocation()
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const fuckLah = (query, competition) => {
        setCompetitionName(competition.competition_name)
        setCompetitionID(competition.id)
        const [competition_id, round_id, level_id, collection_id] = query
     
        const round = competition.rounds.find(r => r.id === Number(round_id))
        setRoundName(round.name)
        const level = round.levels.find(l => l.id === Number(level_id))
        setLevelID(level.id)
        setLevelName(level.name)
        setroudid(round_id)
        setcollectionid(collection_id)
        const collection = level.collections
        setCollectionName(collection.name)
        let arr = []
        let sectionsArr = []
        for (let i = 0; i < collection.section.length; i++) {
            const section = collection.section[i]
            sectionsArr.push({ id: i + 1, name: `Section ${i + 1}` })
            for (const task of section) {
                let obj = task
                obj.task_difficulty = obj.task_difficulty ?? ''
                obj.section = i + 1
                arr.push(obj)
            }
        }
        setSectionOptions(sectionsArr)
        setSections(sectionsArr.map(s => s.id))
        setTaskTable(arr)
    }
   
    const updateTaskTable = (count, target, value) => {
        let newTaskTable = window.structuredClone(taskTable)
        if (['min_marks', 'marks'].includes(target)) {
            newTaskTable[count[0]].task_marks[count[1]][target] = value
        } else newTaskTable[count][target] = value
        if (['task_difficulty'].includes(target) && newTaskTable[count].answer_structure !== 'open' && value !== '') {
            let difficulty = difficultyOptions.find(d => d.name === value)
            if(difficulty){
                newTaskTable[count].task_marks&&newTaskTable[count].task_marks.forEach(a => a.marks = difficulty.correct_marks)
            newTaskTable[count].task_wrong = difficulty.wrong_marks
            newTaskTable[count].task_blank = difficulty.blank_marks}
        }
        setTaskTable(newTaskTable)
    }
    const getData = useCallback(signal => {
        setLoadingTable(true)
        let query = location.search.slice(1).split('&').map(q => q.split('=')[1])
        getDifficultyAndPoints(`?competition_id=${query[0]}&round_id=${query[1]}&level_id=${query[2]}`, signal).then(d => {
            console.log(d , 'res')
            setDifficultyOptions(d.difficultyList.difficulty)
            fuckLah(query, d.competitionTask)
            isverf(d.is_verified)
            setFirstLoaded(false)
            setLoadingTable(false)
        }).catch(e => {
            if (e.name !== 'AbortError') {
                setLoadingTable(false)
                setFirstLoaded(false)
            }
            console.log(e)
        })
    }, [location.search])
    useEffect(() => {
        isMounted.current = true
        let queries = location.search.slice(1).split('&')
        // if (!(['competition_id', 'round_id', 'level_id','collection_id'].every((el, i) => el === queries[i].split('=')[0]) && queries.length === 4)) {
        //     return showWarningSwal('Invalid address')
        // }
        getData(signal)
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [location.search, controller, signal, getData])
    const verf = ()=>{
        let payload = {
            competition_id: Number(competitionID),
            round_id: Number(roundid),
            level_id: Number(levelID),
            collection_id: Number(collectionid)
        }
        verfiyDiffPoints(payload).then(res=>{
            if(res.status===200||res.status===201){
                showNotification('success', res.message, enqueueSnackbar, closeSnackbar)
                getData(signal)
            }else{
                showWarningSwal(res.message)
            }
            setConfirmVerfiy(false)
        })
    }

    return (
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
            <div className='firstRowContainer dashboardAndSelfBtnDiv'>
                <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                <ChevronRightIcon />
                <ReusableButton text='Verfiy Difficulty' fontSize={14} bgColor='#F16774' height={36} br={18} />
            </div>
            <Grid className='tableContainer' container>
                <NunitoText value='Verfiy Difficulty and Points' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid container>
                    <Widget title='Competition' content={competitionName} marginRight={28} />
                    <Widget title='Collection' content={collectionName} marginRight={28} />
                    <Widget title='Round' content={roundName} marginRight={28} />
                    <Widget title='Level' content={levelName} marginRight={28} />
                </Grid>
             
               
                {loadingTable && <Loader height={600} />}
                {!loadingTable && taskTable && !!makeSureIsArray(taskTable).length && <><AssignMarksTable headers={headers} isverfiy={true}
                    data={taskTable} error search={search}
                    sections={sections} selected={selected} setSelected={setSelected} setState={updateTaskTable}
                    difficultyOptions={difficultyOptions}
                    fixed={['name', 'answer_structure', 'task_difficulty', 'section']}
                />
                {/* {!loadingTable && firstLoaded && userTable && !makeSureIsArray(userTable.data).length && <NoData height={600} />} */}
               <Grid display={'flex'} width={'94%'} justifyContent="flex-end" >
               <ReusableButton text={verified?'verified':'verfiy'} readonly={verified} bgColor={verified?'#999':'#5E75C3'} fontSize={16} marginTop={'1%'} onClick={()=>verified?{}:setConfirmVerfiy(true)}></ReusableButton>
               </Grid></>}
               <Modal open={verfiy} onClose={()=>{setConfirmVerfiy(false)}}>
                  <ConfirmVerfiy confirmFunction={verf} cancelConfirm={()=>(setConfirmVerfiy(false))} object={'the current Diffeculty points for '} value={collectionName}></ConfirmVerfiy>
               </Modal>
            </Grid>
        </Box >
    )
}