import React, { useState, useEffect, useRef } from 'react'
import { Box, Grid } from '@mui/material'
import { checkPassword, makeSureIsArray } from '../../functions/general'
import { showWarningSwal, isEmpty, warningMessage } from '../../functions/alert'
import { getSchools, getOrganizations } from '../../functions/getData'
import { patchUser } from '../../functions/patchData'
import { useSelector } from 'react-redux'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
import { isAdmin, isManagerOrTeacher } from '../../functions/checkrole'
const boxStyle = { width: 1200, minHeight: 600, maxHeight: '90%' }
const gridContainerStyle = { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
const firstRowStyle = { ...gridContainerStyle, marginTop: 20, marginBottom: 20, paddingInline: 100 }
const rowStyle = { ...gridContainerStyle, marginBottom: 20, paddingInline: 100 }
const buttonsContainerStyle = { ...gridContainerStyle, marginBlock: '10px 30px', paddingInline: 230 }
export default function EditUserModal({ setLoading, table, id, afterEditingUser, cancelEdit }) {
   const isMounted = useRef(false)
   const editingUser = makeSureIsArray(table.data).find(u => u.id === id)
   const [name, setName] = useState(editingUser?.name || '')
   const role = editingUser?.role_id || ''
   const [username, setUsername] = useState(editingUser?.username || '')
   const [password, setPassword] = useState('')
   const [confirmPassword, setConfirmPassword] = useState('')
   const [phone, setPhone] = useState(editingUser?.phone || '')
   const [email, setEmail] = useState(editingUser?.email || '')
   const [country, setCountry] = useState(editingUser?.country_id || '')
   const [organization, setOrganization] = useState(editingUser?.organization_id || '')

   const [teacherBelongsToTuitionCentre, setTeacherBelongsToTuitionCentre] = useState(false)

   const user = useSelector(state => state.user)
   const countryOptions = useSelector(state => state.countryOptions)
   const [organizationOptions, setOrganizationOptions] = useState([
      { id: editingUser?.organization_id, name: editingUser?.organization_name }
   ])
   useEffect(() => {
      isMounted.current = true
      const controller = new AbortController()
      const signal = controller.signal
      if (isAdmin() && role === 2) {
         getOrganizations('?status=active&limits=100', signal).then(o => {
            setOrganizationOptions(o.OrganizationLists.data.map(d => ({ id: d.id, name: d.name })))
         }).catch(e => console.log(e))
      }
      if (isManagerOrTeacher()) {
         getSchools(`?status=active&country_id=${country}&limits=100&search=${editingUser.school_name}`, signal).then(d => {
            setTeacherBelongsToTuitionCentre(makeSureIsArray(d.SchoolLists.data).find(s => s.id === editingUser.school_id).private)
         }).catch(e => console.log(e))
      }
      return () => {
         controller.abort()
         isMounted.current = false
      }
   }, [editingUser, role, country])
   const original = {
      name: editingUser.name || '', username: editingUser.username || '', email: editingUser.email || '',
      phone: editingUser.phone || '', organization: editingUser.organization_id || '', country: editingUser.country_id || ''
   }
   const current = {
      name, username, email, phone, organization, country
   }
   const unChanged = JSON.stringify(original) === JSON.stringify(current) && password === ''
   const onSubmit = () => {
      if (unChanged) return showWarningSwal('Please make some changes before submitting')
      let arr = [
         { name: 'Full Name', state: name },
         { name: 'Username', state: username },
         { name: 'Email', state: email },
         { name: 'Phone', state: phone }
      ]
      if (isEmpty(arr.map(a => a.state))) return warningMessage(arr)
      if (password !== '') {
         if (checkPassword(password)) return showWarningSwal('Password does not meet requirement')
         if (password !== confirmPassword) return showWarningSwal('Password and Confirm Password must match')
      }
      let payload = {
         // id, // not required if edit own info
         name, username, about: "xxxEdited", phone
      }
      if (user.user_id !== id) payload.id = id
      if (email !== editingUser.email) payload.email = email
      if (role === 2) {
         if (country !== editingUser.country_id) payload.country_id = country
         if (organization !== editingUser.organization_id) payload.organization_id = organization
      }
      if (password !== '') {
         payload.password = password
         payload.password_confirmation = confirmPassword
      }
      setLoading(true)
      patchUser(payload).then(d => {
         isMounted.current && setLoading(false)
         afterEditingUser(cancelEdit, d)
      }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
   }
   return (
      <Box className='popUpModal' style={boxStyle}>
         <div>
            <NunitoText value='EDIT USER' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
            <Grid container justifyContent="space-between" style={firstRowStyle}>
               <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Full Name*'
                  state={name} setState={setName} required={!name} />
               <ReusableAutocompleteWithID type='withLabel' width={435} height={55} btnWidth={63} btnBgColor='#144A94'
                  borderColor='#707070' placeholder='Role' state={1} grayedOut readOnly
                  options={[{ id: 1, option: editingUser.role_name }]} />
            </Grid>
            <Grid container justifyContent="space-between" style={rowStyle}>
               <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Username*'
                  state={username} setState={setUsername} required />
               <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='E-Mail*'
                  state={email} setState={setEmail} required />
            </Grid>
            <Grid container justifyContent="space-between" style={rowStyle}>
               <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Password'
                  state={password} setState={setPassword} />
               <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Confirm Password'
                  state={confirmPassword} setState={setConfirmPassword}
                  required={password !== '' && (checkPassword(password) || password !== confirmPassword)} />
            </Grid>
            {password !== '' && <>
               <NunitoText value='Minimum 8 characters with one uppercase and lowercase letter and 1 of the following 
                letters: "!$#%@"'  fontSize={16} fontWeight={400} marginTop={10} color={checkPassword(password) && '#E83D4D'} />
               <NunitoText value='Password must match confirm password' fontSize={16} fontWeight={400} marginTop={10}
                  color={(checkPassword(password) || password !== confirmPassword) && '#E83D4D'} />
            </>}
            <Grid container alignItems='center' style={rowStyle}>
               <ReusableTextField type='withLabel' width={435} height={60} bgColor='#F2F2F2'
                  placeholder={`Phone Number${country && `(+${countryOptions.find(c => c.id === country)?.Dial})`}*`}
                  state={phone} setState={setPhone} required />
            </Grid>
            {role > 1 && <>
               <Grid container justifyContent="space-between" style={rowStyle}>
                  <ReusableAutocompleteWithID type='withLabel' width={435} height={55} btnWidth={63} btnBgColor='#144A94'
                     borderColor='#707070' placeholder='Organization' state={organization} setState={setOrganization}
                     grayedOut={role !== 2} readOnly={role !== 2}
                     options={organizationOptions.map(o => ({ id: o.id, option: o.name }))} />
                  <ReusableAutocompleteWithID type='withLabel'
                     width={435} height={55} btnWidth={63} btnBgColor='#144A94' borderColor='#707070' placeholder='Country*'
                     state={country} setState={setCountry} grayedOut={role !== 2} readOnly={role !== 2}
                     options={countryOptions.map(c => ({ id: c.id, option: c.display_name }))} required />
               </Grid>
               {[3, 5].includes(editingUser.role_id) && <Grid container justifyContent="space-evenly" style={rowStyle}>
                  <ReusableAutocompleteWithID type='withLabel' width={435} height={55}
                     btnWidth={63} btnBgColor='#144A94' borderColor='#707070' placeholder='Country Partner*' state={1}
                     options={[{ id: 1, option: editingUser.parent_name || 'owo' }]}
                     required grayedOut readOnly />
                  <ReusableAutocompleteWithID type='withLabel' width={435} height={55} btnWidth={63} btnBgColor='#144A94'
                     borderColor='#707070' state={1} placeholder={teacherBelongsToTuitionCentre ? 'Tuition Centre*' : 'School*'}
                     options={[{ id: 1, option: editingUser?.school_name }]}
                     required grayedOut readOnly />
               </Grid>}
               {[4].includes(editingUser.role_id) && <ReusableAutocompleteWithID type='withLabel' width={435} height={55}
                  btnWidth={63} btnBgColor='#144A94' borderColor='#707070' placeholder='Country Partner*' state={1}
                  options={[{ id: 1, option: editingUser.parent_name || 'owo' }]}
                  marginLeft={110} marginBottom={20} required grayedOut readOnly />}
            </>}
         </div>
         <Grid container justifyContent='space-evenly' style={buttonsContainerStyle}>
            <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
               onClick={() => cancelEdit()} />
            <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
               onClick={() => onSubmit()} />
         </Grid>
      </Box>
   )
}