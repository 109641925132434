import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableRow,
  Grid,
  TableCell,
  TableContainer,
  TableBody,
  Paper,
  TablePagination,
  Checkbox,
} from "@mui/material";
import { getComparator, stableSort } from "../../functions/table";
import EnhancedTableHead from "./EnhancedTableHead";
import useWindowDimensions from "../../hooks/WindowDimensions";
import LightTooltip from "../general/LightTooltip";
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";
import './style.css'
const containerStyle = (width) => ({
  marginBlock: '2%',
  width:0.89 * width,
});
const paperStyle = (width , hasSelect) => ({
  boxShadow: "0 0 3px #9E9E9E",
  minWidth: 400,
  maxWidth: 3200,
  width:hasSelect? 0.89 * width-60:0.89 * width,
  margin:'0'
});
const divStyle = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: "block",
  overflow: "hidden",
  minWidth: 150,
  maxWidth: 300,
};
const Cell = ({
  row,
  getter,
  color,
  widths,
  fixed
}) => {
  const tableCellStyle = {
    paddingLeft: 15,
    backgroundColor: color,
    backgroundClip: "padding-box",
  };
  const fixedTableCellStyle = (getter) => {
    let index = widths.findIndex((w) => w.id === getter);
    let w = widths[index - 1]?.totalWidth || 0;
    return { ...tableCellStyle, position: "sticky", left: index==2?w-90:w, zIndex: 999 };
  };
  let output = row[getter] || "-";
  let hoverData = row[getter];
  
  let owo = (
    <div style={divStyle}>
      {output}
    </div>
  );
  return (
    <LightTooltip
      title={row[getter]}
      rollover={typeof hoverData === "string" ? 1 : 0}
      topic={0}
    >
      <TableCell
        align="left"
        component="th"
        scope="row"
        style={fixed.includes(getter) ? fixedTableCellStyle(getter) : tableCellStyle}
      >
        {owo}
      </TableCell>
    </LightTooltip>
  );
};
const Row = ({
  row,
  getters,
  widths,
  fixed,
  colormain
}) => {
  const [color, setColor] = useState("#FFF");
  const tableRowStyle = {
    height: 80,
    overFlow: "auto",
  };
  const blankRow = {
    height: 40, /* overwrites any other rules */
    overFlow: "auto",
    borderBottom:'1px solid #F6F6F6' 
   
  }
  return (
  !Array.isArray(row)&&<TableRow
      style={tableRowStyle}
      onMouseEnter={() => setColor("#F6F6F6")}
      onMouseLeave={() => setColor("#FFF")}
    >
      {getters.map((getter, i) => (
        <Cell
          key={`${getter}-${i}`}
          row={row}
          color={colormain==='primary'?'#FFF':'#d3d3d3 '}
          getter={getter}
          widths={widths}
          fixed={fixed}
        />
      ))}
    </TableRow>
  );
};
export default function LightTable({
  headers,
  data,
  fixed,
  selected , selecting , setSelecting , setSelected , hasSelect , hasPagination , insideModal , unsorted
}) {
  const getters = headers.map((h) => h.id);
  const [order, setOrder] = useState(unsorted?" ":"asc");
  const [orderBy, setOrderBy] = useState(unsorted?"":"id");
  const [page , setPage] = useState(0)
  const [rowsPerPage , setRowsPerPage] = useState(hasPagination&&insideModal?5:10)
  const [hasOverFlow , setHasOverFlow] = useState(false)
  const handlePageChange = (event , newPage)=>{
    setPage(newPage)
  }
  const handleRowPerPagechange = (event)=>{
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const [widths, setWidths] = useState(
    headers.map((h) => ({ id: h.id, width: 0, totalWidth: 0 }))
  );
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    const contianer = document.getElementsByClassName('cont')[0]
    if(contianer.scrollWidth===contianer.offsetWidth ){
        setHasOverFlow(false)
    }else{
        setHasOverFlow(true)
    }
}, [data])
const checkBoxDivStyle = {
  display: 'flex', flexDirection: 'column',  width: 60
}
const selectAllCheckBoxStyle = {
  color: "#000" , height:61
}
const checkBoxStyle = {
  color: "#000", height: 80
}
const indeterminateCondition = hasSelect&&selected.length > 0 && selected.length < data.length
const checkedCondition = hasSelect&&data.length > 0 && selected.length === data.length
const handleShift = id => {
  let sortedData = stableSort(data, getComparator(order, orderBy))
  // Find the earliest occurence in sortedData whr it is also in selected
  let firstToLast = sortedData.filter(s => selected.includes(s.id)).map(s => s.id)
  let [selectingIndex, firstIndex, lastIndex] = [sortedData.findIndex(d => d.id === id),
  sortedData.findIndex(d => d.id === firstToLast[0]), sortedData.findIndex(d => d.id === firstToLast.slice(-1)[0])]
  if (selectingIndex >= firstIndex && selectingIndex <= lastIndex) {
      // If clicked between(inclusive of first and last), just check/uncheck the selected row
      // By right it should have its own set of behaviour(If u test by shift clicking around in file explorer)
      // But for now just leave it like this first because shift click is not required function
      handleClick(id, sortedData[selectingIndex][getters[1]])
      return
  }
  let newSelected = selected
  let newSelecting = selecting
  if (selectingIndex < firstIndex) {
      // If shift click from the front, check all until the last row
      for (let i = selectingIndex; i <= lastIndex; i++) {
          if (!newSelected.includes(sortedData[i].id)) {
              newSelected = [...newSelected, sortedData[i].id]
              newSelecting = [...newSelecting, sortedData[i][getters[1]]]
          }
      }
  } else if (selectingIndex > lastIndex) {
      // If shift click from the back, check all from start till selected row
      for (let i = firstIndex; i <= selectingIndex; i++) {
          if (!newSelected.includes(sortedData[i].id)) {
              newSelected = [...newSelected, sortedData[i].id]
              newSelecting = [...newSelecting, sortedData[i][getters[1]]]
          }
      }
  }
  setSelected(newSelected)
  setSelecting(newSelecting)
}
const handleClick = (id, name) => {
  const selectedIndex = selected.indexOf(id)
  let newSelected = []
  let newSelecting = []
  if (selectedIndex === -1) {
      // If cannot find
      newSelected = newSelected.concat(selected, id)
      newSelecting = newSelecting.concat(selecting, name)
  } else if (selectedIndex === 0) {
      // If first
      newSelected = newSelected.concat(selected.slice(1))
      newSelecting = newSelecting.concat(selecting.slice(1))
  } else if (selectedIndex === selected.length - 1) {
      // If last
      newSelected = newSelected.concat(selected.slice(0, -1))
      newSelecting = newSelecting.concat(selecting.slice(0, -1))
  } else if (selectedIndex > 0) {
      // If in between
      newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
      )
      newSelecting = newSelecting.concat(
          selecting.slice(0, selectedIndex),
          selecting.slice(selectedIndex + 1),
      )
  }
  setSelected(newSelected)
  setSelecting(newSelecting)
}
const handleSelectAllClick = () => {
  let newSelected = [...selected]
  let newSelecting = [...selecting]
  // If not every row on the page is in the selected array, just push everything into the selected array, 
  // don't worry about duplicate ids here, behind will handle
  // Else, remove every row from the page from the selected array
  if (!stableSort(data, getComparator(order, orderBy)).every(el => selected.includes(el.index_no))) {
      stableSort(data, getComparator(order, orderBy)).forEach(d => {
          newSelected.push(d.index_no)
          newSelecting.push(d[getters[1]])
      })
  } else {
      stableSort(data, getComparator(order, orderBy)).forEach(d => {
          newSelected.splice(newSelected.findIndex(n => n === d.index_no), 1)
          newSelecting.splice(newSelecting.findIndex(n => n === d[getters[1]]), 1)
      })
  }
  // Handle duplicate ids here
  setSelected([...new Set(newSelected)])
  setSelecting([...new Set(newSelecting)])
}

const handleSelectClick = (event, id, name) => event.shiftKey && selected.length ? handleShift(id) : handleClick(id, name)

const isSelected = id => selected.indexOf(id) !== -1


let filteredData = data.filter(el=>{
  return !Array.isArray(el)
})
let currentGroup = filteredData[0].group_id
let color = 'primary'
  const { width } = useWindowDimensions();
  return (
    <Grid container  alignItems="flex-start"  style={!insideModal?containerStyle(width):{
      margin:'2% auto',
      width:'100%',
    }} position={'relative'} >
      
           {hasSelect&&<div style={checkBoxDivStyle}>
                <Checkbox style={selectAllCheckBoxStyle} indeterminate={indeterminateCondition}
                    checked={checkedCondition} onChange={() => handleSelectAllClick()} />
                {stableSort(filteredData, getComparator(order, orderBy)).slice(page*rowsPerPage , page*rowsPerPage+rowsPerPage).map((row, i) => {
                  return  !Array.isArray(row)&&<Checkbox key={i} style={checkBoxStyle} onClick={e => handleSelectClick(e, row.index_no, row.name)}
                        checked={isSelected(row.index_no)} />
})}
            </div>}
      <Paper style={!insideModal?paperStyle(width , hasSelect):{
       margin:'0% auto',
      width:hasSelect?0.69 * width-60:'80%',
    }}>
            { hasOverFlow&&!insideModal&&<BsFillArrowLeftCircleFill style={{position:'absolute' , fontSize:'50px' , right:'95%' , top:'50%',zIndex:9999 , color:"#5E75C3"}} onClick={()=>{
        const contianer = document.getElementsByClassName('cont')[0]
        let widthPerScroll = contianer.scrollWidth/headers.length;
        contianer.scrollLeft -= widthPerScroll+20
           }}/>}
      { hasOverFlow&&!insideModal&&<BsFillArrowRightCircleFill style={{position:'absolute' , fontSize:'50px' , left:'98.5%' , top:'50%',zIndex:9999 , color:"#5E75C3"}} onClick={()=>{
        const contianer = document.getElementsByClassName('cont')[0]
        let widthPerScroll = contianer.scrollWidth/headers.length;
        contianer.scrollLeft += widthPerScroll+20
           }}/>}
        <TableContainer className="cont"  style={{outline:'none' , userSelect:'none' ,position:'relative'}}>
          <Table size="small" className="lightTable" >
            <EnhancedTableHead
              handleRequestSort={handleRequestSort}
              headers={headers}
              widths={widths}
              setWidths={setWidths}
              fixed={fixed}
              isCheatingTable={true}
            />
            <TableBody>
              {stableSort(filteredData, getComparator(order, orderBy)).slice(page*rowsPerPage , page*rowsPerPage+rowsPerPage).map(
                (row, i) => {
                 
                 if(currentGroup!==row.group_id){
                  if(color=='primary'){
                    color='scndry'
                  }else{
                    color = 'primary'
                  }
                  currentGroup = row.group_id
                 }
                  
                  return (
                <Row
                  colormain={color}  
                  key={i}
                  row={row}
                  getters={getters}
                  widths={widths}
                  fixed={fixed}
                />
              )}
              )}
            </TableBody>
           
          </Table>
        
        </TableContainer>
       {hasPagination&& <TablePagination  component={"div"} rowsPerPageOptions={[5,10,25,50]} page={page} rowsPerPage={rowsPerPage} onPageChange={handlePageChange} onRowsPerPageChange={handleRowPerPagechange} count={filteredData.length}/> }
      </Paper>
    </Grid>
  );
}
