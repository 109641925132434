import React, { useState, useEffect, useRef } from 'react'
import { Grid, Modal } from '@mui/material'
import { cancelAction } from '../../functions/general'
import { showNotification } from '../../functions/snackbar'
import { patchCompetitionRoundAward } from '../../functions/patchData'
import { deleteCompetitionRoundAward } from '../../functions/deleteData'
import ClearIcon from '@mui/icons-material/Clear'
import AddRoundAwardsModal from './AddRoundAwardsModal'
import DeleteModal from '../modal/DeleteModal'
import OverallAward from './OverallAward'
import TitleBar from '../general/TitleBar'
import AddPageWrapper from '../general/AddPageWrapper'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
import LoadingBackdrop from '../general/LoadingBackdrop'
import { useSnackbar } from 'notistack'
import { deepCopy } from '../../functions/edit'
const roundAwardGridStyle = {
   border: '1px solid #707070', borderRadius: 12, padding: 20,
   boxShadow: '5px 5px 12px rgba(0, 0, 0, 16%)', marginBottom: 30
}
const iconStyle = { fontSize: 30, cursor: 'pointer' }

const awardsContainerStyle = { paddingBottom: 20 }
export default function Awards({ original, setOriginal, competition, setCompetition }) {
   const [expand, setExpand] = useState(true)
   const [loading, setLoading] = useState(false)
   const { rounds } = competition
   return (
      <Grid container style={awardsContainerStyle}>
         <LoadingBackdrop loading={loading} />
         <TitleBar title='overall Awards' state={expand} setState={setExpand} />
         {expand && <AddPageWrapper>
            {/* {rounds.map((round, index) => (
               <Award key={index} count={index} original={original} setOriginal={setOriginal}
                  competition={competition} setCompetition={setCompetition} setLoading={setLoading} />
            ))} */}
            {rounds.length > 1 && <OverallAward original={original} setOriginal={setOriginal}
               competition={competition} setCompetition={setCompetition} />}
         </AddPageWrapper>}
      </Grid>
   )
}