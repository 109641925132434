import React from "react";
import GradeIcon from "@mui/icons-material/Grade";
import CollectionsIcon from "@mui/icons-material/Collections";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { IoIosPaper } from "react-icons/io";
import { FaLayerGroup, FaGlobe } from "react-icons/fa";
import NunitoText from "./NunitoText";
import { HiFlag, HiUserGroup } from "react-icons/hi";
const bigIconStyle = (color) => ({ fontSize: 30, color: color });
const contentDivStyle = {
  fontSize: 12,
  fontWeight: 700,
  fontFamily: "Nunito",
  textAlign: "center",
  paddingInline: 20,
};
export default function Widget({
  title,
  content,
  marginLeft,
  marginTop,
  marginRight,
  marginBottom,
}) {
  let bigIcon, color, fontSize;
  //Set icons base on title
  switch (title) {
    case "Grade":
      color = "#F16774";
      bigIcon = <GradeIcon style={bigIconStyle(color)} />;
      break;
    case "Award Type":
      color = "#F16774";
      bigIcon = <GradeIcon style={bigIconStyle(color)} />;
      break;
    case "Collection":
      color = "#569EFF";
      bigIcon = <CollectionsIcon style={bigIconStyle(color)} />;
      break;
    case "Competition":
      color = "#FF7600";
      bigIcon = <IoIosPaper style={bigIconStyle(color)} />;
      break;
    case "Round":
      color = "#6FC9B6";
      bigIcon = <AccessTimeIcon style={bigIconStyle(color)} />;
      break;
    case "Section":
    case "Level":
      color = "#706FA7";
      bigIcon = <FaLayerGroup style={bigIconStyle(color)} />;
      break;
    case "Tasks Grouping":
      color = "#F3A867";
      bigIcon = <CollectionsBookmarkIcon style={bigIconStyle(color)} />;
      break;
    case "Format":
      color = "#F3A867";
      bigIcon = <FaGlobe style={bigIconStyle(color)} />;
      break;
    case "Group":
      color = "#706FA7";
      bigIcon = <HiUserGroup style={bigIconStyle(color)} />;
      break;
    case "Countries":
      color = "#F3A867";
      bigIcon = <HiFlag style={bigIconStyle(color)} />;
    default:
  }
  const divStyle = {
    borderRadius: 12,
    boxShadow: "0 0 5px #9E9E9E",
    height: 110,
    minWidth: 160,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginLeft,
    marginTop,
    marginRight,
    marginBottom,
    paddingBlock: 10,
  };
  return (
    <div style={divStyle}>
      {bigIcon}
      <div style={contentDivStyle}>{content}</div>
      <NunitoText value={title} fontSize={16} fontWeight={500} color={color} />
    </div>
  );
}
