import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { makeSureIsArray } from '../../functions/general'
import { showWarningSwal } from '../../functions/alert'
import { uploadParticipantAnswers } from '../../functions/postData'
import CloseIcon from '@mui/icons-material/Close'
import { FaFileExcel } from 'react-icons/fa'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import * as XLSX from 'xlsx'
const boxStyle = { width: 1250, minHeight: 450, maxHeight: '90%' }
const closeIconDivStyle = { display: 'flex', justifyContent: 'flex-end', marginTop: -42 }
const closeIconStyle = { fontSize: 36, cursor: 'pointer' }
const divStyle = { paddingInline: 60 }
const radioStyle = { color: '#000' }
const configurationContainerStyle = { marginTop: 10 }
const uploadToDivStyle = {
    display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
    border: '1px solid', borderRadius: 10, padding: 16, width: '66%', marginRight: '4%'
}
const flexStyle = { display: 'flex' }
const flexEndStyle = { display: 'flex', justifyContent: 'flex-end' }
const uploadFileDivStyle = { border: '1px dashed #A9ABD3', borderRadius: 10, padding: 16, width: '30%' }
const excelIconStyle = { color: '#A9ABD3', fontSize: 70 }
const buttonContainerStyle = { ...flexEndStyle, marginBlock: 20, paddingRight: 60 }
const delimit = input => {
    let tokens = []
    let startPosition = 0
    let isInQuotes = false
    for (let currentPosition = 0; currentPosition < input.length; currentPosition++) {
        if (input.charAt(currentPosition) === '"') {
            isInQuotes = !isInQuotes
        } else if (input.charAt(currentPosition) === ',' && !isInQuotes) {
            tokens.push(input.substring(startPosition, currentPosition))
            startPosition = currentPosition + 1
        }
    }
    let lastToken = input.substring(startPosition)
    if (lastToken === ',') {
        tokens.push("")
    } else {
        tokens.push(lastToken)
    }
    return tokens
}
export default function UploadStudentAnswerModal({ setLoading, setOnUpload, table, id, afterUploadingAnswer }) {
    const isMounted = useRef(false)
    const competition = makeSureIsArray(table.data).find(d => d.id === id)
    const [type, setType] = useState('')
    const [round, setRound] = useState(competition.rounds.length ? competition.format ? '' : competition.rounds[0].id : '')
    const [level, setLevel] = useState('')
    const [file, setFile] = useState(null)
    const [data, setData] = useState([])
    const { rounds } = competition
    const roundOptions = rounds.map(r => ({ id: r.id, option: r.name }))
    const levelOptions = rounds.find(r => r.id === round)?.levels.map(l => ({ id: l.id, option: l.name })) || []
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    useEffect(() => {
        isMounted.current = true
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [controller])
    const onChangeRound = value => {
        setRound(value)
        setLevel('')
    }
    const openFileInput = () => document.getElementById('file-upload-for-csv-xlsx').click()
    const onChangeCSVFile = (e, file) => {
        e.target.value = null
        const reader = new FileReader()
        if (file.type === 'text/csv') {
            setFile(file)
            try {
                reader.onload = e => {
                    const text = e.target.result
                    processCSV(text, ',', file.type)
                }
                reader.readAsText(file)
            } catch (err) {
                console.log(err.message)
            }
        } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setFile(file)
            try {
                const rABS = !!reader.readAsBinaryString;
                reader.onload = e => { // e = on_file_select event
                    /* Parse data */
                    const bstr = e.target.result;
                    const wb = XLSX.read(bstr, { type: 'binary' })
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0]
                    const ws = wb.Sheets[wsname]
                    /* Convert array of arrays */
                    const text = XLSX.utils.sheet_to_csv(ws, { header: 1 })
                    /* Update state */
                    processCSV(text, ',', file.type)
                };
                if (rABS) reader.readAsBinaryString(file)
                else reader.readAsArrayBuffer(file)
            } catch (err) {
                console.log(err.message)
            }
        } else {
            alert('FUCKOFF')
        }
    }
    const processCSV = (str, delim = ',', type) => {
        //Get the header from the large chunk of string read from the csv file
        const header = str.slice(0, str.indexOf('\n')).trim().replace(/['"]+/g, '').split(delim)
        //Get all the data from the large chunk of string read from the csv file as an array
        const rows = type === 'text/csv'
            ? str.slice(str.indexOf('\n') + 1).split('\n').slice(0, -1)
            : str.slice(str.indexOf('\n') + 1).split('\n')
        const newArray = rows.map(row => {
            const values = delimit(row)
            const eachObject = header.reduce((obj, header, i) => {
                //This function will return an array with the last item being an object with undefined values
                //So we check if the object is undefined before using string functions on the object
                obj[header] = values[i]
                    ? values[i].trim().replace(/["]+/g, '')
                    : values[i]
                return obj
            }, {})
            return eachObject
        })
        setData(newArray)
    }
    console.log(table)
    const checkSelect = () => {
        let warningMessage = ''
        if (!['0', '1'].includes(type)) warningMessage += 'Please select whether you\'re Uploading or Editing.<br>'
        if (competition.format && round === '') warningMessage += 'Please select round if competition if global.<br>'
        if (level === '') warningMessage += 'Please select level.<br>'
        if (warningMessage) return showWarningSwal(warningMessage)
        openFileInput()
    }
    const reset = () => {
        setLevel('')
        competition.format && setRound('')
    }
    const onSubmit = () => {
        let warningMessage = ''
        if (!['0', '1'].includes(type)) warningMessage += 'Please select whether you\'re Uploading or Editing.<br>'
        if (competition.format && round === '') warningMessage += 'Please select round if competition if global.<br>'
        if (level === '') warningMessage += 'Please select level.<br>'
        if (file === null) warningMessage += 'Please select a file.<br>'
        if (warningMessage) return showWarningSwal(warningMessage)
        setLoading(true)
        let payload = {
            round_id: round,
            level_id: level,
            participants: []
        }
        for (const participantAnswers of data) {
            payload.participants.push({
                index_number: Object.values(participantAnswers)[0],
                answers: Object.entries(participantAnswers).filter(e => e[0].includes('Q')).map(e => e[1])
            })
        }
        console.log(JSON.stringify(payload))
        console.log(payload)
        uploadParticipantAnswers(payload, signal).then(d => {
            console.log(d)
            isMounted.current && setLoading(false)
            afterUploadingAnswer(() => setOnUpload(false), d)
        }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
    }
    return (
        <Box className='popUpModal horizontalScrollable' style={boxStyle}>
            <div>
                <NunitoText value='Student Answer Upload' fontSize={30} fontWeight={600} marginTop={20} />
                <div style={closeIconDivStyle}>
                    <CloseIcon style={closeIconStyle} onClick={() => setOnUpload(false)} />
                </div>
            </div>
            <div style={divStyle}>
                <RadioGroup value={type} onChange={e => setType(e.target.value)} row>
                    <FormControlLabel value='0' control={<Radio style={radioStyle} />} label="Upload Answers" />
                    <FormControlLabel value='1' control={<Radio style={radioStyle} />} label="Edit Answers" />
                </RadioGroup>
                <Grid container style={configurationContainerStyle}>
                    <input id='file-upload-for-csv-xlsx' type="file" accept='.csv, .xlsx' onChange={e => onChangeCSVFile(e, e.target.files[0])}
                        style={{ display: 'none' }} />
                    <div style={uploadToDivStyle}>
                        <div>
                            <NunitoText value='Upload to:' fontSize={16} fontWeight={600} align='left' marginBottom={10} />
                            <div style={flexStyle}>
                                {!!competition.format && <ReusableAutocompleteWithID type='default' width={240} height={54}
                                    placeholder='Round' state={round} setState={onChangeRound} borderColor='#707070' required
                                    options={roundOptions} marginRight={20} />}
                                <ReusableAutocompleteWithID type='default' width={240} height={54} placeholder='Level'
                                    state={level} setState={setLevel} borderColor='#707070' required
                                    options={levelOptions} grayedOut={!round} readOnly={!round} />
                            </div>
                        </div>
                        <div style={flexEndStyle}>
                            <ReusableButton text='Reset filter' bgColor='#F16774' fontSize={16} br={8} onClick={reset}
                                iconType='reset' />
                        </div>
                    </div>
                    <div style={uploadFileDivStyle}>
                        <FaFileExcel style={excelIconStyle} />
                        <NunitoText value={file ? `Selected FIle: ${file.name}` : 'Upload your .xlsx file'}
                            fontSize={16} fontWeight={600} />
                        <NunitoText value={'Drag & drop or click to upload'} fontSize={16} fontWeight={500} />
                        <ReusableButton text='Select File' color='#808081' bgColor='#EBEBEA' fontSize={16} height={54}
                            width={160} marginTop={15} onClick={() => checkSelect()} />
                    </div>
                </Grid>
            </div>
            <div style={buttonContainerStyle}>
                <ReusableButton text='Upload' fontSize={20} width={200} bgColor='#5E75C3'
                    onClick={() => onSubmit()} iconType='upload' />
            </div>
        </Box>
    )
}