import React, { useState } from "react";
import { Grid, Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateCollection } from "../../actions";
import TaskGroupList from "./TaskGroupList";
import AddTaskToSectionModal from "./AddTaskToSectionModal";
import TitleBar from "../general/TitleBar";
import AddPageWrapper from "../general/AddPageWrapper";
import ReusableButton from "../general/ReusableButton";
import ReusableTextField from "../general/ReusableTextField";
import ReusableToggle from "../general/ReusableToggle";
import InputContainer from "../general/InputContainer";
import DescriptionEditor from "../DescriptionEditor";
const tasksContainerStyle = {
  margin: 32,
  backgroundColor: "#FFF",
  padding: "10px 32px",
  border: "1px solid #707070",
  borderRadius: 10,
};
const Section = ({ count, sectionCount, collection, section, sections }) => {
  const [expand, setExpand] = useState(false);
  const [onAddTask, setOnAddTask] = useState(false);
  const [groupCount, setGroupCount] = useState("");
  const [taskCount, setTaskCount] = useState("");
  const { tasks, sortRandomly, allowSkip, description } = section;
  const dispatch = useDispatch();
  const update = (count, head, target, text, event) =>
    dispatch(updateCollection(count, head, target, text, event));
  const cancelAddTask = () => {
    setOnAddTask(false);
    setGroupCount("");
    setTaskCount("");
  };
  const prevSectionsTaskLength = sections
    .filter((s, i) => i < sectionCount)
    .map((s) => s.tasks)
    .flat().length;
  return (
    <Grid container>
      <TitleBar
        title={`Section ${sectionCount + 1}`}
        state={expand}
        setState={setExpand}
      >
        {Boolean(sectionCount) && (
          <ReusableButton
            text="Delete Section"
            height={46}
            bgColor="#E83042"
            fontSize={16}
            marginRight={20}
            onClick={() =>
              update([count, sectionCount], "deleteSection", "sections")
            }
            iconType="delete"
          />
        )}
      </TitleBar>
      {expand && (
        <AddPageWrapper>
          <Grid container style={tasksContainerStyle}>
            <Grid item xs={12}>
              <ReusableButton
                text="Add Task"
                bgColor="#5E75C3"
                fontSize={14}
                iconType="add"
                onClick={() => setOnAddTask(true)}
              />
            </Grid>
            <Grid container>
              <TaskGroupList
                tasks={tasks}
                setOnAddTask={setOnAddTask}
                update={update}
                count={count}
                sectionCount={sectionCount}
                setGroupCount={setGroupCount}
                setTaskCount={setTaskCount}
                prevSectionsTaskLength={prevSectionsTaskLength}
                axis="xy"
                pressDelay={200}
                onSortEnd={(e) =>
                  update([count, sectionCount], "sortTaskGroup", "tasks", "", e)
                }
              />
            </Grid>
          </Grid>
          {/* <Grid container>
                    <h3>{JSON.stringify({ groupCount, taskCount })}</h3>
                </Grid> */}
          {/* <h3>tasks: {tasks.map((tasks, i) => (
                    <Grid container key={i}>
                        index: {i}<br />
                        tasks: {tasks.map(task => (
                            <Grid container key={task.id} style={{ paddingLeft: 20 }}>
                                id: {task.id}, task_title: {task.task_title}
                            </Grid>
                        ))}
                    </Grid>
                ))}</h3><br /> */}
          <InputContainer
            first
            label="Sort Randomly"
            field={
              <ReusableToggle
                type="reduxIcon"
                width={124}
                state={sortRandomly}
                setState={update}
                count={[count, sectionCount]}
                head="sectionBoolean"
                target="sortRandomly"
              />
            }
          />
          <InputContainer
            label="Skip"
            field={
              <ReusableToggle
                type="reduxIcon"
                width={124}
                state={allowSkip}
                setState={update}
                count={[count, sectionCount]}
                head="sectionBoolean"
                target="allowSkip"
              />
            }
          />
          <InputContainer
            label="Total Tasks"
            field={
              <ReusableTextField
                type="immutableDisplay"
                width={240}
                placeholder="Total Tasks"
                state={tasks.flat().length}
                grayedOut
                readOnly
              />
            }
          />
          <InputContainer
            last
            label="Description"
            field={
              <DescriptionEditor
                state={description}
                setState={update}
                height={400}
                placeholder="Description"
                count={[count, sectionCount]}
                head="sectionText"
                target="description"
              />
            }
          />
        </AddPageWrapper>
      )}
      <Modal open={onAddTask} onClose={() => cancelAddTask()}>
        <>
          <AddTaskToSectionModal
            collection={collection}
            section={section}
            count={count}
            sectionCount={sectionCount}
            groupCount={groupCount}
            taskCount={taskCount}
            update={update}
            cancelAddTask={cancelAddTask}
          />
        </>
      </Modal>
    </Grid>
  );
};
const sectionContainerStyle = { paddingBottom: 20 };
export default function Sections({ count, collection }) {
  const [expand, setExpand] = useState(false);
  const { sections } = collection;
  const dispatch = useDispatch();
  const update = (count, head, target, text, event) =>
    dispatch(updateCollection(count, head, target, text, event));
  return (
    <>
      <TitleBar title="Sections" state={expand} setState={setExpand}>
        <ReusableButton
          text="New Section"
          height={46}
          bgColor="#5E75C3"
          fontSize={16}
          marginRight={40}
          onClick={() => update(count, "addSection", "sections")}
          iconType="add"
        />
      </TitleBar>
      {expand && (
        <AddPageWrapper>
          <Grid container style={sectionContainerStyle}>
            {sections.map((section, index) => (
              <Section
                key={index}
                count={count}
                sectionCount={index}
                collection={collection}
                section={section}
                sections={sections}
              />
            ))}
          </Grid>
        </AddPageWrapper>
      )}
    </>
  );
}
