import React, { useState } from "react"
import { Grid } from '@mui/material'
import TitleBar from "../general/TitleBar"
import AddPageWrapper from "../general/AddPageWrapper"
import DescriptionEditor from "../DescriptionEditor"
const containerStyle = { paddingBottom: 20 }
const editorContainerStyle = { margin: '36px 60px' }
export default function Solution({ solutions, updateTask ,IsRead}) {
    const [expand, setExpand] = useState(true)
    return (
        <Grid container style={containerStyle}>
            <TitleBar title='Task Solution/Working' state={expand} setState={setExpand} />
            {expand && <AddPageWrapper>
                <Grid container style={editorContainerStyle}>
                    <DescriptionEditor state={solutions} setState={updateTask} height={500} disabled={IsRead} grayedOut={IsRead}
                        placeholder='Solution' target='solutions' />
                </Grid>
            </AddPageWrapper>}
        </Grid >
    )
}