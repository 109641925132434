import React from 'react'
import { Grid } from '@mui/material'
import ReusableAutocompleteWithID from './general/ReusableAutocompleteWithID'
import ReusableCheckbox from './general/ReusableCheckbox'
import ReusableButton from './general/ReusableButton'
import { isAdmin } from '../functions/checkrole'
const gibOptions = arr => arr.map(o => ({ id: o.id, option: o.name || o.display_name }))
const EachAutoComplete = ({ count, filter, optionsInUse, setState, filtered, uploadingFor }) => {
    let options = []
    let grayCondition = false
    let required = true
    switch (filter.key) {
        case 'type':
            options = gibOptions(optionsInUse[0])
            break;
        case 'competition_id':
            options = gibOptions(optionsInUse[1])
            break;
        case 'organization_id':
            options = gibOptions(optionsInUse[2])
            grayCondition = filtered.find(f => f.key === 'competition_id').state === ''
            break;
        case 'country_id':
            options = gibOptions(uploadingFor === 'school' ? optionsInUse : optionsInUse[3])
            if (uploadingFor === 'participant') grayCondition = filtered.find(f => f.key === 'competition_id').state === ''
            break;
        case 'school':
            options = gibOptions(optionsInUse[4])
            required = false
            grayCondition = filtered.find(f => f.key === (isAdmin() ? 'country_id' : 'competition_id')).state === ''
            break;
        case 'tuition_centre':
            options = gibOptions(optionsInUse[5])
            required = false
            grayCondition = filtered.find(f => f.key === 'type').state !== -1 ||
                filtered.find(f => f.key === (isAdmin() ? 'country_id' : 'competition_id')).state === ''
            break;
        default:
    }
    let placeholder = `${filter.label}${required ? '*' : ''}`
    if (['private'].includes(filter.key)) {
        return <ReusableCheckbox type='default' label={placeholder}
            state={filter.state} count={count} target='state' setState={setState} fontSize={20} />
    } else {
        return <ReusableAutocompleteWithID type='withLabel' width={380} placeholder={placeholder}
            state={filter.state} count={count} target='state' setState={setState}
            bgColor='#FFF' borderColor='#707070' marginRight={30}
            required={required} grayedOut={grayCondition} readOnly={grayCondition} options={options} />
    }
}
export default function CSVUploadFilter({ filtered, options, setState, uploadingFor, list, setFiltered }) {
    const selectContainerStyle = {
        border: '1px solid #000', borderRadius: 12,
        backgroundColor: '#F5F5F5', padding: 24
    }
    const selectContainerStyleWithList = { ...selectContainerStyle, pointerEvents: 'none' }
    const reset = () => {
        let newFiltered = [...filtered]
        newFiltered.forEach(f => typeof f.state === 'boolean' ? f.state = false : f.state = '')
        setFiltered(newFiltered)
    }
    return (
        <Grid container alignItems='center' style={list.length ? selectContainerStyleWithList : selectContainerStyle}>
            {filtered.map((f, i) => (
                <EachAutoComplete key={i} count={i} filter={f} optionsInUse={options} setState={setState}
                    filtered={filtered} uploadingFor={uploadingFor} />
            ))}
            <Grid container style={{ marginTop: 24 }}>
                <ReusableButton text='Reset' bgColor='#F16774' fontSize={16} br={8} onClick={reset} iconType='reset' />
            </Grid>
        </Grid>
    )
}