import React from 'react'
import { FormControlLabel, Checkbox } from '@mui/material'
export default function ReusableCheckbox({ type, width, marginLeft, marginRight, marginTop, marginBottom,
    label, fontSize, state, setState, count, target, disabled }) {
    const formControlStyle = {
        width: width,
        marginLeft: marginLeft,
        marginRight: marginRight,
        marginTop: marginTop,
        marginBottom: marginBottom,
        height: 40
    }
    const textStyle = {
        fontSize: fontSize,
        fontFamily: 'Nunito',
        fontWeight: 600,
        userSelect: 'none'
    }
    const onChangeState = () => {
        if (count !== undefined && target !== undefined) setState(count, target, !state)
        else if (count !== undefined && target === undefined) setState(count, !state)
        else if (count === undefined && target !== undefined) setState(target, !state)
        else setState(!state)
    }
    switch (type) {
        case 'default':
            return (
                <FormControlLabel style={formControlStyle} disabled={disabled}
                    value={state} onChange={() => onChangeState()}
                    control={<Checkbox style={{ color: '#000' }} checked={state} />}
                    label={<p style={textStyle}>{label}</p>} labelPlacement='start'
                />
            )
        case 'modal':
            return (
                <FormControlLabel style={formControlStyle} disabled={disabled}
                    value={state} onChange={() => onChangeState()}
                    control={<Checkbox style={{ color: '#000' }} size='small' checked={state} />}
                    label={<p style={textStyle}>{label}</p>} labelPlacement='end'
                />
            )
        default: return null
    }
}