import React from 'react'
import { IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
export default function ReusableToggle({ type, width, marginLeft, marginRight, marginTop, marginBottom,
    falseValue, trueValue, state, setState, count, head, target, disabled, grayedOut }) {
    let divBorder, borderRadius, divHeight, btnHeight, btnWidth
    //Available types are: icon, reduxIcon, text, reduxText
    //Set width of each button to half to total width of the div
    btnWidth = width / 2
    //Style the div and button based on type
    if (['icon', 'reduxIcon'].includes(type)) {
        divBorder = '2px solid #707070'
        borderRadius = 12
        divHeight = 45
        btnHeight = 45
    }
    if (['text', 'reduxText'].includes(type)) {
        divBorder = '1px solid #707070'
        borderRadius = 24
        divHeight = 59
        btnHeight = 51
    }
    const divStyle = {
        width: width,
        marginLeft: marginLeft,
        marginRight: marginRight,
        marginTop: marginTop,
        marginBottom: marginBottom,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        border: divBorder,
        borderRadius: borderRadius,
        height: divHeight,
        backgroundColor: grayedOut && '#707070'
    }
    const btnStyle = {
        backgroundColor: !state ? '#F16774' : '#5E75C3',
        borderRadius: borderRadius,
        height: btnHeight,
        width: btnWidth
    }
    const textStyle = {
        fontSize: 20,
        fontFamily: 'Nunito',
        fontWeight: 600,
        fontStyle: 'italic',
        userSelect: 'none',
        width: btnWidth,
        textAlign: 'center',
        color: grayedOut && '#FFF'
    }
    const onChangeState = () => {
        if (count !== undefined && target !== undefined) setState(count, target, !state)
        else if (count !== undefined && target === undefined) setState(count, !state)
        else if (count === undefined && target !== undefined) setState(target, !state)
        else setState(!state)
    }
    switch (type) {
        case 'text':
            return (
                <div style={divStyle} onClick={() => !disabled ? onChangeState() : undefined}>
                    {!state ? <IconButton style={{ ...btnStyle, marginLeft: 3 }}>
                        <Typography style={{ ...textStyle, color: '#FFF' }}>{falseValue}</Typography>
                    </IconButton> : <div style={{ ...textStyle, marginLeft: 10 }}>
                        {falseValue}</div>}
                    {state ? <IconButton style={{ ...btnStyle, marginRight: 3 }}>
                        <Typography style={{ ...textStyle, color: '#FFF' }}>{trueValue}</Typography>
                    </IconButton> : <div style={{ ...textStyle, marginRight: 10 }}>{trueValue}</div>}
                </div>
            )
        case 'reduxText':
            return (
                <div style={divStyle} onClick={() => !disabled ? setState(count, head, target, !state) : undefined}>
                    {!state ? <IconButton style={{ ...btnStyle, marginLeft: 3 }}>
                        <Typography style={{ ...textStyle, color: '#FFF' }}>{falseValue}</Typography>
                    </IconButton> : <div style={{ ...textStyle, marginLeft: 10 }}>
                        {falseValue}</div>}
                    {state ? <IconButton style={{ ...btnStyle, marginRight: 3 }}>
                        <Typography style={{ ...textStyle, color: '#FFF' }}>{trueValue}</Typography>
                    </IconButton> : <div style={{ ...textStyle, marginRight: 10 }}>{trueValue}</div>}
                </div>
            )
        case 'icon':
            return (
                <div style={divStyle} onClick={() => !disabled ? onChangeState() : undefined}>
                    {Boolean(!state) && <IconButton style={{ ...btnStyle, marginLeft: -2 }}>
                        <ClearIcon style={{ fontSize: 25, color: '#FFF' }} />
                    </IconButton>}
                    {Boolean(state) && <IconButton style={{ ...btnStyle, marginLeft: '50%' }}>
                        <CheckIcon style={{ fontSize: 25, color: '#FFF' }} />
                    </IconButton>}
                </div>
            )
        case 'reduxIcon':
            return (
                <div style={divStyle} onClick={() => !disabled ? setState(count, head, target, !state) : undefined}>
                    {Boolean(!state) && <IconButton style={{ ...btnStyle, marginLeft: -2 }}>
                        <ClearIcon style={{ fontSize: 25, color: '#FFF' }} />
                    </IconButton>}
                    {Boolean(state) && <IconButton style={{ ...btnStyle, marginLeft: '50%' }}>
                        <CheckIcon style={{ fontSize: 25, color: '#FFF' }} />
                    </IconButton>}
                </div>
            )
        default: return null
    }
}