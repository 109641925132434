import React, { useState, useCallback, useEffect } from 'react'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Competitions from '../components/Competitions/Competitions'
import { useDispatch } from 'react-redux'
import { resetCompetition, dupeCompetitionFrmDB } from '../actions'
import { getCompetitions } from '../functions/getData'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
import { showWarningSwal } from '../functions/alert'
import Loader from '../components/general/Loader'
export default function AddCompetition() {
   const [loading, setLoading] = useState(false)
   document.title = 'Add Competition'
   let location = useLocation()
   const dispatch = useDispatch()
   const dupe = useCallback(text => {
      return dispatch(dupeCompetitionFrmDB(text))
   }, [dispatch])
   const dupeCompetition = useCallback(competition => {
      dupe(competition)
   }, [dupe])
   const reset = useCallback(() => dispatch(resetCompetition()), [dispatch])
   useEffect(() => {
      const controller = new AbortController()
      const signal = controller.signal
      if (location.search) {
         if (location.search.slice(0, 14) !== '?duplicate_id=') return showWarningSwal('Wrong query')
         setLoading(true)
         getCompetitions(`/${location.search.slice(14)}`, signal).then(c => {
            let competition = c.competitionList.data[0]
            if (competition === undefined) return showWarningSwal('Competition not found')
            dupeCompetition(competition)
         }).catch(e => console.log(e)).finally(() => {
            if (!signal.aborted) setLoading(false)
         })
      }
      return () => {
         reset()
         controller.abort()
      }
   }, [location.search, dupeCompetition, reset])
   return (
      <Box className='wrapperBox'>
         <div className='firstRowContainer dashboardAndSelfBtnDiv'>
            <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
            <ChevronRightIcon />
            <ReusableButton text='Competitions' fontSize={14} bgColor='#F16774' height={36} br={18} onClick={() => reset()}/>
         </div>
         <div className='formContainer'>
            <NunitoText value='Add Competition' fontSize={40} fontWeight={700} italic color='#144A94' align='left' />
            {loading ? <Loader height={600} /> : <Competitions  adding={true}/>}
         </div>
      </Box>
   )
}