import React, { useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { showWarningSwal } from '../../functions/alert'
import { invalidName, makeSureIsArray } from '../../functions/general'
import { patchDifficultyGroup } from '../../functions/patchData'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableToggle from '../general/ReusableToggle'
import ReusableButton from '../general/ReusableButton'
const boxStyle = { width: 1500, minHeight: 150, maxHeight: '90%' }
const formContainerStyle = { paddingBottom: 20 }
const configContainerStyle = { marginTop: 30, paddingInline: 200 }
const rowsContainerStyle = { marginTop: 20 }
const flexStyle = { display: 'flex' }
const centerFlexStyle = { display: 'flex', alignItems: 'center' }
const difficultiesContainerStyle = assignMarks => ({ marginTop: 20, width: !assignMarks && 1000 })
const buttonsWrapperStyle = {
    marginInline: '510px 130px', marginBottom: 20
}
const rowButtonsWrapperStyle = { width: 80 }
const upButtonStyle = (index, length) => ({ marginRight: index === length - 1 && 20 })
const downButtonStyle = index => ({ marginLeft: !index && 36 })
const rowIconStyle = { fontSize: 32, color: '#000' }
const buttonsContainerStyle = { paddingInline: 210, marginBlock: '60px 30px' }
export default function EditDifficultyGroupModal({ setLoading, table, id, afterEditingDifficultyGroup, cancelEdit }) {
    const editingDifficultyGroup = makeSureIsArray(table.data).find(s => s.id === id)
    const [difficultyGroup, setDifficultyGroup] = useState(window.structuredClone(editingDifficultyGroup))
    const [deleteId, setDeleteId] = useState([])
    const { name, assign_marks, difficulty } = difficultyGroup
    const updateDifficultyGroup = (identifier, value) => {
        let newDifficultyGroup = { ...difficultyGroup }
        newDifficultyGroup[identifier] = value
        setDifficultyGroup(newDifficultyGroup)
    }
    const updateDifficulty = (count, identifier, value) => {
        let newDifficultyGroup = { ...difficultyGroup }
        if (identifier === 'add') {
            newDifficultyGroup.difficulty.splice(count + 1, 0, { name: '', correct_marks: '', wrong_marks: '', blank_marks: '' })
        } else if (identifier === 'remove') {
            setDeleteId([...deleteId, newDifficultyGroup.difficulty[count].id])
            newDifficultyGroup.difficulty.splice(count, 1)
        } else if (identifier === 'moveUp') {
            [newDifficultyGroup.difficulty[count - 1], newDifficultyGroup.difficulty[count]] =
                [newDifficultyGroup.difficulty[count], newDifficultyGroup.difficulty[count - 1]]
        } else if (identifier === 'moveDown') {
            [newDifficultyGroup.difficulty[count + 1], newDifficultyGroup.difficulty[count]] =
                [newDifficultyGroup.difficulty[count], newDifficultyGroup.difficulty[count + 1]]
        } else newDifficultyGroup.difficulty[count][identifier] = value
        setDifficultyGroup(newDifficultyGroup)
    }
    const massAssignScore = target => {
        let newDifficultyGroup = { ...difficultyGroup }
        newDifficultyGroup.difficulty.forEach(n => n[target] = newDifficultyGroup.difficulty[0][target])
        setDifficultyGroup(newDifficultyGroup)
    }
    const onSubmit = () => {
        if (JSON.stringify(editingDifficultyGroup) === JSON.stringify(difficultyGroup)) return showWarningSwal('Please make some changes before submitting')
        let warningMessage = ''
        if (invalidName(name)) warningMessage += 'Invalid Difficulty Group Name.<br>'
        difficulty.forEach(({ name, correct_marks, wrong_marks, blank_marks }, j) => {
            let a = ''
            if (invalidName(name)) a += 'Invalid Difficulty Name.<br>'
            if (assign_marks) {
                if (isNaN(correct_marks) || correct_marks === '') a += 'Invalid correct marks<br>'
                if (isNaN(wrong_marks) || wrong_marks === '') a += 'Invalid wrong marks<br>'
                if (isNaN(blank_marks) || blank_marks === '') a += 'Invalid blank marks<br>'
            }
            if (a) {
                warningMessage += '<div style="text-align:left">'
                warningMessage += `<b>Difficulty ${j + 1}</b> has<br>`
                warningMessage += `<div>${a}</div>`
                warningMessage += '</div>'
            }
        })
        if (warningMessage) return showWarningSwal(warningMessage)
        setLoading(true)
        let difficulties = []
        difficulty.forEach(({ id, name, correct_marks, wrong_marks, blank_marks }) => {
            if (assign_marks) {
                difficulties.push(id === undefined
                    ? { name, correct_marks, wrong_marks, blank_marks }
                    : { id, name, correct_marks, wrong_marks, blank_marks }
                )
            } else {
                difficulties.push(id === undefined
                    ? { name }
                    : { id, name }
                )
            }
        })
        let payload = {
            id, assign_marks, delete_id: deleteId, difficulty: difficulties
        }
        if (name.toLowerCase() !== editingDifficultyGroup.name.toLowerCase()) payload.name = name
        console.log(JSON.stringify(payload))
        console.log(payload)
        patchDifficultyGroup(payload).then(d => {
            setLoading(false)
            afterEditingDifficultyGroup(cancelEdit, d)
        }).catch(e => {
            setLoading(false)
            console.log(e)
        })
    }
    return (
        <Box className='popUpModal horizontalScrollable' style={boxStyle}>
            <NunitoText value='EDIT DIFFICULTY GROUP' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
            <Grid container style={formContainerStyle}>
                <Grid container alignItems="center" justifyContent="space-between" style={configContainerStyle}>
                    <div style={centerFlexStyle}>
                        <NunitoText value='Difficulty Group' fontSize={20} fontWeight={600} marginRight={40} />
                        <ReusableTextField type='default' width={450} height={60} bgColor='#F2F2F2' placeholder='Enter Difficulty Group Name*'
                            state={name} target='name' setState={updateDifficultyGroup} required />
                    </div>
                    <div style={centerFlexStyle}>
                        <NunitoText value='Assign Marks' fontSize={20} fontWeight={600} marginRight={40} />
                        <ReusableToggle type='icon' width={120} state={assign_marks} target='assign_marks'
                            setState={updateDifficultyGroup} />
                    </div>
                </Grid>
                <Grid container direction="row" alignItems="center" justifyContent="center" style={rowsContainerStyle}>
                    {difficulty.map((diff, index) => (
                        <Grid key={index} container alignItems='center' justifyContent='space-between' style={difficultiesContainerStyle(assign_marks)}>
                            {Boolean(assign_marks && !index) && <Grid container justifyContent='space-between' style={buttonsWrapperStyle}>
                                <ReusableButton text='Mass Assign Correct Points' fontSize={15} height={42} width={240} bgColor='#6FC9B6'
                                    onClick={() => massAssignScore('correct_marks')} />
                                <ReusableButton text='Mass Assign Wrong Points' fontSize={15} height={42} width={240} bgColor='#F16774'
                                    onClick={() => massAssignScore('wrong_marks')} />
                                <ReusableButton text='Mass Assign Blank Points' fontSize={15} height={42} width={240} bgColor='#707070'
                                    onClick={() => massAssignScore('blank_marks')} />
                            </Grid>}
                            <div style={rowButtonsWrapperStyle}>
                                {index > 0 && <IconButton edge="end" style={upButtonStyle(index, difficulty.length)}
                                    onClick={() => updateDifficulty(index, 'moveUp')}>
                                    <ExpandLessIcon style={rowIconStyle} />
                                </IconButton>}
                                {index < difficulty.length - 1 && <IconButton edge="end" style={downButtonStyle(index)}
                                    onClick={() => updateDifficulty(index, 'moveDown')}>
                                    <ExpandMoreIcon style={rowIconStyle} />
                                </IconButton>}
                            </div>
                            <div style={flexStyle}>
                                <ReusableTextField type='default' width={assign_marks ? 360 : 760} height={60} bgColor='#F2F2F2'
                                    required placeholder='Enter Difficulty Name*' count={index} target='name'
                                    state={diff.name} setState={updateDifficulty} />
                                {Boolean(assign_marks) && <div>
                                    <ReusableTextField type='default' width={280} height={60} bgColor='#F2F2F2'
                                        placeholder='Correct Answer*' count={index} target='correct_marks' number
                                        state={diff.correct_marks} setState={updateDifficulty} marginLeft={20} required />
                                    <ReusableTextField type='default' width={280} height={60} bgColor='#F2F2F2'
                                        placeholder='Wrong Answer*' count={index} target='wrong_marks' number
                                        state={diff.wrong_marks} setState={updateDifficulty} marginLeft={20} required />
                                    <ReusableTextField type='default' width={280} height={60} bgColor='#F2F2F2'
                                        placeholder='Blank Answer*' count={index} target='blank_marks' number
                                        state={diff.blank_marks} setState={updateDifficulty} marginLeft={20} required />
                                </div>}
                            </div>
                            <div style={rowButtonsWrapperStyle}>
                                {difficulty.length > 1 && <IconButton edge="start" onClick={() => updateDifficulty(index, 'remove')}>
                                    <RemoveCircleIcon style={rowIconStyle} />
                                </IconButton>}
                                <IconButton edge="start" onClick={() => updateDifficulty(index, 'add')}>
                                    <AddCircleIcon style={rowIconStyle} />
                                </IconButton>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid container justifyContent='space-evenly' style={buttonsContainerStyle}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => cancelEdit()} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
        </Box>
    )
}