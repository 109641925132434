import React, { useRef, useEffect } from 'react'
import { TableCell, TableHead, TableRow } from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
const tableHeadStyle = {
    fontSize: 20, fontWeight: 'bolder', color: '#144A94', cursor: 'pointer', height: 61,
    backgroundColor: '#FFF', backgroundClip: 'padding-box'
}
const tableCellDivStyle = {
    display: 'flex', alignItems: 'center', whiteSpace: 'nowrap'
}
const fixedHeaderStyle = (widths, id,isCheatingTable) => {
    let index = widths.findIndex(w => w.id === id)
    let w = widths[index - 1]?.totalWidth || 0
    return { ...tableHeadStyle, position: 'sticky', left:index==2&&isCheatingTable?w-90: w, zIndex: 999 }
}
const tablestylecountry = {
    fontSize: 20, fontWeight: 'bolder', color: 'red', cursor: 'pointer', height: 61,
    backgroundColor: '#FFF', backgroundClip: 'padding-box'
}
const arrowWrapperStyle = { display: 'flex', flexDirection: 'column' }
const arrowStyle = { color: '#000', position: 'relative' }
const EachHead = ({ header, handleRequestSort, widths, setWidths, fixed ,isCheatingTable}) => {
    const { id, label } = header
    const ref = useRef(null)
    useEffect(() => {
        let newWidths = [...widths]
        let index = newWidths.findIndex(n => n.id === id)
        let prev = newWidths[index - 1]?.totalWidth || 0
        if(index!=-1){
       newWidths[index].width = ref.current?.offsetWidth
        newWidths[index].totalWidth = prev + ref.current?.offsetWidth}
        setWidths(newWidths)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const togglestyles = (label)=>{
       
        let style = {}
        let firstchar = label.charAt(0)
        if(firstchar==='&'){
            style = {
                fontSize: 20, fontWeight: 'bolder', color: '#144A94', cursor: 'pointer', height: 61,
                backgroundColor: '#FFF', backgroundClip: 'padding-box'
            }
        }else if(firstchar==='#'){
            style = {
                fontSize: 20, fontWeight: 'bolder', color: 'red', cursor: 'pointer', height: 61,
                backgroundColor: '#FFF', backgroundClip: 'padding-box'
            }
        }else if(firstchar==='%'){
            style={
                fontSize: 20, fontWeight: 'bolder', color: 'black', cursor: 'pointer', height: 61,
                backgroundColor: '#FFF', backgroundClip: 'padding-box'
            }
        }else{
            style = tableHeadStyle
        }
        return style
    }
    return (
        <TableCell style={fixed.includes(id) ? fixedHeaderStyle(widths, id,isCheatingTable) :togglestyles(label)}
            onClick={() => handleRequestSort(id)} ref={ref}>
            <div style={tableCellDivStyle}>
                {label}
                <div style={arrowWrapperStyle}>
                    <ArrowDropUpIcon style={{ ...arrowStyle, top: 8 }} />
                    <ArrowDropDownIcon style={{ ...arrowStyle, bottom: 8 }} />
                </div>
            </div>
        </TableCell>
    )
}
export default function EnhancedTableHead({ handleRequestSort, headers, widths, setWidths, fixed ,isCheatingTable}) {
    return (
        <TableHead>
            <TableRow>
                {headers.map(header => (
                    <EachHead key={header.id} id={header.id} header={header} handleRequestSort={handleRequestSort}
                        widths={widths} setWidths={setWidths} fixed={fixed} isCheatingTable={isCheatingTable} />
                ))}
            </TableRow>
        </TableHead>
    )
}