import React from 'react'
import { Grid } from '@mui/material'
export default function AddPageWrapper({ children, main }) {
    const containerStyle = {
        marginInline: !main && 30,
        backgroundColor: '#FFF',
        border: '1px solid #000',
        borderTopStyle: 'none',
        borderRadius: '0 0 10px 10px'
    }
    return <Grid container style={containerStyle}>{children}</Grid>
}