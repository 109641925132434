import React from 'react'
import { Box, Grid } from '@mui/material'
import { makeSureIsArray } from '../../functions/general'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
export default function EnableUserModal({ table, id, selected, enableFunction, cancelEnable }) {
    const selectedRows = makeSureIsArray(table.data).filter(s => id === '' ? selected.includes(s.id) : s.id === id)
    return (
        <Box className='popUpModal' style={{ width: 705, minHeight: 365 }}>
            <NunitoText value='CONFIRM ENABLE' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={30} />
            <NunitoText value='Are you sure you want to enable' fontSize={20} fontWeight={600} />
            {selectedRows.map((s, i) => (
                <NunitoText key={i} value={`${s.name}${i < selectedRows.length - 1 && ', '}`} fontSize={20} fontWeight={700} />
            ))}
            <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63}
                    width={280} onClick={() => cancelEnable()} />
                <ReusableButton text='Confirm' bgColor='#5E75C3' fontSize={25} height={63}
                    width={280} onClick={() => enableFunction()} />
            </Grid>
        </Box>
    )
}