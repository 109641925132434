import React, { useState } from 'react'
import { Grid, IconButton, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch } from 'react-redux'
import { updateTask } from '../../actions'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
const boxStyle = { width: 1210, minHeight: 450, maxHeight: '90%' }
const closeIconDivStyle = { display: 'flex', justifyContent: 'flex-end', marginTop: -50 }
const closeIconStyle = { fontSize: 36 }
const divStyle = { paddingInline: 60, marginTop: 30 }
const answersDivStyle = { minHeight: 300 }
const answerContainerStyle = { marginTop: 20 }
const containerStyle = { border: '1px solid #707070', borderRadius: 6, minHeight: 440, paddingInline: 100 }
const addAnswerBtnDivStyle = { marginBlock: 20 }
const buttonsContainerStyle = { paddingRight: 60, marginBlock: 20 }
export default function AddAnswersModal({ count, answerCount, task, answer, setAddAnswers }) {
    const [answers, setAnswers] = useState(window.structuredClone(answer.answers))
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateTask(count, head, target, text, event))
    const updateAnswers = (count, value) => {
        let newAnswers = [...answers]
        newAnswers[count] = value
        setAnswers(newAnswers)
    }
    const onClickSave = () => {
        update([count, answerCount], 'setAnswers', 'answers', answers)
        setAddAnswers(false)
    }
    const { taskTitle } = task
    return (
        <Box className='popUpModal horizontalScrollable' style={boxStyle}>
            <div>
                <NunitoText value='Add Correct Answer' fontSize={30} marginTop={20} />
                <div style={closeIconDivStyle}>
                    <IconButton onClick={() => setAddAnswers(false)}>
                        <CloseIcon style={closeIconStyle} />
                    </IconButton>
                </div>
                <div style={divStyle}>
                    <Grid container flexDirection='column' justifyContent='space-between' style={containerStyle}>
                        <div>
                            <NunitoText value={taskTitle === '' ? 'Task Title' : taskTitle} fontSize={21} align='left' marginTop={20} />
                        </div>
                        <div style={answersDivStyle}>
                            {answers.map((ans, index) => (
                                <Grid key={index} container alignItems='center' style={answerContainerStyle}>
                                    <Grid item xs={11}>
                                        <ReusableTextField type='default' placeholder="Enter answer" state={ans} setState={updateAnswers}
                                            count={index} fullWidth />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => setAnswers(answers.filter((f, i) => i !== index))}>
                                            <CloseIcon style={{ fontSize: 24 }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                        </div>
                        <div style={addAnswerBtnDivStyle}>
                            <ReusableButton text='Add Correct Answer' fontSize={20} height={50} bgColor='#5E75C3'
                                iconType='add' allowSpam onClick={() => setAnswers([...answers, ''])} />
                        </div>
                    </Grid>
                </div>
                <Grid container justifyContent='flex-end' style={buttonsContainerStyle}>
                    <ReusableButton text='Save' fontSize={22} height={50} width={200} bgColor='#5E75C3' iconType='save'
                        onClick={() => onClickSave()} />
                </Grid>
            </div>
        </Box>
    )
}