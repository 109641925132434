import React, { useEffect } from 'react'
import { Box, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import { useState } from 'react'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import { getMarkedParticipants } from '../../functions/getData'
import LightTable from '../table/LightTable'
import LoadingBackdrop from '../general/LoadingBackdrop'
import NoData from '../general/NoData'

export default function CustomeDeelemModal({setOnElm , id , handleSubmit , title}) {
    // const [partstring , setpartString] = useState("")
    const [selected , setSelected] = useState([])
    const [data , setData] = useState()
    const [header, setHeader] = useState([ { label: "Index Number", state: '', id: 'index_no', header: 'index_no' },
    { label: 'Name', state: 'name', id: 'name', header: 'name' },
    { label: 'School', state: [], id: 'school', header: 'school' },
    { label:'Country',state: [], id: 'country', header: 'country'},
    { label:'Grade',state: [], id: 'grade', header: 'grade'},
    {label:'Integrity Issue Description',state: [], id: 'reason', header: 'reason'},
    {label:'Created by',state: [], id: 'created_by', header: 'Created by'},
    {label:'Created Date/Time (UTC)',state: [], id: 'created_at', header: 'Created Date/Time (UTC)'}
])
    const submit=()=>{
    handleSubmit(id,selected , 'custom')
    
    }
    useEffect(()=>{
        getMarkedParticipants(id).then(res=>{
            console.log(res)
            setData(res.data)
        })
    },[])
    return  <>
    <LoadingBackdrop loading={data===undefined}/>
    {data&&<Box className='popUpModal horizontalScrollable' style={{ width: '80%' , minHeight:'200px' }}>
    <NunitoText value={title} fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
   
    {data.length>0?<Grid className='tableContainer' container position={'relative'}  width={'100%'} alignSelf={'center'} marginBottom={'3%'}>
        <LightTable insideModal={true}  hasPagination={true}  hasSelect={true} selected={selected} selecting={[]} setSelecting={()=>{}} setSelected={setSelected}  fixed={[]} data={data} headers={header}/>
    </Grid>:<Grid marginBottom={'3%'}><NoData/></Grid>}
   
    {selected.length>0&&<Grid justifyContent={'center'} container width={'80%'} alignSelf={'center'}>
        <ReusableButton  text={'Remove IAC Participants'}  fontSize={22} height={60}  bgColor='#5E75C3' onClick={()=>{
            submit()
        }} marginBottom={20} />
    </Grid>}
</Box>}
</>
}