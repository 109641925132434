import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import NunitoText from "../general/NunitoText";
import { gradeOptions, makeSureIsArray } from "../../functions/general";
import "./style.css";
export default function EnhancedHover({
  getter,
  data,
  type,
  structure,
  isObject,
  output,
  title,
}) {
  const countryOptions = useSelector((state) => state.countryOptions);
  if (isObject) {
    const titleDivStyle = {
      backgroundColor: "#5E75C3",
      border: "1px solid #707070",
      borderRadius: 6,
      paddingInline: 10,
      minWidth: 120,
    };

    const languageColor = (status) =>
      status === "active"
        ? "#000"
        : status === "pending translation"
        ? "#F3797E"
        : "orange";
    if (
      [
        "languages",
        "competition_date",
        "teachers",
        "collections",
        "competitions",
        "difficulty",
        "topic",
        "tags",
      ].includes(getter)
    ) {
      return (
        data && (
          <div>
            <div style={titleDivStyle}>
              <NunitoText
                value={getter.charAt(0).toUpperCase() + getter.slice(1) || ""}
                fontSize={15}
                fontWeight={400}
                italic
                color="#FFF"
              />
            </div>
            {data.map((d, index) => {
              let value = d;
              let color = "#000";
              switch (getter) {
                case "competitions":
                  value = d.competition;
                  break;
                case "difficulty":
                case "teachers":
                  value = d.name;
                  break;
                case "languages":
                  value = `${d.name} (${d.status
                    .charAt(0)
                    .toUpperCase()}${d.status.slice(1)})`;
                  color = languageColor(d.status);
                  break;
                default:
              }
              return (
                <NunitoText
                  key={index}
                  value={value || ""}
                  color={color}
                  align="left"
                />
              );
            })}
          </div>
        )
      );
    } else if (
      [
        "grade_difficulty",
        "partners",
        "competition_organization",
        "awards",
        "levels",
        "sections",
        "users",
        "name",
      ].includes(getter)
    ) {
      let getters = [];
      if (data.length) {
        switch (getter) {
          case "grade_difficulty":
            getters = [
              { getter: "grade", title: "Grade" },
              { getter: "difficulty", title: "Difficulty" },
            ];
            break;
          case "competition_organization":
            getters = [
              { getter: "organization_name", title: "Organization" },
              { getter: "country_id", title: "Country" },
            ];
            break;
          case "partners":
            getters = [
              { getter: "organization", title: "Organization" },
              { getter: "country_id", title: "Country" },
              { getter: "name", title: "Partner" },
            ];
            break;
          case "sections":
            getters = [
              { getter: "id", title: "Section" },
              { getter: "tasks", title: "Tasks" },
            ];
            break;
          case "users":
            getters = [
              { getter: "country_name", title: "Country" },
              { getter: "name", title: "Partners" },
            ];
            break;
          default:
            getters = Object.keys(data[0]).map((o) => ({
              getter: o,
              title: `${o.charAt(0).toUpperCase()}${o.slice(1)}`,
            }));
            break;
        }
      }

      return (
        <>
          <Grid container>
            {getters.map(({ getter, title }, i) => {
              return (
                <div
                  key={`${getter}-${i}`}
                  style={{ marginRight: i < getters.length - 1 && 30 }}
                >
                  <div style={titleDivStyle}>
                    <NunitoText
                      value={
                        `${title.charAt(0).toUpperCase()}${title.slice(1)}` ||
                        ""
                      }
                      fontSize={18}
                      fontWeight={400}
                      italic
                      color="#FFF"
                    />
                  </div>
                  {data !== undefined &&
                    makeSureIsArray(data).map((d, i2) => {
                      let value = d[getter] ? d[getter].toString() : "-";
                      if (getter === "country_id")
                        value = countryOptions.find(
                          (c) => c.id === d[getter]
                        ).display_name;
                      if (getter === "id") value = `Section ${i2 + 1}`;
                      if (getter === "tasks") value = d[getter].flat().length;
                      if (getter === "grade") {
                        if (value !== "-" && d[getter] <= 15) {
                          // if (value !== "" && d[getter] <= 15) {
                          value = gradeOptions.filter((g) => {
                            return g.id === d[getter];
                          })[0].name;
                        } else {
                          value = "-";
                        }
                      }
                      if (
                        getter === "organization_name" ||
                        getter === "country_id"
                      ) {
                        return (
                          <div key={`${i} -${i2}-${getter}`}>
                            {
                              <NunitoText
                                value={value || ""}
                                fontSize={16}
                                color="#000"
                              />
                            }
                            {/* {data.length > 7 && i2 === 7 && <NunitoText
                                                    value='....' fontSize={16} color='#000' />} */}
                          </div>
                        );
                      } else {
                        return (
                          <div key={`${i} -${i2}-${getter}`}>
                            {(i2 < 6 || i2 === data.length - 1) && (
                              <NunitoText
                                value={value || ""}
                                fontSize={16}
                                color="#000"
                              />
                            )}
                            {data.length > 7 && i2 === 7 && (
                              <NunitoText
                                value="...."
                                fontSize={16}
                                color="#000"
                              />
                            )}
                          </div>
                        );
                      }
                    })}
                </div>
              );
            })}
          </Grid>
          {getter === "sections" && title === "collections" && (
            <div
              className="seemorehover"
              style={{
                marginLeft: "0 auto",
                width: "100%",
                textAlign: "center",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              see more
            </div>
          )}
        </>
      );
    } else if (["task_answers"].includes(getter)) {
      const answerStyle = {
        margin: 0,
        fontWeight: "lighter",
        textAlign: "center",
      };
      if (type === "mcq") {
        if (structure === "sequence") {
          return (
            data && (
              <div style={{ minWidth: 180 }}>
                <Grid container>
                  <Grid item xs={6}>
                    <NunitoText value="Order" fontWeight={600} fontSize={20} />
                  </Grid>
                  <Grid item xs={6}>
                    <NunitoText
                      value="Answers"
                      fontWeight={600}
                      fontSize={20}
                    />
                  </Grid>
                </Grid>
                {data.map((d, index) => (
                  <Grid container key={index}>
                    <Grid item xs={6}>
                      <h2 style={answerStyle}>{d.position}</h2>
                    </Grid>
                    <Grid item xs={6}>
                      <h2 style={answerStyle}>{d.task_labels[0].content}</h2>
                    </Grid>
                  </Grid>
                ))}
              </div>
            )
          );
        } else {
          return (
            data && (
              <div style={{ minWidth: 100 }}>
                <NunitoText
                  value="Answers"
                  fontWeight={600}
                  fontSize={20}
                  marginLeft={10}
                  marginRight={10}
                />
                {data.map((d, index) => (
                  <h2 key={index} style={answerStyle}>
                    {d.task_labels[0].content}
                  </h2>
                ))}
              </div>
            )
          );
        }
      } else {
        if (structure === "open") {
          return (
            data && (
              <div style={{ minWidth: 100 }}>
                <NunitoText
                  value="Task Label"
                  fontWeight={600}
                  fontSize={20}
                  marginLeft={10}
                  marginRight={10}
                />
                {data.map((d, index) => (
                  <h2 key={index} style={answerStyle}>
                    {d.task_labels[0].content}
                  </h2>
                ))}
              </div>
            )
          );
        } else {
          return (
            data && (
              <div style={{ minWidth: 280 }}>
                <Grid container>
                  <Grid item xs={6}>
                    <NunitoText
                      value="Answer Label"
                      fontWeight={600}
                      fontSize={20}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NunitoText
                      value="Answers"
                      fontWeight={600}
                      fontSize={20}
                    />
                  </Grid>
                </Grid>
                {data[0].answer !== null &&
                  data.map((d, index) => (
                    <Grid container key={index}>
                      {d.task_labels.length > 0 && (
                        <Grid item xs={6}>
                          <h2 style={answerStyle}>
                            {d.task_labels[0].content}
                          </h2>
                        </Grid>
                      )}
                      <Grid item xs={6}>
                        <h2 style={answerStyle}>{d.answer}</h2>
                      </Grid>
                    </Grid>
                  ))}
              </div>
            )
          );
        }
      }
    } else if (
      title !== "participants" &&
      ["identifier"].includes(getter) &&
      data.length > 0
    ) {
      return <div style={{ color: "#F3797E" }}>{data.content}</div>;
    } else {
      return JSON.stringify({ getter, data });
    }
  }
  return output;
}
