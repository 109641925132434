import { combineReducers } from 'redux'
import taskReducer from './task'
import collectionReducer from './collection'
import competitionReducer from './competition'
import userReducer from './user'
import { countryOptionsReducer, roleOptionsReducer, languageOptionsReducer } from './options'

const allReducers = combineReducers({
    //Form states
    task: taskReducer,
    collection: collectionReducer,
    competition: competitionReducer,
    //User
    user: userReducer,
    //Options
    countryOptions: countryOptionsReducer,
    roleOptions: roleOptionsReducer,
    languageOptions: languageOptionsReducer
})
export default allReducers