import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box, Grid, Modal, Collapse } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { showWarningSwal } from "../functions/alert";
import {
  getCompetitions,
  getMarking,
  getMarkingPreparation,
} from "../functions/getData";
import {
  computecomp,
  patchMarkingPreparation,
  patchMarkingGroupOverview,
} from "../functions/patchData";
import { showNotification } from "../functions/snackbar";
import { cancelAction, makeOptions } from "../functions/general";
import CloseIcon from "@mui/icons-material/Close";
import MarkingOverviewTable from "../components/table/MarkingOverviewTable";
import NunitoText from "../components/general/NunitoText";
import ReusableButton from "../components/general/ReusableButton";
import Loader from "../components/general/Loader";
import NoData from "../components/general/NoData";
import Widget from "../components/general/Widget";
import TitleBar from "../components/general/TitleBar";
import AddPageWrapper from "../components/general/AddPageWrapper";
import LoadingBackdrop from "../components/general/LoadingBackdrop";
import { useSnackbar } from "notistack";
import axios from "axios";
import PatchMarkingOptionsModal from "../components/modal/PatchMarkingOptionsModal";
import ViewAndFilterButton from "../components/ViewAndFilterButton";
import Filter from "../components/Filter";
import { refreshData } from "../functions/postData";

const containerStyle = { paddingInline: 30 };
const widgetContainerStyle = { paddingTop: 24 };
const headers = [
  { id: "level", label: "Level" },
  { id: "countries", label: "Country" },
  { id: "marked", label: "marked / Answers Uploaded" },
  { id: "moderation_status", label: "Moderation Status" },
  { id: "total", label: "Total Particpants" },
  { id: "absentees", label: "Absentee" },
  { id: "progress", label: "Progress" },
];
export default function MarkingOverview() {
  document.title = "Marking Overview";
  const isMounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [counter, setCounter] = useState(false);
  const [competitionName, setCompetitionName] = useState("");
  const [computeClicked, setClicked] = useState(false);
  const [singleCompute, setSinglecompute] = useState(true);
  const [canbeComputed, setCanbeComputed] = useState(true);
  const [finished, setFinished] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [displayOptionsModal, setDisplayOptionsModal] = useState(false);
  const [displayFiltering, setDisplayFiltering] = useState(false);
  const [filterOptions, setFilterOptions] = useState();
  const [filtered, setFiltered] = useState(
    [
      {
        label: "Country",
        state: "",
        key: "country_id",
        header: "countries",
      },
      {
        label: "Level",
        state: "",
        key: "name",
        header: "level",
      },
    ].filter(Boolean)
  );

  const handleFilter = (value) => {
    setDisplayFiltering(!displayFiltering);
  };

  const onChangeFiltered = (value) => {
    console.log(value);
    setFiltered(value);
    let filteredData = originalData[0].filter((item) => {
      return item.countries.includes(value[0].state);
    });
    filteredData = filteredData.filter((item) => {
      if (value[1].state) {
        return item.level === value[1].state;
      }
      return item;
    });
    console.log(filteredData);
    // console.log(filteredData.length);
    console.log(data[0].length);
    setData([filteredData]);
  };

  const controller = useMemo(() => new AbortController(), []);
  const signal = controller.signal;
  let location = useLocation();

  function loadData() {
    setLoadingData(true);
    getMarking(`/${location.search.slice(4)}`, signal).then((res) => {
      setFirstLoaded(true);
      console.log(res);
      const uniqueCountries = [];
      const groupLevels = [];
      console.log(res);
      setCompetitionName(res.competition_name);
      let data = [];
      if (res) {
        for (const round in res.rounds) {
          let singleRoundobj = res.rounds[round];
          let singleRound = [];
          for (const levels in singleRoundobj) {
            setFinished(true);
            setCanbeComputed(true);
            singleRoundobj[levels].map((el) => {
              el.country_group.forEach((country) => {
                if (!uniqueCountries.includes(country)) {
                  uniqueCountries.push(country);
                }
              });
              if (!groupLevels.includes(el.name)) {
                groupLevels.push(el.name);
              }
              if (
                el.computing_status !== "Finished" &&
                el.computing_status !== "Bug Detected" &&
                el.computing_status !== "Not Started"
              ) {
                console.log(counter);
                setFinished(false);
              }
              if (
                el.computing_status !== "Not Started" &&
                el.computing_status !== "Finished"
              ) {
                console.log("in", el.computing_status);
                setCanbeComputed(false);
              }
              let roundlvl = {
                round: round,
                iscomputed: true,
                isactive: true,
                progress: Number(el.compute_progress_percentage),
                levelReady: el.level_is_ready_to_compute,
                id: el.level_id,
                level: el.name,
                countries: el.country_group.join(","),
                marked: el.marked_participants,
                total_attended_participants: el.total_participants,
                absentees: el.absentees,
                absenteesCount: el.absentees_count,
                computing_status: el.computing_status,
                marking_group_id: el.marking_group_id,
                level_is_ready_to_compute: el.level_is_ready_to_compute,
                uploaded: el.answers_uploaded,
                moderation_status: el.moderation_status,
              };
              singleRound.push(roundlvl);
            });
          }

          data.push(singleRound);
        }
      }
      setData(data);
      setOriginalData(data);
      setFilterOptions({
        countries: uniqueCountries,
        level: groupLevels,
      });
      setLoadingData(false);
    });
  }
  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();
    const signal = controller.signal;
    if (location.search.slice(0, 4) === "?id=") {
      loadData();
    } else {
      showWarningSwal("Wrong query");
    }
    return () => {
      controller.abort();
      isMounted.current = false;
    };
  }, [location.search, controller, signal]);

  const computeAll = (clearPrevious, checks) => {
    if (canbeComputed) {
      const payload = {
        clear_previous_results: clearPrevious ? 1 : 0,
        not_to_compute: [
          ...checks
            .map((item) => {
              if (!item.checked) {
                return item.name;
              }
              return null;
            })
            .filter((item) => {
              return !!item;
            }),
        ],
      };
      console.log(finished, "in statment");
      setFinished(false);
      //Test
      if (data[0].length === originalData[0].length) {
        computecomp(location.search.slice(4), payload).then((res) => {
          {
            res.status !== 406 && setCanbeComputed(false);
          }
          setCounter(!counter);
        });
      } else {
        for (let index in data[0]) {
          const group = data[0][index];
          patchMarkingGroupOverview(
            group.id,
            group.marking_group_id,
            payload
          ).then((res) => {
            if (index == data[0].length - 1) {
              setCanbeComputed(false);
              setCounter(!counter);
              if (res.error) {
                showNotification(
                  "warning",
                  res.error,
                  enqueueSnackbar,
                  closeSnackbar
                );
              } else {
                showNotification(
                  "success",
                  res.message,
                  enqueueSnackbar,
                  closeSnackbar
                );
              }
            }
          });
        }
      }
      setDisplayOptionsModal(false);
    } else {
      showNotification(
        "warning",
        "all levels shoud be not started or finished calculating before computing",
        enqueueSnackbar,
        closeSnackbar
      );
    }
  };

  // useEffect(() => {
  //   if (!finished) {
  //     setTimeout(() => {
  //       loadData();
  //       setCounter((data) => !data);
  //       console.log("realod");
  //       console.log(canbeComputed);
  //     }, 5000);
  //   }
  // }, [canbeComputed]);
  return (
    <Box className="wrapperBox">
      <LoadingBackdrop loading={loading} />
      <Collapse in={displayFiltering}>
        {filterOptions && (
          <Filter
            type="collapse"
            filterOptions={filtered.map((f) =>
              makeOptions(filterOptions, f.header)
            )}
            // filterOptions={filterOptions}
            filtered={filtered}
            onChangeFiltered={onChangeFiltered}
          />
        )}
      </Collapse>
      <Grid
        className="firstRowContainer"
        container
        justifyContent="space-between"
      >
        <div className="firstRowContainer dashboardAndSelfBtnDiv">
          <ReusableButton
            text="Dashboard"
            fontSize={14}
            bgColor="#F16774"
            height={36}
            width={125}
            br={18}
            to="/dashboard"
            iconType="home"
          />
          <ChevronRightIcon />
          <ReusableButton
            text="Competitions"
            fontSize={14}
            bgColor="#F16774"
            height={36}
            br={18}
            to="/competitions"
          />
        </div>
        <div className="firstRowContainer viewAndFilterBtnDiv">
          <ViewAndFilterButton
            text="Filter"
            state={displayFiltering}
            fontSize={14}
            height={42}
            onClick={handleFilter}
          />
        </div>
      </Grid>

      <Grid className="tableContainer" container>
        <NunitoText
          value="Marking Overview"
          fontSize={40}
          fontWeight={700}
          italic
          color="#144A94"
        />
        <TitleBar fixed main>
          <ReusableButton
            text="Refresh Data"
            fontSize={15}
            bgColor={"#5E75C3"}
            height={46}
            marginRight={60}
            // onClick={() => computeAll()}
            onClick={() => {
              refreshData(`${location.search.slice(4)}`).then((res) => {
                console.log(res);
                if (res.status === "success") {
                  loadData();
                }
              });
            }}
            iconType="refresh"
          />
          {/* <ReusableButton
            text="Compute All"
            fontSize={15}
            bgColor={canbeComputed ? "#5E75C3" : "red"}
            height={46}
            marginRight={60}
            // onClick={() => computeAll()}
            onClick={() => setDisplayOptionsModal(true)}
            iconType="compute"
          /> */}

          {displayOptionsModal && (
            <PatchMarkingOptionsModal
              confirmFunction={computeAll}
              cancelConfirm={() => {
                setDisplayOptionsModal(false);
              }}
            />
          )}
        </TitleBar>
        <AddPageWrapper main>
          <Grid container style={containerStyle}>
            {loadingData && <Loader height={110} />}

            <Grid container style={widgetContainerStyle}>
              {competitionName && (
                <Widget
                  title="Competition"
                  content={competitionName}
                  marginRight={28}
                />
              )}
            </Grid>

            <>
              {!loadingData &&
                firstLoaded &&
                data.length &&
                data.map((el, index) => {
                  return (
                    <>
                      <h2 style={{ lineHeight: "5px" }}>Round:{index + 1}</h2>

                      <MarkingOverviewTable
                        setCanbeComputed={setCanbeComputed}
                        setFinished={setFinished}
                        setCounter={setCounter}
                        setloading={setLoadingData}
                        counter={counter}
                        headers={headers}
                        data={el}
                        fixed={["level", "countries"]}
                        // competionId={competiion}
                      />
                    </>
                  );
                })}
            </>
          </Grid>
        </AddPageWrapper>
      </Grid>
    </Box>
  );
}
